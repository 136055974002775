import {
  GET_ACCOUNT, TRANSFER, CLEAR, GET_TOTAL_BALANCE, UPDATE_ACCOUNT, UPDATE_ACCOUNT_FIELD_FROM_SOCKET,
} from './accountTypes';

const initialState = {
  account: {},
  totalBalance: 0,
  success: false,
  accountFetched: false,
  amountTransferred: false,
};

const AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
        accountFetched: true,
        amountTransferred: false,
      };
      
    case TRANSFER:
      return {
        ...state,
        amountTransferred: true,
      };

    case CLEAR:
      return {
        ...state,
        success: false,
        accountFetched: false,
        amountTransferred: false,
      };

    case GET_TOTAL_BALANCE: 
      return {
        ...state,
        totalBalance: action.payload,
      };

    case UPDATE_ACCOUNT_FIELD_FROM_SOCKET:
      return {
        ...state,
        account: {
          ...state.account,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default AccountReducer;
