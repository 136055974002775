import {
  DISPLAY_UNREAD_NOTIFICATIONS, 
  ADD_NOTIFICATION, 
  REMOVE_READ_NOTIFICATION, 
  REMOVE_ALL_READ_NOTIFICATION, 
} from './notificationTypes';

const initialState = {
  unreadNotifications: [],
  success: false,
  fetched: false,
  error: false,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY_UNREAD_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: action.payload,
        success: false,
        fetched: true,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        unreadNotifications: [action.payload, ...state.unreadNotifications],
      };
    case REMOVE_READ_NOTIFICATION:
      return {
        ...state,
        unreadNotifications: state.unreadNotifications.filter((notif) => notif._id !== action.payload),
      };
    case REMOVE_ALL_READ_NOTIFICATION:
      return {
        ...state,
        unreadNotifications: action.payload,
      };
    default:
      return state;
  }
};
export default notificationReducer;
