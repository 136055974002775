import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { socket } from '../web';
import {
  getUserSpotOrders, stopSpotOrder, updateSpotSocketOrder, 
} from '../redux/spotOrder/spotOrderActions';
import { getAccount } from '../redux/account/accountActions';
import { limitOrdersColumnsSchema, limitPastOrdersColumnsSchema } from '../DataTableSchemas/limitOrdersColumns';

function SpotTradeOrdersData({
  primaryCoin, secondaryCoin, today, isTradingEnabled, 
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userSpotOrders = useSelector((state) => state.spotOrder.userSpotOrders);
  const userId = useSelector((state) => state.user?.user?._id);

  const pairName = useParams().coins;
  const token = localStorage.getItem('uToken');

  const [show, setShow] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [activeTab, setActiveTab] = useState(`${t('labels.active')}`);
  const [rates, setRates] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (!isTradingEnabled) {
      return toast.error(t('messages.trading_disabled'));
    }
    setShow(true);
  };
  
  const handleCancel = () => {
    dispatch(stopSpotOrder(orderId));
    setShow(false);
    setOrderId('');
  };

  useEffect(() => {
    if (userId) {
      dispatch(getUserSpotOrders(userId));
    }
  }, [userId]);

  const handleUpdateSpotOrderRes = (data) => {
    dispatch(updateSpotSocketOrder(data));
    dispatch(getAccount(userId));
  };

  useEffect(() => {
    if (socket && userId) {
      socket.on(`onUpdateSpotOrderRes${userId}`, handleUpdateSpotOrderRes);
      return () => {
        socket.off(`onUpdateSpotOrderRes${userId}`);
      };
    }
  }, [socket, userId]);

  useEffect(() => {
    if (pairName && today && userId) {
      socket.emit('getCurrentMarketPriceRequest', { pairName, userId, today });
      socket.on(`getCurrentMarketPriceResponse${pairName}${userId}${today}`, (currentMarketPriceData) => {
        setRates(currentMarketPriceData);
      });
    }

    return () => {
      if (socket) socket.off(`getCurrentMarketPriceResponse${pairName}${userId}${today}`);
    };
  }, [pairName, today, userId]);

  const limitOrdersColumns = limitOrdersColumnsSchema(secondaryCoin, primaryCoin, rates, setOrderId, handleShow, t);
  const limitPastOrdersColumns = limitPastOrdersColumnsSchema(secondaryCoin, primaryCoin, t);

  return (
    <>
      <div className="active-orders-block-wrapper">
        <div className="active-orders-header">
          <h5 className="text-light">
            {`${activeTab} ${t('labels.orders')}`}
          </h5>
          <div className="active-orders-tabs">
            <div
              style={{ cursor: 'pointer' }}
              className={activeTab === `${t('labels.active')}` ? 'active-tab' : ''}
              onClick={() => setActiveTab(`${t('labels.active')}`)}
            >
              {`${t('labels.active')} ${t('labels.orders')}`}
            </div>
            <div
              style={{ cursor: 'pointer' }}
              className={activeTab === `${t('labels.closed')}` ? 'active-tab' : ''}
              onClick={() => setActiveTab(`${t('labels.closed')}`)}
            >
              {`${t('labels.closed')} ${t('labels.orders')}`}
            </div>
          </div>
        </div>

        <div style={{ width: '100%', height: '450px' }}>
          {activeTab === `${t('labels.closed')}` ? (
            userSpotOrders && userSpotOrders.length ? (
              <DataTable
                columns={limitPastOrdersColumns}
                data={userSpotOrders?.filter((row) => row.status !== 1)}
                pagination
                fixedHeader
                persistTableHead
                theme="solarizedd"
              />
            ) : (
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th scope="col">{t('labels.pair')}</th>
                    <th scope="col">{t('labels.type')}</th>
                    <th scope="col">{t('labels.direction')}</th>
                    <th scope="col">{t('labels.order_value')}</th>
                    <th scope="col">{t('labels.filled_qty')}</th>
                    <th scope="col">{t('labels.order_price')}</th>
                    <th scope="col">{t('labels.order_qty')}</th>
                    <th scope="col">{t('labels.status')}</th>
                    <th scope="col">{t('labels.time')}</th>
                    <th scope="col">{t('labels.id')}</th>
                  </tr>
                </thead>
                <tbody>
                  <td colSpan="10">
                    {token ? (
                      `${t('labels.empty')}`
                    ) : (
                      <div className="graph-table-btns buy-tabs">
                        <Link to="/register">
                          <button type="button" className="mb-2 register-now">
                            {t('labels.register_now')}
                          </button>
                        </Link>
                        <Link to="/login">
                          <button type="button" className="login-now">
                            {t('labels.login')}
                          </button>
                        </Link>
                      </div>
                    )}
                  </td>
                </tbody>
              </table>
            )
          ) : (
            ''
          )}

          {activeTab === `${t('labels.active')}` ? (
            userSpotOrders && userSpotOrders.length ? (
              <DataTable
                columns={limitOrdersColumns}
                data={userSpotOrders?.filter((row) => row.status === 1)}
                pagination
                fixedHeader
                persistTableHead
                theme="solarizedd"
                style={{ width: '100%' }}
              />
            ) : (
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th scope="col">{t('labels.pair')}</th>
                    <th scope="col">{t('labels.type')}</th>
                    <th scope="col">{t('labels.direction')}</th>
                    <th scope="col">{t('labels.order_value')}</th>
                    <th scope="col">{t('labels.order_qty')}</th>
                    <th scope="col">{t('labels.order_price')}</th>
                    <th scope="col">{t('labels.mark_price')}</th>
                    <th scope="col">{t('labels.unfilled_qty')}</th>
                    <th scope="col">{t('labels.time')}</th>
                    <th scope="col">{t('labels.id')}</th>
                    <th scope="col">{t('labels.action')}</th>
                  </tr>
                </thead>
                <tbody>
                  <td colSpan="11">
                    {token ? (
                      `${t('labels.empty')}`
                    ) : (
                      <div className="graph-table-btns buy-tabs">
                        <Link to="/register">
                          <button type="button" className="mb-2 register-now">
                            {t('labels.register_now')}
                          </button>
                        </Link>
                        <Link to="/login">
                          <button type="button" className="login-now">
                            {t('labels.login')}
                          </button>
                        </Link>
                      </div>
                    )}
                  </td>
                </tbody>
              </table>
            )
          ) : (
            ''
          )}
        </div>
      </div>

      <ModalWindow handleCancel={handleCancel} handleClose={handleClose} show={show} />
    </>
  );
}

export default SpotTradeOrdersData;

function ModalWindow({ handleCancel, handleClose, show }) {
  const { t } = useTranslation();

  return (
    <Modal
      className="withdraw-details two-factor-auth text-center"
      centered
      backdrop="static"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className="modal-main-heading" closeButton />
      <Modal.Body className="text-white">
        <h5 className="mb-5">{t('messages.cancel_confirmation')}</h5>
        <div className="limit-modal-btns">
          <button type="button" onClick={handleClose} className="btn cancel">
            {t('labels.no')}
          </button>
          <button type="button" onClick={handleCancel} className="btn confirm">
            {t('labels.yes')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
