import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Navbar } from 'react-bootstrap';
import { getLanguages, searchLanguages } from '../../redux/language/languageActions';
import { useDebounce } from '../../hooks/useDebounce';
import { ENV } from '../../config/config';
import styles from './styles.module.css';

export function LanguageSelector({ isMobile = false }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [searchValue, setSearchValue] = useState();
  const languages = useSelector((state) => state.languages);

  const search = (value) => {
    dispatch(searchLanguages(value));
  };

  useDebounce(searchValue, 300, search);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    if (ENV.hasMultiLanguages) dispatch(getLanguages());
  }, [ENV]);

  return (
    ENV.hasMultiLanguages ? (
      !isMobile ? (
        <Navbar className={styles.languageSelectorMenu}>
          <button type="button" className="dropbtn-hover p-0">
            <FontAwesomeIcon size="lg" icon={faGlobe} className="header-icon" />
            <div className={styles.dropdownContent}>
              <input 
                className={styles.dropdownContentSerchInput} 
                type="text" 
                placeholder={t('labels.search')}
                onChange={({ target: { value } }) => setSearchValue(value)}
              />
              <div className={styles.dropdownContentWrapper}>
                { 
                  languages.map((lang) => (
                    <div 
                      key={lang._id} 
                      className={styles.dropdownContentItem} 
                      onClick={() => changeLanguage(lang.code)}
                    >
                      {lang.name}
                    </div>
                  ))
                }
              </div>
            </div>
          </button>
        </Navbar>
      ) : (
        <div className="dropdown header-trade-dd-mobile navbar-nav nav-link mobile-none2">
          <button
            className="btn text-white dropdown-toggle p-0"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon icon={faGlobe} className="header-icon" />
            <span
              className="navbar-link-heading mobile-none2"
              style={{ fontWeight: 700 }}
            >
              {t('labels.language')}
            </span>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <input 
              className={styles.dropdownContentSerchInputMobile} 
              type="text" 
              placeholder={t('labels.search')}
              onChange={({ target: { value } }) => setSearchValue(value)}
            />
            <ul className={styles.mobileLanguageAccordionWrapper}>
              { 
                languages.map((lang) => (
                  <li className="nav-link" key={lang._id} onClick={() => changeLanguage(lang.code)}>{lang.name}</li>
                ))
              }
            </ul>

          </ul>
        </div>
      )

    ) : null
  );
}
