import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { GetCoinImg } from '../../helpers/getCoinImg';
import { getAccount } from '../../redux/account/accountActions';
import {
  getUserStakingInvestments,
  claimFlexibleStakingInvestment,
  updateStakingInvestmentState,
} from '../../redux/stakingInvestment/stakingInvestmentActions';

function StakingList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [, setLoader] = useState(true);
  const [filterCurrency, setFilterCurrency] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [stakingInvestments, setStakingInvestments] = useState([]);

  const userId = useSelector((state) => state.user?.user?._id);
  const allStakingInvestments = useSelector(
    (state) => state?.stakingInvestment?.stakingInvestments,
  );
  const stakingInvestmentClaimed = useSelector(
    (state) => state?.stakingInvestment?.stakingInvestmentClaimed,
  );
  const stakingInvestmentsFetched = useSelector(
    (state) => state?.stakingInvestment?.stakingInvestmentsFetched,
  );

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        await dispatch(getUserStakingInvestments(userId));
      };
      fetchData();
    }
  }, [userId]);

  useEffect(() => {
    if (stakingInvestmentsFetched || stakingInvestmentClaimed) {
      if (stakingInvestmentClaimed) {
        dispatch(getAccount(userId));
      }
      dispatch(updateStakingInvestmentState());
      setLoader(false);
    }
    if (allStakingInvestments?.length > 0) {
      setStakingInvestments(allStakingInvestments);
      setFilteredItems(allStakingInvestments);
    } else {
      setStakingInvestments([]);
      setFilteredItems([]);
    }
  }, [
    allStakingInvestments,
    stakingInvestmentsFetched,
    stakingInvestmentClaimed,
  ]);

  const searchByCurrency = (e) => {
    setFilterCurrency(e.target.value);
    const searchText = e.target.value;
    const searchData = stakingInvestments.filter(
      (row) => row?.currency?.name
        ?.toLowerCase()
        ?.includes(searchText.toLowerCase())
        || row?.currency?.symbol?.toLowerCase()?.includes(searchText.toLowerCase()),
    );
    setFilteredItems(searchData);
  };

  const calcEarnings = (amt, percentage, noOfDays) => {
    const pctAmt = (amt * percentage) / 100;
    const dailyAmt = pctAmt / 365;
    const noOfDaysAmt = dailyAmt * noOfDays;

    return noOfDaysAmt ? Number(noOfDaysAmt?.toFixed(2)) : 0;
  };

  const claimFlexible = (currentStakingInvestment) => {
    setLoader(true);
    const earning = Number(calcEarnings(currentStakingInvestment?.investedAmount, currentStakingInvestment?.pct, moment(Date.now()).diff(moment(currentStakingInvestment.createdAt), 'days')));
    const finalEarnings = earning ? Number(currentStakingInvestment?.investedAmount) + earning : Number(currentStakingInvestment?.investedAmount);
    const data = {
      currencyId: currentStakingInvestment?.currencyId,
      userId: currentStakingInvestment?.userId,
      finalEarnings: finalEarnings || 0,
      orderId: currentStakingInvestment?._id,
    };

    dispatch(claimFlexibleStakingInvestment(data));
  };

  return (
    <div className="content-box simple-earn-wrapper">
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl">
        <div className="tbl-head-wrap d-flex flex-row">
          <div className="table-heading">
            <h2>{t('labels.my_investments')}</h2>
          </div>
          <div className="search-check-holder">
            <div className="search-wrap">
              <div className="input-group">
                <input
                  type="text"
                  placeholder={t('labels.search')}
                  name="filterCurrency"
                  value={filterCurrency}
                  onChange={(e) => searchByCurrency(e)}
                />
                <FontAwesomeIcon icon={faSearch} className="search" />
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table>
            <tr>
              <th>{t('labels.coin')}</th>
              <th>{t('labels.investment')}</th>
              <th>{t('labels.period')}</th>
              <th>{t('labels.duration')}</th>
              <th>{t('labels.final_earnings')}</th>
              <th>{t('labels.created')}</th>
              <th>{t('labels.released_time')}</th>
              <th>{t('labels.days_elapsed')}</th>
              <th>{t('labels.funds_released_in')}</th>
              <th>{t('labels.action')}</th>
            </tr>
            {filteredItems?.length > 0 ? (
              filteredItems.map((currentStaking) => (
                <tr key={`staking-${currentStaking?._id}`}>
                  <td style={{ width: '30px' }}>
                    <div className="d-flex flex-row align-items-center">
                      <div className="icon-wrap">
                        <img
                          alt=""
                          src={GetCoinImg(currentStaking?.currency?.symbol)}
                          className="img-fluid"
                        />
                      </div>
                      <div className="icon-info">
                        <h1 className="text-uppercase">
                          {currentStaking?.currency?.symbol
                            ? currentStaking?.currency?.symbol
                            : 'N/A'}
                        </h1>
                        <p className="text-capitalize mb-0">
                          {currentStaking?.currency?.name
                            ? currentStaking?.currency?.name
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td style={{ width: '20px' }}>
                    {currentStaking?.investedAmount}
                  </td>
                  <td>{currentStaking?.isFlexible ? 'Flexible' : 'Locked'}</td>
                  <td>
                    {currentStaking?.isFlexible
                      ? '-'
                      : currentStaking?.period}
                  </td>
                  <td>{currentStaking?.finalEarnings}</td>
                  <td>{moment(currentStaking.createdAt).format('LLL')}</td>
                  <td>
                    {currentStaking?.isFlexible && !currentStaking?.isReleased ? '-' : moment(currentStaking.releaseTime).format('LLL')}
                  </td>
                  <td>
                    {moment(Date.now()).diff(
                      moment(currentStaking.createdAt),
                      'days',
                    )}
                  </td>
                  <td>
                    {currentStaking?.isFlexible
                      ? '-'
                      : moment(currentStaking.releaseTime).diff(
                        moment(Date.now()),
                        'days',
                      ) + 1}
                  </td>
                  <td>
                    {!currentStaking?.isReleased ? (currentStaking?.isFlexible 
                      ? <button type="button" className="btn-complete" onClick={() => claimFlexible(currentStaking)}>{t('labels.claim')}</button> : '') 
                      : <span style={{ padding: '8px 10px' }} className="badge rounded-pill bg-success">{t('labels.claimed')}</span>}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td>{t('messages.no_record_found')}</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

export default StakingList;
