import { toast } from 'react-toastify';
import {
  REGISTER_USER, LOGIN_USER, TOGGLE_STATE, LOGOUT_USER,
} from './authTypes';
import { ENV } from '../../config/config';
import { apiHelper } from '../apiHelper';

export const RegisterUser = (newUser) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/register', newUser);
    
    if (res?.data) {
      const { data } = res;
      ENV.removeItem('code');
      ENV.encryptUserData(data);

      dispatch({
        type: REGISTER_USER,
        payload: data,
      });
      window.location.href = `/login?status=200&resend=${newUser.email}&message=A verification Email has been sent to ${newUser.email}. Please verify Email address to log in.`;
    }
  } catch (error) {
    toast.error(error.response?.message);
  }
};

export const LoginUser = (user) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/login', user);
    if (res?.data) {
      const { data } = res;

      if (data.type === 'not-verified') {
        window.location.href = data.url;
      } else {
        dispatch({
          type: LOGIN_USER,
          payload: data,
        });
        if (data.success) {
          ENV.encryptUserData(data.user, data.token, data.user[0]?.userId);
          window.location.href = '/portfolio';
        }
      }
    }
  } catch (error) {
    // console.log(error.message);
  }
};

export const VerifyUser = (token, navigate) => async () => {
  try {
    const response = await apiHelper('get', `/api/auth/verify/${token}`);
    const { data } = response;

    if (data && data.user) {
      ENV.encryptUserData(data.user, data.token, data.user._id);
      toast.success(data.message, {
        autoClose: 1000,
      });
      navigate('/portfolio');
    } else if (data && data.redirectUrl) {
      navigate(data.redirectUrl);
    }
  } catch (error) {
    navigate('/login');
  }
};

export const userDirectLogin = (user) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/user-login-byadmin', user);
    if (res?.data) {
      const { data } = res;
      
      if (data?.success && data?.user) {
        await ENV.encryptUserData(data?.user, data?.token, data?.user[0]?.userId, data.isAdminLogged);
        window.location.href = '/portfolio';
      } else {
        toast.error('Unable to login user.');
        window.location.href = '/login';
      }
    } else {
      window.location.href = '/login';
    }
  } catch (error) {
    toast.error(error.response.message);
    window.location.href = '/login';
  }
};

export const affiliateLoginByAdmin = (user) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/aff-user-login', user);

    if (res?.data) {
      const { data } = res;

      if (data?.success && data?.user) {
        await ENV.encryptUserData(data?.user, data?.token, data?.user[0]?.userId);
        window.location.href = '/deposit/USDT';
      } else {
        toast.error('Unable to login user.');
        window.location.href = '/login';
      }
    } else {
      window.location.href = '/login';
    }
  } catch (error) {
    // console.log(error);
    toast.error(error.response.message);
    window.location.href = '/login';
  }
};

export const resendVerification = (email) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/resend', { email });
  
    if (res && res.data && res.data.success) { 
      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/auth/recover', { email });
    // console.log(res);
    if (res) {
      // toast.success(res.data.message)
      return true;
    }
  } catch (error) {
    // console.log(error.message)
    return false;
  }
};

export const updateState = () => async (dispatch) => {
  try {
    dispatch({
      type: TOGGLE_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};

export const LogoutUser = (id) => async (dispatch) => {
  try {
    const userId = id.split('"').join('');
    await apiHelper('post', `/api/auth/update-logout-activity/${userId}`, '');
    dispatch({
      type: LOGOUT_USER,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
