import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import { GET_OTHER_PSP } from './pspOthersTypes';

export const getOthersPsp = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/other-psp/active');
    
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_OTHER_PSP,
        payload: res.data.othersPsp,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};
