import { socket } from '../../../web';
import { parseFullSymbol } from '../../../helpers/datafeedHelpers';

const userIdJSON = localStorage.getItem('uId');
const userId = (userIdJSON && userIdJSON !== 'undefined') ? JSON.parse(userIdJSON) : '';

const channelToSubscription = new Map();

function getNextDailyBarTime(barTime) {
  const date = new Date(barTime * 1000);
  date.setDate(date.getDate() + 1);
  return date.getTime() / 1000;
}

let today = new Date().toISOString();

export function getChannel() {
  return channelToSubscription.entries().next().value[1];
}

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback,
  lastDailyBar,
) {
  const parsedSymbol = parseFullSymbol(symbolInfo.full_name);

  const channelString = `${resolution}~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}~${today}`;
  const pairname = parsedSymbol.fromSymbol + parsedSymbol.toSymbol;

  const handler = {
    id: subscriberUID,
    callback: onRealtimeCallback,
  };

  let subscriptionItem = channelToSubscription.get(channelString);
  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    return;
  }

  subscriptionItem = {
    symbolInfo,
    onRealtimeCallback,
    onResetCacheNeededCallback,
    subscriberUID,
    resolution,
    lastDailyBar,
    handlers: [handler],
    pairname,
    parsedSymbol,
    userId,
    uniqueChannelId: channelString,
  };

  channelToSubscription.set(channelString, subscriptionItem);
  socket.emit('getFuturesFeeds', subscriptionItem);
  socket.on(`reciveFuturesFeeds${channelString}`, (data) => {
    const {
      eventTime, open, high, low, close, isFinal, parsedSymbol, 
    } = data;
  
    const channelString = `${resolution}~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}~${today}`;
    const subscriptionItem = channelToSubscription.get(channelString);
    if (subscriptionItem === undefined) {
      return;
    }
    const { lastDailyBar } = subscriptionItem;
    const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);
  
    let bar;
    if (eventTime >= nextDailyBarTime) {
      bar = {
        time: nextDailyBarTime,
        open: parseFloat(open),
        high: parseFloat(high),
        low: parseFloat(low),
        close: parseFloat(close),
        isBarClosed: isFinal,
      };
    } else {
      bar = {
        ...lastDailyBar,
        high: parseFloat(Math.max(lastDailyBar.high, high)),
        low: parseFloat(Math.min(lastDailyBar.low, low)),
        close: parseFloat(close),
      };
    }
    
    subscriptionItem.lastDailyBar = bar;
  
    subscriptionItem.handlers.forEach((handler) => {
      handler.callback(bar);
    });
  });
}

export function unsubscribeFromStream(subscriberUID) {
  // eslint-disable-next-line no-restricted-syntax
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex((handler) => handler.id === subscriberUID);

    if (handlerIndex !== -1) {
      // Remove from handlers
      subscriptionItem.handlers.splice(handlerIndex, 1);

      if (subscriptionItem.handlers.length === 0) {
        socket.off(`reciveFuturesFeeds${channelString}`);
        today = new Date().toISOString();
        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
}
