import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import currencyFormatter from 'currency-formatter';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../web';
import tradeSpotDropdownMark from '../assets/images/tradeSpotDropdownMark.svg';
import { getCurrency } from '../redux/currencies/currencyActions';
import { getAccount } from '../redux/account/accountActions';
import { getUserLeverageOrders } from '../redux/leverageOrder/leverageOrderActions';
import { GetCoinImg } from '../helpers/getCoinImg';
import { AccountTypeIcon } from './AccountTypeIcon/AccountTypeIcon';

function TradePriceWidget({
  primaryCoin,
  coinsToPrecisions,
  secondaryCoin,
  prevPair,
  setPrimaryCoin,
  setSecondaryCoin,
  unsubscribeFromPrevSocket,
  pairs,
  coinLink,
  today,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pairName = useParams().coins;
  const [ticketData, setTicketData] = useState(0);
  const [accountType, setAccountType] = useState({ name: 'main' });
  const { pair } = useSelector((state) => state.pairTrade);
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const accountData = useSelector((state) => state?.accounts?.account);
  const userId = useSelector((state) => state.user?.user?._id);
  const tradingValues = [
    {
      id: 1,
      title: `${t('portfolio.24h_change')}%`,
      value: ticketData?.percentChange ? ticketData?.percentChange : '0.00',
      hoverValue: ticketData?.priceChange,
    },
    { id: 2, title: t('labels.24h_hight'), value: ticketData?.high ? parseFloat(ticketData?.high).toFixed(coinsToPrecisions?.[ticketData?.symbol]) : '0.00' },
    { id: 2, title: t('labels.24h_low'), value: ticketData?.low ? parseFloat(ticketData?.low).toFixed(coinsToPrecisions?.[ticketData?.symbol]) : '0.00' },
    {
      id: 3,
      title: `${t('labels.24h_turnover')}(USDT)`,
      value: ticketData?.quoteVolume
        ? currencyFormatter.format(ticketData?.quoteVolume, { code: 'USD' })
        : '0.00',
    },
  ];
  const memoizedAccountTypeIcon = useMemo(() => <AccountTypeIcon accountType={accountType} />, [accountType]);

  useEffect(() => {
    if (accountData?.accountType) {
      setAccountType(accountData.accountType);
    }
  }, [accountData]);

  useEffect(() => {
    dispatch(getCurrency());
    if (userId) {
      Promise.allSettled([
        dispatch(getUserLeverageOrders(userId)),
        dispatch(getAccount(userId)),
      ]);
    }
  }, [userId]);

  useEffect(() => {
    if (pairName && pair && pair.isEnabled && userId && today) {
      socket.emit('getTickerDataRequest', { pairName, userId, today });
      socket.on(`getTickerDataResponse${pairName}${userId}${today}`, (ticketData) => {
        setTicketData(ticketData);
      });
    }
  
    return () => {
      setTicketData(0);
      if (socket) socket.off(`getTickerDataResponse${pairName}${userId}${today}`);
    };
  }, [pairName, userId, today, pair]);

  return (
    <div className="trade-header-info">
      <div className="dropdown-header-info">
        <DropdownMenu
          primaryCoin={primaryCoin}
          secondaryCoin={secondaryCoin}
          prevPair={prevPair}
          setPrimaryCoin={setPrimaryCoin}
          setSecondaryCoin={setSecondaryCoin}
          unsubscribeFromPrevSocket={unsubscribeFromPrevSocket}
          pairs={pairs}
          currencyData={currencyData}
          coinLink={coinLink}
        />

        <hr className="header-info-split-line" />
        {
          primaryCoin 
          && (
          <div className="mx-5">
            <h6 className="trade-header-info-label">
              {`Last Price ${primaryCoin.symbol}`}
            </h6>
            <h5>{ticketData?.averagePrice ? parseFloat(ticketData?.averagePrice).toFixed(coinsToPrecisions?.[ticketData?.symbol]) : '0.00'}</h5>
          </div>
          )
        }
        
        {tradingValues.map((tradingValue, key = 0) => (
          <div className="generated-values" key={key}>
            <h6 className="trade-header-info-label">{tradingValue.title}</h6>
            <p>{tradingValue.value}</p>
          </div>
        ))}
      </div>
      {memoizedAccountTypeIcon}  
    </div>
  );
}

function DropdownMenu({
  primaryCoin,
  secondaryCoin,
  prevPair,
  setPrimaryCoin,
  setSecondaryCoin,
  unsubscribeFromPrevSocket,
  pairs,
  currencyData,
  coinLink,
}) {
  const navigate = useNavigate();
  const [filterText, setFilterText] = useState('');
  const [filteredItemsResult, setFilteredItemsResult] = useState(pairs);
  const pairData = useSelector((state) => state.pairTrade.pair);

  const handleSearchFilter = () => {
    const filteredItemsResult = pairs.filter((pair) => pair.toLowerCase().includes(filterText.toLowerCase()));
    setFilteredItemsResult(filteredItemsResult);
  };

  useEffect(() => {
    if (pairs) {
      setFilteredItemsResult(pairs);
    }
  }, [pairs]);

  useEffect(() => {
    handleSearchFilter();
  }, [filterText]);

  return (
    <div className="dropdown">
      <button
        className="btn text-white"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {
          primaryCoin 
          && (
          <div className="dropdown-coin-logo">
            <img
              src={GetCoinImg(primaryCoin.symbol)}
              alt={primaryCoin.symbol}
              className="rotateimg180"
            />
            {primaryCoin.symbol}
          </div>
          )
        }

        <img src={tradeSpotDropdownMark} className="dropdown-arrow" alt="tradeSpotDropdownMark" />
        {
          secondaryCoin 
          && (
          <div className="dropdown-coin-logo">
            <img
              src={GetCoinImg(secondaryCoin.symbol)}
              alt={secondaryCoin.symbol}
              className="rotateimg180"
            />
            {secondaryCoin.symbol}
          </div>
          )
        }

        <img src={tradeSpotDropdownMark} className="dropdown-mark" alt="tradeSpotDropdownMark" />
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <input
          type="text"
          id="search"
          autoComplete="off"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        
        {Object.keys(pairData).length && filteredItemsResult.map((pair, key = 0) => {
          const prim = pairData.toCurrency;
          const sec = pairData.fromCurrency;
          return (
            <li
              key={key}
              onClick={() => {
                setFilterText('');
                setPrimaryCoin(prim);
                setSecondaryCoin(sec);
                unsubscribeFromPrevSocket(prevPair);
                navigate(`/${coinLink}/${pair}`);
              }}
            >
              <a className="dropdown-item">{pair}</a>
            </li>
          );
        })}        
      </ul>
    </div>
  );
}

export default TradePriceWidget;
