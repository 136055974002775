import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

export function ChatContent({ chat, userId, chatBoxRef }) {
  return (
    <div className="chat-content" ref={chatBoxRef}>
      {
            !!chat.messages?.length && chat.messages?.map((message) => (
              <div key={message._id} className={`chat-content__message ${message.sender === userId ? 'right-side' : 'left-side'}`}>
                {message.content}
                {
                  !!message.fileName && (
                    <a target="_blank" className="chat-content__file" href={`${process.env.REACT_APP_SERVER_URL}/images/${message.fileName}`} download rel="noreferrer">
                      <FontAwesomeIcon size="xl" className="pointer" icon={faFile} />
                    </a>
                  ) 
                }
                <span className="time">{new Date(message.createdAt).toLocaleString()}</span>
              </div>
            ))
        }
    </div>
  );
}
