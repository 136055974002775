import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userDirectLogin, affiliateLoginByAdmin } from '../redux/auth/authActions';

function AuthLayout({ title, children }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const loginAsUser = (email) => {
    if (email) {
      const data = { email, userByAdmin: true };
      dispatch(userDirectLogin(data));
    }
  };

  const loginAffiliateAsUser = (email) => {
    if (email) {
      const data = { email, userByAffiliate: true };
      dispatch(affiliateLoginByAdmin(data));
    }
  };

  useEffect(() => {
    if (title) document.title = title;
    else document.title = 'StarBitrex';
  }, [title]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.get('email') && Number(params.get('userByAdmin')) === 1) {
      loginAsUser(params.get('email'));
    } else if (params.get('email') && Number(params.get('userByAffiliate')) === 1) {
      loginAffiliateAsUser(params.get('email'));
    } else {
      setShow(true);
      if (localStorage.uToken) {
        window.location.href = '/portfolio';
      }
    }
  }, []);

  return (show && (
  <div className="auth-layout">
    {children}
  </div>
  ));
}

export default AuthLayout;
