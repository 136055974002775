import { toast } from 'react-toastify';
import { GET_Networks } from './networkTypes';
import { apiHelper } from '../apiHelper';

export const getNetwork = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/Network/', '');
    if (res?.data) {
      const { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: GET_Networks,
        payload: data.allNetworks,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
