import { toast } from 'react-toastify';
import {
  GET_SPOT_ORDER,
  GET_USER_SPOT_ORDER,
  ADD_SPOT_ORDER,
  STOP_SPOT_ORDER,
  CLEAR_SPOT_ORDER,
  SPOT_ORDER_ERROR,
  COMPLETE_ORDER,
  UPDATE_SPOT_SOCKET_ORDER,
} from './spotOrderTypes';
import { apiHelper } from '../apiHelper';

export const getSpotOrders = () => async (dispatch) => {
  try {
    // console.log('I am getting spot orders');
    const res = await apiHelper('get', '/api/spotOrder/', '');
    // console.log('Get Spot Order Response ', res);
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_SPOT_ORDER,
        payload: data,
      });
    } else {
      dispatch({
        type: SPOT_ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getUserSpotOrders = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/spotOrder/${id}`, '');
    if (res.data && res.data.userOrders) {
      dispatch({
        type: GET_USER_SPOT_ORDER,
        payload: res.data.userOrders,
      });
    } else {
      dispatch({
        type: SPOT_ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const addSpotOrder = (data) => async (dispatch) => {
  try {
    // console.log("Here I am in spotOderActions.js");
    const res = await apiHelper('post', '/api/spotOrder/add', data);
    if (res?.data) {
      toast.success(res.data.message);
      const { data } = res;
      dispatch({
        type: ADD_SPOT_ORDER,
        payload: data,
      });
    } else {
      dispatch({
        type: SPOT_ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateSpotOrder = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/spotOrder/update', data);
    if (res?.data) {
      toast.success(res.data.message);
      const { data } = res;
      // console.log(data);
      dispatch({
        type: ADD_SPOT_ORDER,
        payload: data,
      });
    } else {
      dispatch({
        type: SPOT_ORDER_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const clearSpotOrder = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_SPOT_ORDER,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const stopSpotOrder = (id) => async (dispatch) => {
  console.log('🚀 ~ file: spotOrderActions.js:114 ~ stopSpotOrder ~ id:', id);
  try {
    const res = await apiHelper('put', `/api/spotOrder/stop/${id}`, '');
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: STOP_SPOT_ORDER,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const completeSpotOrder = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/spotOrder/complete/${id}`, '');
    if (res?.data) {
      const { data } = res;
      toast.success(res.data.message);
      dispatch({
        type: COMPLETE_ORDER,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateSpotSocketOrder = (order) => (dispatch) => {
  if (order.status !== 1) {
    toast.success('Order is Completed.');
    dispatch({ type: UPDATE_SPOT_SOCKET_ORDER, payload: order });
  }
};
