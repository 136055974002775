import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ENV } from '../config/config';
import { changePassword } from '../redux/users/userActions';

function ChangePassword() {
  const { t } = useTranslation();
  const passChanged = useSelector((state) => state.user?.passChanged);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialState = { oldPassword: '', password: '', confirmPassword: '' };
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState('');
  const [view, setView] = useState(0);
  const [id, setId] = useState('');

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    const { password, confirmPassword, oldPassword } = state;
    e.preventDefault();

    if (oldPassword === '') {
      setErrors(t('change_password.enter_old_pass'));
    } else if (password === '') {
      setErrors(t('change_password.enter_new_pass'));
    } else if (password.length < 6) {
      setErrors(t('change_password.pass_requirements'));
    } else if (password === oldPassword) {
      setErrors(t('change_password.new_pass_should_be_different'));
    } else if (confirmPassword === '') {
      setErrors(t('change_password.confirmation_required'));
    } else if (password !== confirmPassword) {
      setErrors(t('change_password.not_matched'));
    } else {
      const data = {
        oldPassword,
        password,
        confirmPassword,
      };
      dispatch(changePassword(id, data));
    }
  };

  useEffect(() => {
    if (passChanged) {
      setState(initialState);
      ENV.logout();
      navigate('/login');
    }
  }, [passChanged]);

  useEffect(() => {
    const loginUser = localStorage.getItem('uId');
    const uId = JSON.parse(loginUser);
    setId(uId);
  }, []);

  return (
    <section className="user-change-password header-padding">
      <Row>
        <Col lg={12}>
          <div className="text-center bottom-space mb-lg-0 container-fluid">
            <h3 className="text-capitalize mb-5 text-white">{t('change_password.change_password')}</h3>
          </div>
          <div className="register create-account">
            <div className="banner">
              <div className="container-fluid">
                <form className="account-form wrap-account-form m-auto">
                  <div style={{ position: 'relative', marginBottom: '15px' }}>
                    <input
                      type={view ? 'text' : 'password'}
                      className="form-control"
                      name="oldPassword"
                      value={state.oldPassword}
                      onChange={(e) => handleChange(e)}
                      placeholder={t('change_password.enter_current_pass')}
                      aria-label="Email verification code"
                      aria-describedby="button-addon2"
                    />
                    <div className="eyeicon" onClick={() => setView(!view)}>
                      <FontAwesomeIcon style={{ position: 'absolute', right: '0', top: 15 }} color="gray" icon={view ? faEye : faEyeSlash} />
                    </div>
                  </div>
                  <div style={{ position: 'relative', marginBottom: '15px' }}>
                    <input
                      type={view ? 'text' : 'password'}
                      className="form-control"
                      name="password"
                      value={state.password}
                      onChange={(e) => handleChange(e)}
                      placeholder={t('change_password.enter_new_pass_ph')}
                      aria-label="Email verification code"
                      aria-describedby="button-addon2"
                    />
                    <div className="eyeicon" onClick={() => setView(!view)}>
                      <FontAwesomeIcon style={{ position: 'absolute', right: '0', top: 15 }} color="gray" icon={view ? faEye : faEyeSlash} />
                    </div>
                  </div>
                  <div style={{ position: 'relative', marginBottom: '15px' }}>
                    <input
                      type={view ? 'text' : 'password'}
                      className="form-control"
                      name="confirmPassword"
                      value={state.confirmPassword}
                      onChange={(e) => handleChange(e)}
                      placeholder={t('change_password.enter_confirm_pass')}
                      aria-label="Email verification code"
                      aria-describedby="button-addon2"
                    />
                    <div className="eyeicon" onClick={() => setView(!view)}>
                      <FontAwesomeIcon style={{ position: 'absolute', right: '0', top: 15 }} color="gray" icon={view ? faEye : faEyeSlash} />
                    </div>
                  </div>
                  {errors ? (
                    <div
                      style={{ color: '#FE6E00', padding: '8px' }}
                      className="alert alert-danger"
                    >
                      {errors}
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="d-block">
                    <button type="button" className="btn w-100 enter-btn" onClick={(e) => handleSubmit(e)}>
                      {t('labels.confirm')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default ChangePassword;
