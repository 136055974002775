import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrency } from '../../redux/currencies/currencyActions';
import { addCard } from '../../redux/cards/creditCardActions';

function AddCards({ handleCloseCC }) {
  const { t } = useTranslation();
  const [label, setLabel] = useState('');
  const [card, setCard] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState([]);

  const dispatch = useDispatch();
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const userId = useSelector((state) => state.user?.user?._id);

  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  const handleAdd = () => {
    const data = {
      name: label,
      card,
      currencyId: selectedCurrency._id,
      userId,
    };

    dispatch(addCard(data));
    setSelectedCurrency([]);
    handleCloseCC();
  };

  return (
    <div className="withdrawal-modal2">
      <h4 className="text-green">{t('labels.add_card')}</h4>
      <form className="address-form wrap-address-form m-auto">
        <div className="input-group buttonInside">
          <p className="text-white">{t('labels.address_label')}</p>
          <input type="text" placeholder={t('labels.enter_address_label')} onChange={(e) => setLabel(e.target.value)} />
        </div>
        <p className="text-white">{t('labels.select_currency')}</p>
        <div className="dropdown deposit-dropdown">
          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <div className="d-flex justify-content-between">
              <p className="coin-name">
                {selectedCurrency && selectedCurrency.symbol ? selectedCurrency.name : `${t('labels.select_currency')}`}
              </p>
              <div className="coin-details d-flex align-items-center">
                {selectedCurrency && selectedCurrency.symbol ? (
                  <p className="detail">
                    (
                    {selectedCurrency.symbol}
                    )
                  </p>
                ) : <p className="detail">{`(${t('labels.symbol')})`}</p>}
                <p className="dd-arrow" />
              </div>
            </div>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {currencyData && currencyData.length > 0 && currencyData?.filter((row) => row?.isFiat === true)?.map((currency) => (
              <li onClick={() => { setSelectedCurrency(currency); }}>
                <a className="dropdown-item">
                  <div className="d-items d-flex justify-content-between">
                    <p>{currency.name}</p>
                    <p>{currency.symbol}</p>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>

        {selectedCurrency && selectedCurrency._id
          ? (
            <>
              <div className="input-group buttonInside">
                <p className="text-white">{t('labels.card_number')}</p>
                <input type="text" placeholder={t('labels.enter_card_number')} onChange={(e) => setCard(e.target.value)} />
              </div>
              <div className="d-block">
                <button type="button" onClick={() => handleAdd()} className="btn enter-btn3">{t('labels.continue')}</button>
              </div>
            </>
          )
          : ''}
      </form>
    </div>
  );
}

export default AddCards;
