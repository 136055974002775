import { toast } from 'react-toastify';
import {
  GET_ADMIN_DEPOSITS,
  GET_ADMIN_WITHDRAWS,
  TRANSACTION_ERROR,
  DEPOSIT_REQ,
} from './transationTypes';
import { apiHelper } from '../apiHelper';

export const getAdminDeposits = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/transaction/admin-deposits/${id}`, '');
    if (res?.data) {
      const { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: GET_ADMIN_DEPOSITS,
        payload: data,
      });
    } else {
      dispatch({
        type: TRANSACTION_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getAdminWithdraws = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/transaction/admin-withdraws/${id}`, '');
    if (res?.data) {
      const { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: GET_ADMIN_WITHDRAWS,
        payload: data,
      });
    } else {
      dispatch({
        type: TRANSACTION_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const submitDepositReq = (postData) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/transaction/deposit-req', postData);
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: DEPOSIT_REQ,
        payload: data,
      });
    } else {
      dispatch({
        type: TRANSACTION_ERROR,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
