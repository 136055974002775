export const SET_USER_VERIFY_STATUS = 'SET_USER_VERIFY_STATUS';
export const SET_VERIFICATION_PAGE_STEP = 'SET_VERIFICATION_PAGE_STEP';
export const SET_IS_DATA_LOADING = 'SET_IS_DATA_LOADING';
export const SET_ID_CARD_FRONT_FILE = 'SET_ID_CARD_FRONT_FILE';
export const SET_ID_CARD_BACK_FILE = 'SET_ID_CARD_BACK_FILE';
export const SET_CREDIT_CARD_FRONT_FILE = 'SET_CREDIT_CARD_FRONT_FILE';
export const SET_CREDIT_CARD_BACK_FILE = 'SET_CREDIT_CARD_BACK_FILE';
export const SET_SELFIE_FILE = 'SET_SELFIE_FILE';
export const SET_ID_CARD_FRONT_DATA = 'SET_ID_CARD_FRONT_DATA';
export const SET_ID_CARD_BACK_DATA = 'SET_ID_CARD_BACK_DATA';
export const SET_CREDIT_CARD_FRONT_DATA = 'SET_CREDIT_CARD_FRONT_DATA';
export const SET_CREDIT_CARD_BACK_DATA = 'SET_CREDIT_CARD_BACK_DATA';
export const SET_SELFIE_DATA = 'SET_SELFIE_DATA';
