import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const UserWalletAddressDataTableSchema = (copyReferral, formatDate, deleteAction) => {
  const { t } = useTranslation();
  const { isWithdrawalEnabled } = useSelector((state) => state?.user?.user);

  const columns = [
    {
      name: t('labels.address_label'),
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: t('labels.network'),
      selector: (row) => row?.symbol,
      sortable: true,
    },
    {
      name: t('labels.address'),
      selector: (row) => (
        <CopyToClipboard text={row?.address}>
          <span>
            {row?.address.slice(0, 4)}
            ...
            {row?.address.slice((row?.address.length || 0) - 4, row?.address.length)}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: t('labels.wallet_type'),
      selector: () => t('labels.withdrawal'),
      sortable: true,
      omit: !isWithdrawalEnabled,
    },
    {
      name: t('labels.date'),
      selector: (row) => formatDate(new Date(row?.createdAt)),
      sortable: true,
    },
    {
      name: t('labels.action'),
      selector: (row) => (
        <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => deleteAction(row._id)}>{t('labels.delete')}</button>
      ),
    },
  ];
  return columns;
};
