export const IsolatedLeverageMarks = {
  1: '1x',
  10: '10x',
  20: '20x',
  30: '30x',
  40: '40x',
  50: '50x',
};

export const CrossLeverageMarks = {
  1: '1x',
  25: '25x',
  50: '50x',
  75: '75x',
  100: '100x',
  125: '125x',
};
