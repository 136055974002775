import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { VerifyUser } from '../redux/auth/authActions';

export function VerifyAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      dispatch(VerifyUser(token, navigate));
    }
  }, [dispatch, token]);

  return null;
}
