import {
  GET_ORDER, 
  ADD_ORDER, 
  CLEAR_ORDER, 
  ORDER_BOOK,
  GET_USER_ORDER, 
  ORDER_ERROR, 
  STOP_ORDER, 
  START_ORDER, 
  ORDER_PNL,
  UPDATE_SOCKET_ORDER,
} from './leverageOrderTypes';

const initialState = {
  orders: [],
  userOrders: [],
  order: [],
  success: false,
  error: false,
  stopped: false,
  started: false,
  orderBook: [],
  orderPnL: 0,
};

const LeverageOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SOCKET_ORDER:
      const updatedOrderId = action.payload?._id;
      const updatedIndex = state.userOrders.findIndex((obj) => obj._id === updatedOrderId);

      let updatedUserOrders;
      if (updatedIndex !== -1) {
        updatedUserOrders = [...state.userOrders];
        updatedUserOrders[updatedIndex] = action.payload;
      } else {
        updatedUserOrders = [action.payload, ...state.userOrders];
      }

      return {
        ...state,
        userOrders: updatedUserOrders,
      };
    case ORDER_PNL:
      return {
        ...state,
        orderPnL: action.payload,
      };
    case GET_ORDER:
      return {
        ...state,
        orders: action.payload,
      };
    case GET_USER_ORDER:
      return {
        ...state,
        userOrders: action.payload,
      };
    case ADD_ORDER:
      return {
        ...state,
        order: action.payload,
        success: true,
        error: false,
      };
    case CLEAR_ORDER:
      return {
        ...state,
        order: [],
        success: false,
        stopped: false,
        started: false,
        error: false,
      };
    case STOP_ORDER:
      return {
        ...state,
        stopped: true,
        // success: false,
        // started: false,
        // error: false
      };
    case START_ORDER:
      return {
        ...state,
        started: true,
        // stopped: false,
        // success: false,
        // error: false
      };
    case ORDER_ERROR:
      return {
        ...state,
        error: true,
      };
    case ORDER_BOOK:
      return {
        ...state,
        orderBook: action.payload,
        success: false,
        error: false,
      };
    default:
      return state;
  }
};

export default LeverageOrderReducer;
