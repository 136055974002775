import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { calculateFee, toFixedAndTrim } from '../helper/helper';
import {
  selectConversionFromAmount, selectConversionFromCoin, selectConversionToCoin, selectExchangeRate, selectIsAllConditionsValid, 
} from '../../../redux/exchange/exchangeSelectors';


function ShowExchangeInfo() {
  const { t } = useTranslation();

  const isAllConditionsValid = selectIsAllConditionsValid();
  
  const conversionFromCoin = useSelector(selectConversionFromCoin);
  const conversionToCoin = useSelector(selectConversionToCoin);
  const conversionFromAmount = useSelector(selectConversionFromAmount);
  const exchangeRate = useSelector(selectExchangeRate);

  const fromCoinDecimal = conversionFromCoin.decimalPrecision;
  const conversionFromFee = conversionFromCoin.conversionFee || 0;
  const fromDecimalPrecision = conversionFromCoin.decimalPrecision || 2;
  const conversionFromSymbol = conversionFromCoin.symbol || 'Coin';
  const conversionToSymbol = conversionToCoin.symbol || 'Coin';

  const priceTitle = t('labels.price');
  const priceDesc = `1 ${conversionFromSymbol} = ${exchangeRate} ${conversionToSymbol}`;

  const inversePriceTitle = t('labels.inverse_price');
  const inversePriceValue = toFixedAndTrim((1 / exchangeRate), fromCoinDecimal);
  const inversePriceDesc = `1 ${conversionToSymbol} = ${inversePriceValue} ${conversionFromSymbol}`;

  const youWillSpendTitle = t('labels.you_will_spend');
  const youWillSpendAmount = parseFloat(conversionFromAmount);
  const youWillSpendDesc = `${Number(toFixedAndTrim(youWillSpendAmount, fromCoinDecimal))} ${conversionFromSymbol}`;
  
  const conversionFeeTitle = t('labels.conversion_fee');
  const conversationFeeAmount = calculateFee(conversionFromFee, conversionFromAmount, fromDecimalPrecision);
  const conversionFee = conversionFromFee > 0 ? toFixedAndTrim(conversationFeeAmount, fromCoinDecimal) : '';
  const conversionFeeDesc = `${conversionFee} ${conversionFromSymbol}`;

  return (
    <div
      className="crypto-have crypto-info-text"
      style={{ flexDirection: 'column', backgroundColor: 'unset' }}
    >
      {isAllConditionsValid ? (
        <>
          <div className="text-white-light mb-2 d-sm-flex justify-content-between w-100">
            <p>{priceTitle}</p>
            <p>{priceDesc}</p>
          </div>
          <div className="text-white-light mb-2 d-sm-flex justify-content-between w-100">
            <p>{inversePriceTitle}</p>
            <p>{inversePriceDesc}</p>
          </div>
          <div className="text-white-light mb-2 d-sm-flex justify-content-between w-100">
            <p>{youWillSpendTitle}</p>
            <p>{youWillSpendDesc}</p>
          </div>
          <div className="text-white-light mb-2 d-sm-flex justify-content-between w-100">
            <p>{conversionFeeTitle}</p>
            <p>{conversionFeeDesc}</p>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ShowExchangeInfo;
