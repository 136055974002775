/* eslint-disable no-plusplus */
import { apiHelper } from '../redux/apiHelper';

const getCoinsString = async () => {
  try {
    const response = await apiHelper('get', '/api/pair/get-coins-string?isEnabled=true');
    if (response.data.data) {
      return response.data.data;
    } 
    return 0;
  } catch (error) {
    console.error(error);
  }
};

export const pairHelper = async (binancePair) => {
  const pairsList = await getCoinsString();
  const binancePairToTWPair = {};
  for (let i = 0; i < pairsList.coins.length; i++) {
    binancePairToTWPair[pairsList.coins[i]] = pairsList.slicedValues[i];
  }
  return binancePairToTWPair[binancePair];
};
