import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addCard } from '../../../redux/cards/creditCardActions';
import styles from './style.module.css';

export function NewCardForm({
  setCardOptions, isBtnShown, userId, currencyId, cardOptions, isNotValidFields, setIsNotValidFields,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setYearOptions = () => {
    const yearsOptions = [];
    const year = new Date().getFullYear();

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 9; i++) {
      const nextYear = year + i;
      yearsOptions.push({
        value: String(nextYear).substring(2),
        label: nextYear,
      });
    }

    return yearsOptions;
  };

  const yearOptions = setYearOptions();

  const validateInputs = (name, value) => {
    const cardNumberRegex = /^$|^[0-9]+$/;
    const cardHolderNameRegex = /^(?:[a-zA-Z\s']+)?$/;

    if (name === 'number' && !cardNumberRegex.test(value)) {
      setIsNotValidFields((prev) => ({
        ...prev, [name]: true, numberError: 'Card number must contains only numbers',
      }));
      return false;
    };

    if (name === 'name' && !cardHolderNameRegex.test(value)) {
      setIsNotValidFields((prev) => ({
        ...prev, [name]: true, nameError: 'Cardholder name must contains only letters', 
      }));
      return false;
    };

    if (name === 'cvc' && !cardNumberRegex.test(value)) {
      setIsNotValidFields((prev) => ({
        ...prev, [name]: true, cvcError: 'CVV must contains only numbers', 
      }));
      return false;
    };

    if (name === 'number' && value.length > 19) {
      setIsNotValidFields((prev) => ({
        ...prev, [name]: true, numberError: 'Card number should not be more than 19 characters',  
      }));
      return false;
    };

    if (name === 'cvc' && value.length > 4) {
      setIsNotValidFields((prev) => ({
        ...prev, [name]: true, cvcError: 'CVV should not be more than 4 characters',  
      }));
      return false;
    };

    return true;
  };

  const handleChange = (name, value) => {
    const isValid = validateInputs(name, value);

    if (isValid) {
      setCardOptions((prev) => ({ ...prev, [name]: value }));
      setIsNotValidFields((prev) => ({ ...prev, [name]: false }));
    }

    if (name === 'month' || name === 'year') setCardOptions((prev) => ({ ...prev, expiry: `${prev.month}/${prev.year}` }));
  };

  const handleFocus = (name) => {
    setCardOptions((prev) => ({ ...prev, focus: (name === 'month' || name === 'year') ? 'expiry' : name }));
  };

  const saveCard = () => {
    const data = {
      userId,
      currencyId,
      card: cardOptions.number,
      name: cardOptions.name,
      cvv: cardOptions.cvc,
      expiredMonth: cardOptions.month,
      expiredYear: cardOptions.year,
    };

    dispatch(addCard(data));
  };

  return (
    <form className={styles.cardForm}>
      {isBtnShown && (
        <button
          type="button"
          className="btn add-address-btn"
          style={{ alignSelf: 'flex-end' }}
          onClick={saveCard}
        >
          {t('labels.save_this_card')}
        </button>
      )}
      <div className={styles.cardFormRows}>
        <label htmlFor="input-card-name">{t('labels.cardholder_name')}</label>
        <input 
          type="text" 
          name="name" 
          id="input-card-name" 
          className={styles.cardFormInput}
          value={cardOptions.name}
          onChange={({ target: { value, name } }) => handleChange(name, value)}
          onFocus={({ target: { name } }) => handleFocus(name)}
        />
        {isNotValidFields.name && <p>{isNotValidFields.nameError}</p>}
      </div>
      <div className={styles.cardFormRows}>
        <label htmlFor="input-card-number">{t('labels.card_number')}</label>
        <input 
          type="text" 
          name="number" 
          id="input-card-number" 
          value={cardOptions.number}
          className={styles.cardFormInput}
          onChange={({ target: { value, name } }) => handleChange(name, value)}
          onFocus={({ target: { name } }) => handleFocus(name)}
        />
        {isNotValidFields.number && <p>{isNotValidFields.numberError}</p>}
      </div>
      <div className={styles.cardFormRows}>
        <label>{t('labels.expiration_date')}</label>
        <div className={styles.twoElSelect}>
          <select
            required
            name="month"
            onChange={({ target: { value, name } }) => handleChange(name, value)}
            onFocus={({ target: { name } }) => handleFocus(name)}
          >
            <option value=" ">{t('labels.month')}</option>
            <option value="01">{t('labels.january')}</option>
            <option value="02">{t('labels.february')}</option>
            <option value="03">{t('labels.march')}</option>
            <option value="04">{t('labels.april')}</option>
            <option value="05">{t('labels.may')}</option>
            <option value="06">{t('labels.june')}</option>
            <option value="07">{t('labels.july')}</option>
            <option value="08">{t('labels.august')}</option>
            <option value="09">{t('labels.september')}</option>
            <option value="10">{t('labels.october')}</option>
            <option value="11">{t('labels.november')}</option>
            <option value="12">{t('labels.december')}</option>
          </select>
          <select
            required
            name="year"
            onChange={({ target: { value, name } }) => handleChange(name, value)}
            onFocus={({ target: { name } }) => handleFocus(name)}
          >
            <option value=" ">{t('labels.year')}</option>
            { yearOptions.map((option) => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
      </div>
      <div className={styles.cardFormRows}>
        <label htmlFor="input-card-cvv">CVV</label>
        <input 
          type="text" 
          name="cvc" 
          id="input-card-cvv" 
          className={styles.cardFormInput}
          value={cardOptions.cvc}
          onChange={({ target: { value, name } }) => handleChange(name, value)}
          onFocus={({ target: { name } }) => handleFocus(name)}
        />
        {isNotValidFields.cvc && <p>{isNotValidFields.cvcError}</p>}
      </div>
    </form>
  );
}
