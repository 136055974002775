import { apiHelper } from '../redux/apiHelper';

// Makes requests to CryptoCompare API
export async function makeApiRequest(path) {
  if (!path || !path.market) return { error: true, message: `Wrong msrket name: ${path?.market}` };
  const response = await apiHelper('get', `/api/chart/get-${path.market}-bars/${path.fromSymbol + path.toSymbol}&${path.interval}&${path.userId}`);

  return response.data;
}

// Get Data from Pair Trade API
export async function getPairTrade(symbolName) {
  try {
    if (symbolName) {
      const response = await apiHelper('get', `/api/pair/get-pair/${symbolName}`);

      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }
  return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}
