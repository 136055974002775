import { toast } from 'react-toastify';
import { apiHelper, isSuccessResponse } from '../apiHelper';
import { GET_DEPOSIT_TYPES } from './depositTypes';

export const getDepositTypes = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/payments/deposit-types');

    if (isSuccessResponse(res)) {
      await dispatch({
        type: GET_DEPOSIT_TYPES,
        payload: res.data,
      });
    }
  } catch (error) {
    toast.error(error.message);
  }
};
