import React from 'react';
import Deposit from '../components/Deposit/Deposit';

function DepositPage() {
  return (
    <Deposit />
  );
}

export default DepositPage;
