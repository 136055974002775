import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import ReactFlagsSelect from 'react-flags-select';
import { RegisterUser } from '../redux/auth/authActions';
import { getClientRoles } from '../redux/roles/roleActions';
import { ENV } from '../config/config';

function RegisterStarbitrex() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.role?.roles.roles);
  const initialUserState = {
    firstName: '', 
    lastName: '', 
    username: '', 
    email: '', 
    phoneNumber: '', 
    password: '', 
    confirmPassword: '', 
    referralCode: '', 
    dateOfBirth: '', 
    additionalInfo: '',
  };
  const [user, setUser] = useState(initialUserState);
  const [errors, setErrors] = useState('');
  const [viewPass, setViewPass] = useState(0);
  const [viewCPass, setViewCPass] = useState(0);
  const [selectedFlagCountry, setSelectedFlagCountry] = useState('');
  const [, setLoader] = useState(true);

  useEffect(() => {
    dispatch(getClientRoles());
  }, []);

  useEffect(async () => {
    if (roles) setLoader(false);
  }, [roles]);

  const handleChange = ({ target: { name, value } }) => {
    setUser(((prevState) => ({ ...prevState, [name]: value })));
  };

  const registerDEXSystemUser = (roleId) => {
    const {
      email, password, confirmPassword, referralCode,
    } = user;

    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email || !email.length) {
      setErrors(t('register.email_required'));
    } else if (!email.match(regexp)) {
      setErrors(t('register.email_error'));
    } else if (!password || !password.length) {
      setErrors(t('register.pass_required'));
    } else if (password.length < 5) {
      setErrors(t('register.pass_length'));
    } else if (password !== confirmPassword) {
      setErrors(t('register.pass_not_match'));
    } else {
      setErrors('');

      const newUser = {
        email: email.toLowerCase(),
        password,
        referralCode,
        roleId,
        clientType: 1,
        hostname: window.location.host,
        isDEXSystem: true,
      };

      dispatch(RegisterUser(newUser));
    }
  };

  const registerCEXSystemUser = (roleId) => {
    const {
      firstName, 
      lastName, 
      username, 
      email, 
      password, 
      confirmPassword, 
      referralCode, 
      dateOfBirth, 
      phoneNumber,
      additionalInfo,
    } = user;

    const exp = /^[a-z A-Z а-я А-Я]+$/;
    // eslint-disable-next-line no-unused-vars
    const numCheck = /^[0-9]*$/;
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (firstName === '') {
      setErrors(t('register.first_name_required'));
    } else if (!firstName.match(exp)) {
      setErrors(t('register.first_name_error'));
    } else if (lastName === '') {
      setErrors(t('register.last_name_required'));
    } else if (!lastName.match(exp)) {
      setErrors(t('register.last_name_error'));
    } else if (username === '') {
      setErrors(t('register.username_required'));
    } else if (email === '') {
      setErrors(t('register.email_required'));
    } else if (!email.match(regexp)) {
      setErrors(t('register.email_error'));
    } else if (dateOfBirth === '') {
      setErrors(t('register.date_birth_required'));
    } else if (password === '') {
      setErrors(t('register.pass_required'));
    } else if (password.length < 5) {
      setErrors(t('register.pass_length'));
    } else if (password !== confirmPassword) {
      setErrors(t('register.pass_not_match'));
    } else {
      setErrors('');

      const newUser = {
        firstName,
        lastName,
        username,
        email,
        phone: phoneNumber,
        password,
        dateOfBirth,
        additionalInfo,
        referralCode,
        roleId,
        countryCode: selectedFlagCountry,
        clientType: 1,
        hostname: window.location.host,
      };
      
      dispatch(RegisterUser(newUser));
    }
  };

  const handleSubmit = async () => {
    let roleId = '';
    roles?.forEach((element) => {
      if (element.name === 'Client') {
        roleId = element._id;
      }
    });

    if (ENV.isDEXSystem) {
      registerDEXSystemUser(roleId);
    } else {
      registerCEXSystemUser(roleId);
    }
  };

  return (
    <>
      {
        !ENV.isDEXSystem && (
          <>
            <div className="password-input-field">
              <p className="text-white-light">{t('labels.first_name')}</p>
              <div>
                <input type="text" className="text-light" placeholder={`${t('labels.type_first_name')}...`} name="firstName" value={user.firstName} onChange={handleChange} />
              </div>
            </div>
            <div className="password-input-field">
              <p className="text-white-light">{t('labels.last_name')}</p>
              <div>
                <input type="text" className="text-light" placeholder={`${t('labels.type_last_name')}...`} name="lastName" value={user.lastName} onChange={handleChange} />
              </div>
            </div>
          </>
        )
      }
      <div className="password-input-field">
        <p className="text-white-light">{t('labels.email')}</p>
        <div>
          <input type="email" className="text-light" placeholder={`${t('login.type_email')}...`} name="email" value={user.email} onChange={handleChange} />
        </div>
      </div>
      {
        !ENV.isDEXSystem && (
          <>
            <div className="react-select-country">
              <p className="text-white-light">{t('labels.select_country')}</p>
              <div>
                <ReactFlagsSelect
                  selected={selectedFlagCountry}
                  onSelect={(code) => setSelectedFlagCountry(code)}
                  searchable
                  searchPlaceholder={t('labels.search_for_country')}
                  className="country-react-flags-select"
                />
              </div>
            </div>
            <div className="password-input-field">
              <p className="text-white-light">{t('labels.phone_number')}</p>
              <div>
                <input type="text" className="text-light" placeholder={`${t('labels.type_phone_number')}...`} name="phoneNumber" value={user.phoneNumber} onChange={handleChange} />
              </div>
            </div>
            <div className="password-input-field dob">
              <p className="text-white-light">{t('labels.select_date_birth')}</p>
              <div>
                <input type="date" className="text-light" placeholder={`${t('labels.type_date_of_birth')}...`} name="dateOfBirth" value={user.dateOfBirth} onChange={handleChange} />
              </div>
            </div>
          </>
        )
      }
      <div className="password-input-field">
        <p className="text-white-light">{t('labels.choose_password')}</p>
        <div>
          <span onClick={() => setViewPass(!viewPass)}><FontAwesomeIcon className="faeye" icon={viewPass ? faEyeSlash : faEye} /></span>
          <input type={viewPass ? 'text' : 'password'} className="text-light" placeholder={`${t('login.type_password')}...`} name="password" value={user.password} onChange={handleChange} />
        </div>
      </div>
      <div className="password-input-field">
        <p className="text-white-light">{t('labels.repeat_password')}</p>
        <div>
          <span onClick={() => setViewCPass(!viewCPass)}><FontAwesomeIcon className="faeye" icon={viewCPass ? faEyeSlash : faEye} /></span>
          <input type={viewCPass ? 'text' : 'password'} className="text-light" placeholder={`${t('labels.confirm_password')}...`} name="confirmPassword" value={user.confirmPassword} onChange={handleChange} />
        </div>
      </div>
      {
        !ENV.isDEXSystem && (
          <div className="password-input-field">
            <p className="text-white-light">{t('labels.username')}</p>
            <div>
              <input type="text" className="text-light" placeholder={`${t('labels.type_username')}...`} name="username" value={user.username} onChange={handleChange} />
            </div>
          </div>
        )
      }
      <div className="password-input-field">
        <p className="text-white-light">{t('labels.referral_code')}</p>
        <div>
          <input type="text" className="text-light" placeholder={`${t('labels.enter_referral_code')}...`} name="referralCode" value={user.referralCode} onChange={handleChange} />
        </div>
      </div>
      {
        !ENV.isDEXSystem && (
          <div className="password-input-field col-md-12 register-ad-info">
            <p className="text-white-light">{t('labels.additional_info')}</p>
            <div className="form-group">
              <textarea className="form-control" name="additionalInfo" value={user.additionalInfo} rows="3" onChange={handleChange} />
            </div>
          </div>
        )
      }
      {errors ? (
        <div style={{ color: '#FE6E00' }} className="alert alert-danger">
          {' '}
          {errors}
          {' '}
        </div>
      ) : ('')}
      <div className="text-center">
        <button type="button" className="btn enter-btn" style={{ textTransform: 'uppercase' }} onClick={() => handleSubmit()}>
          {t('labels.sign_up')}
        </button>
      </div>
    </>
  );
}

export default RegisterStarbitrex;
