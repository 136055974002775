import {
  GET_STAKING_INVESTMENTS,
  GET_STAKING_INVESTMENT,
  ADD_STAKING_INVESTMENT,
  EDIT_STAKING_INVESTMENT,
  CLAIM_STAKING_INVESTMENT,
  DELETE_STAKING_INVESTMENT,
  TOGGLE_STAKING_INVESTMENT_STATE,
  ERROR_STAKING_INVESTMENT_STATE,
} from './stakingInvestmentTypes';

const initialState = {
  stakingInvestments: [],
  stakingInvestment: {},
  stakingInvestmentsFetched: false,
  stakingInvestmentAdded: false,
  stakingInvestmentFetched: false,
  stakingInvestmentEditted: false,
  stakingInvestmentDeleted: false,
  stakingInvestmentClaimed: false,
  stakingInvestmentError: false,
};

const stakingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAKING_INVESTMENTS:
      return {
        ...state,
        stakingInvestments: action.payload,
        stakingInvestmentsFetched: true,
      };
    case GET_STAKING_INVESTMENT:
      return {
        ...state,
        stakingInvestment: action.payload,
        stakingInvestmentFetched: true,
      };
    case ADD_STAKING_INVESTMENT:
      return {
        ...state,
        stakingInvestments: [...state.stakingInvestments, action.payload],
        stakingInvestmentAdded: true,
      };
    case EDIT_STAKING_INVESTMENT:
      return {
        ...state,
        stakingInvestment: action.payload,
        stakingInvestmentEditted: true,
      };
    case DELETE_STAKING_INVESTMENT:
      return {
        ...state,
        stakingInvestments: state.stakingInvestments.filter((item) => item._id !== action.payload),
        stakingInvestmentDeleted: true,
      };
    case CLAIM_STAKING_INVESTMENT:
      const index = state.stakingInvestments.findIndex((obj) => obj._id === action.payload?._id);
      // eslint-disable-next-line no-unused-expressions, no-param-reassign
      index !== -1 ? state.stakingInvestments[index] = action.payload : null;
      return {
        ...state,
        stakingInvestments: state.stakingInvestments,
        stakingInvestmentClaimed: true,
      };
    case TOGGLE_STAKING_INVESTMENT_STATE:
      return {
        ...state,
        stakingInvestmentsFetched: false,
        stakingInvestmentAdded: false,
        stakingInvestmentFetched: false,
        stakingInvestmentEditted: false,
        stakingInvestmentDeleted: false,
        stakingInvestmentClaimed: false,
        stakingInvestmentError: false,
      };
    case ERROR_STAKING_INVESTMENT_STATE:
      return {
        ...state,
        stakingInvestmentError: true,
      };
    default:
      return state;
  }
};

export default stakingReducer;
