import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { socket } from '../../web';

let futures = [];
let futuresCounter = 0;

export function MarketTable({
  primaryCoin, secondaryCoin, userId, today, coinsToPrecisions,
}) {
  const { t } = useTranslation();
  const [futureTradesAPI, setFutureTradesAPI] = useState([]);
  const pairName = useParams().coins;
  
  useEffect(() => {
    if (pairName && userId && today) {
      socket.emit('getBinanceFutureTradesRequest', { pairName, userId, today });
  
      socket.on(`getBinanceFutureTradesRequestResponse${pairName}${userId}${today}`, (response) => {
        futuresCounter += 1;
        if (futuresCounter % 20 === 0) {
          futures.unshift(response);
          if (futures.length > 20) futures = futures.splice(0, 20);
          setFutureTradesAPI(futures);
          futuresCounter = 0;
        }
      });
    }
  
    return () => {
      futures = [];
      futuresCounter = 0;
      setFutureTradesAPI([]);
      if (socket) socket.off(`getBinanceFutureTradesRequestResponse${pairName}${userId}${today}`);
    };
  }, [pairName, userId, today]);
  
  return (
    <div style={{ height: '650px', overflow: 'hidden' }}>
      <table style={{ width: '100%' }} className="trade-table market-table">
        <thead>
          <tr>
            <th>{t('labels.time')}</th>
            <th>
              {`${t('labels.price')}(${primaryCoin?.symbol})`}
            </th>
            <th>
              {`${t('labels.amount')}(${secondaryCoin?.symbol})`}
            </th>
          </tr>
        </thead>
        <tbody>
          {futureTradesAPI && futureTradesAPI.length ? (
            futureTradesAPI.map((row, key = 0) => (
              <tr key={key}>
                <td>
                  {new Intl.DateTimeFormat('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  }).format(row.E)}
                </td>
                <td className={row.m ? 'text-green' : 'text-red'}>
                  {parseFloat(row.p).toFixed(coinsToPrecisions?.[pairName] || 2)}
                </td>
                <td>{parseFloat(row.q).toFixed(2)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3">{t('messages.no_data_found')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
  
