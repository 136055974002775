import React from 'react';
import Header from '../components/Header/Header';
import Banner from '../components/LandingPageStarbitrex/Banner';
import MarketTrend from '../components/LandingPageStarbitrex/MarketTrend';
import TradeAnyway from '../components/LandingPageStarbitrex/TradeAnyway';
import GetInTouch from '../components/LandingPageStarbitrex/GetInTouch';
import Footer from '../components/Footer/Footer';

function LandingPageStarbitrex() {
  return (
    <>
      <Header />
      <Banner />
      <MarketTrend />
      <TradeAnyway />
      <GetInTouch />
      <Footer />
    </>
  );
}

export default LandingPageStarbitrex;
