import { 
  GET_CRYPTO_ORDER, 
  GET_USER_CRYPTO_ORDER,
  ADD_CRYPTO_ORDER, 
  CLEAR_CRYPTO_ORDER, 
  CRYPTO_ORDER_ERROR, 
  STOP_CRYPTO_ORDER, 
  START_CRYPTO_ORDER, 
  CRYPTO_ORDER_PNL,
  CRYPTO_ORDER_BOOK,
} from './cryptoOrderTypes';
 
const initialState = {
  cryptoOrders: [],
  userCryptoOrders: [],
  cryptoOrder: [],
  cryptoError: false,
  cryptoStopped: false,
  cryptoStarted: false,
  cryptoOrderBook: [],
  cryptoOrderPnL: 0,
  cryptoOrderPnLFetched: false,
  cryptoOrdersFetched: false,
  userCryptoOrdersFetched: false,
  cryptoOrderFetched: false,
  cryptoOrderBookFetched: false,
  cryptoOrderAdded: false,
};
 
const CryptoOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CRYPTO_ORDER_PNL:
      return {
        ...state,
        cryptoOrderPnL: action.payload,
        cryptoOrderPnLFetched: true,
      };
    case GET_CRYPTO_ORDER:
      return {
        ...state,
        cryptoOrders: action.payload,
        cryptoOrdersFetched: true,
      };
    case GET_USER_CRYPTO_ORDER:
      return {
        ...state,
        userCryptoOrders: action.payload,
        userCryptoOrdersFetched: true,
      };
    case ADD_CRYPTO_ORDER:
      return {
        ...state,
        cryptoOrder: action.payload,
        cryptoOrderAdded: true,
      };
    case CRYPTO_ORDER_BOOK:
      return {
        ...state,
        cryptoOrderBook: action.payload,
        cryptoOrderBookFetched: true,
      };
    case CLEAR_CRYPTO_ORDER:
      return {
        ...state,
        cryptoStopped: false,
        cryptoStarted: false,
        cryptoError: false,
        cryptoOrderPnLFetched: false,
        cryptoOrdersFetched: false,
        userCryptoOrdersFetched: false,
        cryptoOrderFetched: false,
        cryptoOrderBookFetched: false,
        cryptoOrderAdded: false,
      };
    case STOP_CRYPTO_ORDER:
      return {
        ...state,
        cryptoStopped: true,
      };
    case START_CRYPTO_ORDER:
      return {
        ...state,
        cryptoStarted: true,
      };
    case CRYPTO_ORDER_ERROR:
      return {
        ...state,
        cryptoError: true,
      };
    default:
      return state;
  }
};
 
export default CryptoOrderReducer;
