import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIdCardBackData, selectIdCardBackFile, selectIdCardFrontData, selectIdCardFrontFile, selectIsDataLoading, 
} from '../../../redux/kycVerification/kycVerificationSelectors';
import {
  setIdCardBackData,
  setIdCardBackFile, setIdCardFrontData, setIdCardFrontFile, setVerificationPageStep, 
} from '../../../redux/kycVerification/kycVerificationAction';
import { verifiedStatusColors } from '../../../redux/kycVerification/kycVerificationReducer';
import s from './IdCardModule.module.css';
import VerifyFileInput from '../VerifyFileInput/VerifyFileInput';

function IdCardModule() {
  const dispatch = useDispatch();
  
  const isDataLoading = useSelector(selectIsDataLoading);
  const idCardFrontFile = useSelector(selectIdCardFrontFile);
  const idCardBackFile = useSelector(selectIdCardBackFile);
  const idCardFrontData = useSelector(selectIdCardFrontData);
  const idCardBackData = useSelector(selectIdCardBackData);

  const isCardSelected = (cardData, cardFile) => (cardData ? cardData.verifiedStatus !== 'declined' : cardFile);

  const isFrontCardSelected = isCardSelected(idCardFrontData, idCardFrontFile);
  const isBackCardSelected = isCardSelected(idCardBackData, idCardBackFile);

  const isSkipBtnAvailable = !isFrontCardSelected && !isBackCardSelected;
  const isProcessBtnAvailable = isFrontCardSelected || isBackCardSelected;

  const processBtnClassName = isProcessBtnAvailable ? `${s.processBtn} ${s.readyProcessBtn}` : s.processBtn;
  const processBtnTitle = idCardFrontData 
    ? (!isFrontCardSelected || !isBackCardSelected) ? 'Process' : 'Next'
    : 'Process';

  const frontColor = idCardFrontData 
    ? verifiedStatusColors[idCardFrontData.verifiedStatus] 
    : idCardFrontFile ? '#09C575' : 'white';

  const backColor = idCardBackData 
    ? verifiedStatusColors[idCardBackData.verifiedStatus] 
    : idCardBackFile ? '#09C575' : 'white';

  const handleSkipBtn = () => {
    if (!idCardFrontData) dispatch(setIdCardFrontFile(null));
    if (!idCardBackData) dispatch(setIdCardBackFile(null));
    dispatch(setVerificationPageStep('creditCard'));
  };

  const handleProcessBtn = () => {
    if (isProcessBtnAvailable) {
      dispatch(setVerificationPageStep('creditCard'));
    }
  };

  const handleIdCardFrontChange = ({ target: { files } }) => {
    if (files && files.length > 0) {
      dispatch(setIdCardFrontFile(files[0]));
      dispatch(setIdCardFrontData(null));
    }
  };

  const handleIdCardBackChange = ({ target: { files } }) => {
    if (files && files.length > 0) {
      dispatch(setIdCardBackFile(files[0]));
      dispatch(setIdCardBackData(null));
    }
  };

  if (isDataLoading) {
    return (
      <>Loading</>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className={s.uploadInputsWrapper}>
        <VerifyFileInput
          type="id-card-front"
          labelTitle="Id Card Front" 
          borderTitle="A clear photo of your id card front" 
          statusColor={frontColor}
          onChange={handleIdCardFrontChange}
        />
        <VerifyFileInput
          type="id-card-back"
          labelTitle="Id Card Back" 
          borderTitle="A clear photo of your id card back" 
          statusColor={backColor}
          onChange={handleIdCardBackChange}
        />
      </div>

      <hr style={{ color: 'white' }} />

      <div className={s.buttonsWrapper}>
        <div className={processBtnClassName} onClick={handleProcessBtn}>{processBtnTitle}</div>
        {isSkipBtnAvailable && <span className={s.skipForNowBtn} onClick={handleSkipBtn}>Skip for now</span>}
      </div>
    </div>
  );
}

export default IdCardModule;
