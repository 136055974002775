import { toast } from 'react-toastify';
import { ADD_CARD, GET_CARDS, DELETE_CARD } from './creditCardTypes';
import { apiHelper } from '../apiHelper';

export const addCard = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/credit-card/add', data);

    if (res && res.data && res.data.success) {
      await dispatch({
        type: ADD_CARD,
        payload: res.data.creditCard,
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getCards = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/credit-card/${userId}`, '');
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CARDS,
        payload: res.data.creditCards,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const deleteCard = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/credit-card/${id}`, '');
    if (res && res.data && res.data.success) {
      await dispatch({
        type: DELETE_CARD,
        payload: res.data.creditCard,
      });

      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
