import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HistoryTable } from './histroryBook';
import { MarketTable } from './marketTable';

export function Order({
  selectRate, primaryCoin, secondaryCoin, today, coinsToPrecisions,
}) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('History');
  const userId = useSelector((state) => state.user?.user?._id);

  return (
    <div className="order-book-market">
      <div className="order-book-market-wrapper">
        <div className="order-book-market-header">
          <div
            style={{ cursor: 'pointer' }}
            className={activeTab === 'History' ? 'tab-active' : ''}
            onClick={() => setActiveTab('History')}
          >
            {t('labels.history')}
          </div>
          <div
            style={{ cursor: 'pointer' }}
            className={activeTab === 'History' ? '' : 'tab-active'}
            onClick={() => setActiveTab('Market')}
          >
            {t('labels.market_trading')}
          </div>
        </div>
        {activeTab === 'History' ? (
          <HistoryTable 
            coinsToPrecisions={coinsToPrecisions}
            selectRate={selectRate} 
            userId={userId}
            today={today}
          />
        ) : (
          <MarketTable
            coinsToPrecisions={coinsToPrecisions}
            primaryCoin={primaryCoin}
            secondaryCoin={secondaryCoin}
            userId={userId}
            today={today}
          />
        )}
      </div>
    </div>
  );
}
