import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook, faLinkedin, faInstagram, faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/images/STBRX-new-logo-01.png';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="footer-bg">
        <Container>
          <div className="footer-header">
            {/* <div className="footer-circle"></div> */}
            <div className="row">
              <div className="col-lg-4 mb-lg-0 mb-5">
                <a className="footer-mb d-block">
                  <figure className="mb-0">
                    <Image src={Logo} alt="" fluid />
                  </figure>
                </a>
                <p className="site-text">
                  {t('footer.main_text', { brand_name: process.env.REACT_APP_BRANDNAME })}
                </p>
                <div className="read-button">
                  <a className="read-btn">
                    {t('labels.read_more')}
                    <FontAwesomeIcon className="fa" icon={faAngleRight} />
                  </a>
                </div>
                {/* <h3 className="text-capitalize footer-mb">{t('labels.community')}</h3>
                <div className="social-icons">
                  <ul>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>
                    </li>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faLinkedin} />
                      </a>
                    </li>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>
                    </li>
                    <li>
                      <a>
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="col-lg-7 offset-lg-1">
                <div className="row">
                  <div className="col-lg-4 col-md-4 mb-lg-0 mb-4">
                    <h3 className="text-capitalize footer-mb">{t('labels.about_us')}</h3>
                    <ul className="footer-links">
                      <li>
                        <a>{t('labels.about')}</a>
                      </li>
                      <li>
                        <a>{t('labels.compliance')}</a>
                      </li>
                      <li>
                        <a>{t('labels.markets')}</a>
                      </li>
                    </ul>
                    {/* <ul className="footer-links">
                      <li>
                        <a>{t('labels.about')}</a>
                      </li>
                      <li>
                        <a>{t('labels.careers')}</a>
                      </li>
                      <li>
                        <a>{t('labels.business_contact')}</a>
                      </li>
                      <li>
                        <a>{t('labels.community')}</a>
                      </li>
                      <li>
                        <a>{`${process.env.REACT_APP_BRANDNAME} ${t('labels.blog')}`}</a>
                      </li>
                      <li>
                        <a>{t('labels.terms')}</a>
                      </li>
                      <li>
                        <a>{t('labels.privacy')}</a>
                      </li>
                      <li>
                        <a>{t('labels.announcements')}</a>
                      </li>
                    </ul> */}
                  </div>
                  <div className="col-lg-4 col-md-4 mb-lg-0 mb-4">
                    <h3 className="text-capitalize footer-mb">{t('labels.services')}</h3>
                    <ul className="footer-links">
                      <li>
                        <a>{t('labels.buy_crypto')}</a>
                      </li>
                      <li>
                        <a>{t('labels.funding')}</a>
                      </li>
                      <li>
                        <a>{t('labels.account_types')}</a>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="col-lg-4 col-md-4 mb-lg-0 mb-1">
                    <h3 className="text-capitalize footer-mb">{t('labels.support')}</h3>
                    <ul className="footer-links">
                      <li>
                        <a>{t('labels.give_us_feedback')}</a>
                      </li>
                      <li>
                        <a>{t('labels.support_center')}</a>
                      </li>
                      <li>
                        <a>{t('labels.fees')}</a>
                      </li>
                      <li>
                        <a>{t('labels.trading_rules')}</a>
                      </li>
                      <li>
                        <a>{`${process.env.REACT_APP_BRANDNAME} ${t('labels.verify')}`}</a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="container-fluid custom-box">
          <div className="footer-bottom">
            <p className="copyright text-center mb-0">
              {`${t('labels.copyright')}@${process.env.REACT_APP_BRANDNAME} 2022. ${t('labels.all_right_reserved')}`}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
