import React, { useState, useEffect } from 'react';
import {
  InputGroup,
  FormControl,
  Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { getMinByObjectNumberKey, getMaxByObjectNumberKey } from './constant/helper';
import { CrossLeverageMarks, IsolatedLeverageMarks } from './constant/constants';
import { MarginType } from '../../../types/order';
  
const getMarksByMargin = (margin) => {
  if (margin === MarginType.CROSS) {
    return CrossLeverageMarks;
  }
  return IsolatedLeverageMarks;
};

function SetLeverageModal({
  margin,
  setMargin,
  show,
  onHide,
  leverage,
  setLeverage,
  isLeverageChangeEnabled,
}) {
  const { t } = useTranslation();
  const [selectedLeverage, setSelectedLeverage] = useState(leverage || 1);
  const [min, setMin] = useState(getMinByObjectNumberKey(getMarksByMargin(margin)));
  const [max, setMax] = useState(getMaxByObjectNumberKey(getMarksByMargin(margin)));
  
  const handleSliderChange = (value) => {
    setSelectedLeverage(value);
  };

  const handleMarginChange = (newMargin) => {
    const newMax = getMaxByObjectNumberKey(getMarksByMargin(newMargin));
    const newMin = getMinByObjectNumberKey(getMarksByMargin(newMargin));

    setMargin(newMargin);
    setMin(newMin);
    setMax(newMax);
  
    if (selectedLeverage > newMax) {
      setSelectedLeverage(newMax);
    }

    if (selectedLeverage < newMin) {
      setSelectedLeverage(newMin);
    }
  };

  const handleConfirm = () => {
    setLeverage(selectedLeverage);
    onHide();
  };

  useEffect(() => {
    setSelectedLeverage(leverage);
  }, [leverage]); 

  return (
    <Modal
      className="withdrawal-modal modal-wrapper modal-wrapper-width set-leverage-modal trade-page-modal"
      onHide={onHide}
      show={show}
      centered
    >
      <Modal.Body>
        <div className="modal-main-heading-content mb-4">
          <h5 className="modal-title text-white">{t('labels.margin_mode')}</h5>
        </div>
        <div className="by-switch full by-switch--rectangle mb-3">
          <div
            onClick={() => handleMarginChange(MarginType.CROSS)}
            className={margin === MarginType.CROSS ? 'by-switch__item by-switch__item--active' : 'by-switch__item'}
          >
            {t('labels.cross')}
          </div>
          <div
            onClick={() => handleMarginChange(MarginType.ISOLATED)}
            className={margin === MarginType.ISOLATED ? 'by-switch__item by-switch__item--active' : 'by-switch__item'}
          >
            {t('labels.isolated')}
          </div>
        </div>
        <p className="trade-modal-para">
          {margin === MarginType.CROSS ? t('messages.cross_margin_info') : t('messages.isolated_margin_info')}
        </p>
        <div className="leverage-long mt-4">
          <div className="modal-main-heading-content d-flex justify-content-between align-items-center mb-3">
            <h5 className="modal-title text-white" id="exampleModalLabel">
              {t('labels.leverage')}
            </h5>
          </div>
          <InputGroup className="mb-3">
            <FormControl
              placeholder={t('labels.leverage')}
              disabled
              className="text-center"
              value={selectedLeverage}
            />
          </InputGroup>
          {isLeverageChangeEnabled && (
            <Slider
              min={min}
              max={max}
              step={1}
              marks={getMarksByMargin(margin)}
              value={selectedLeverage}
              onChange={handleSliderChange}
              className="mb-4 sliderTrade"
            />
          )}
        </div>
        <div className="limit-modal-btns mt-4">
          <button
            onClick={handleConfirm}
            type="button"
            className="btn confirm w-100"
          >
            {t('labels.confirm')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SetLeverageModal;
