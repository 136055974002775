import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

export function ChatHeader({ hideChat }) {
  const { t } = useTranslation();

  return (
    <div className="chat-header">
      <span className="chat-header__suppot-name">
        {t('labels.support')}
        {' '}
        {process.env.REACT_APP_BRANDNAME}
      </span>
      <FontAwesomeIcon className="pointer" icon={faXmark} onClick={() => hideChat()} />
    </div>
  );
}
