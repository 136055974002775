import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  selectConversionFromAmount, selectConversionFromCoin, selectConversionToAmount, selectConversionToCoin,
} from '../../../redux/exchange/exchangeSelectors';
import { calculateFee } from '../helper/helper';
import styles from '../style.module.css';


function ConfirmationModal({
  showConfirmation, 
  handleCloseConfirmation,
  handleSubmit,
}) {
  const { t } = useTranslation();

  const isCreatingExchangeOrder = useSelector((state) => state.internalOrders?.isLoading);

  const conversionFromAmount = useSelector(selectConversionFromAmount);
  const conversionFromCoin = useSelector(selectConversionFromCoin);
  const conversionToCoin = useSelector(selectConversionToCoin);
  const conversionToAmount = useSelector(selectConversionToAmount);

  const feeAmount = calculateFee(conversionFromCoin.conversionFee, conversionFromAmount, conversionFromCoin.decimalPrecision);
  const feeAmountDescription = conversionFromCoin.conversionFee > 0 ? `${feeAmount} ${conversionFromCoin.symbol}` : 0;

  return (
    <Modal
      className="withdraw-details two-factor-auth text-center"
      centered
      backdrop="static"
      show={showConfirmation}
      onHide={handleCloseConfirmation}
    >
      <Modal.Header className="modal-main-heading" closeButton />
      <Modal.Body className="text-white">
        <p>
          <b>
            {`${t('labels.converting')} ${conversionFromCoin.symbol} to ${conversionToCoin.symbol}`}
          </b>
        </p>
        <p>
          <b>
            {`${t('labels.amount')}: ${conversionFromAmount} ${conversionFromCoin.symbol}`}
            {' = '}
            {`${conversionToAmount} ${conversionToCoin.symbol}`}
          </b>
        </p>
        <p>
          <b>
            {`${t('labels.conversion_fee')}: ${feeAmountDescription}`}
          </b>
        </p>
        <br />
        <div className="limit-modal-btns">
          <button disabled={isCreatingExchangeOrder} type="button" className="btn confirm" onClick={handleSubmit}>
            {t('labels.confirm')}
          </button>
          <button disabled={isCreatingExchangeOrder} type="button" className="btn cancel" onClick={handleCloseConfirmation}>
            {t('labels.cancel')}
          </button>
          {isCreatingExchangeOrder ? <FontAwesomeIcon className={styles.spinner} icon={faSpinner} size="2xl" /> : null}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmationModal;
