import React from 'react';
import './CustomToastBody.css';

function CustomToastBody({ closeToast, message }) {
  return (
    <div className="custom-toast-body">
      <span className="custom-toast-body-icon">⚠️</span>
      <span className="custom-toast-body-message">
        { message }
      </span>
      <button 
        onClick={closeToast} 
        className="custom-toast-body-close"
        type="button"
        aria-label="Close notification"
      >
        ×
      </button>
    </div>
  );
}

export default CustomToastBody;
