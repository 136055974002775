import { useEffect } from 'react';

export const useDebounce = (value = null, delay, dispatch) => {
  useEffect(() => {
    if (typeof value === 'string') {
      const handler = setTimeout(() => {
        dispatch(value);
      }, delay);
  
      return () => {
        clearTimeout(handler);
      };
    }
  }, [value]);
};
