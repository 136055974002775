import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { socket } from '../../web';
import { getCurrency } from '../../redux/currencies/currencyActions';
import { getUserLeverageOrders } from '../../redux/leverageOrder/leverageOrderActions';
import styles from './style.module.css';

let market = [];

export function Market({
  setPrimaryCoin, setSecondaryCoin, today, coinsToPrecisions, coins,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [marketChangeAPI, setMarketChangeAPI] = useState([]);
  const dispatch = useDispatch();
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const userId = useSelector((state) => state.user?.user?._id);
  
  useEffect(() => {
    if (userId && today) {
      socket.emit('getBinanceMarketChangeRequest', { coins, userId, today });
      socket.on(`getBinanceMarketChangeRequestResponse${userId}${today}`, (response) => {
        market.push(response);
        market = [...new Map(market.map((item) => [item.symbol, item])).values()];
        setMarketChangeAPI(market); // change only single row Response => { TRXUSDT, 703311925.20000000 , 3.071 }
      });
    }

    
    return () => {
      if (socket) socket.off(`getBinanceMarketChangeRequestResponse${userId}${today}`);
    };
  }, [userId, today, coins]);

  useEffect(() => {
    dispatch(getCurrency());
    if (userId) dispatch(getUserLeverageOrders(userId));
  }, [userId]);

  return (
    <div className="order-book-market-wrapper trade-sport-market" style={{ paddingLeft: '15px' }}>
      <h5 style={{ textTransform: 'uppercase' }}>{t('labels.market')}</h5>
      <div className="market-table-wrapper">
        <table className="trade-table trade-spot-table">
          <thead>
            <tr>
              <th>{t('labels.pair')}</th>
              <th>{t('labels.price')}</th>
              <th>{t('labels.change')}</th>
            </tr>
          </thead>

          <tbody style={{ position: 'relative', height: '300px' }}>
            {marketChangeAPI.length ? (
              marketChangeAPI.map((market) => (
                <tr
                  key={`market-${market.symbol}-${today}`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const a = market.symbol.substring(0, market.symbol.length - 4);
                    const b = market.symbol.substring(market.symbol.length - 4, market.symbol.length);
                    const prim = currencyData?.find((row) => row.symbol === a);
                    const sec = currencyData?.find((row) => row.symbol === b);
                    setPrimaryCoin(prim);
                    setSecondaryCoin(sec);
                    navigate(`/trade-spot/${market.symbol}`);
                  }}
                >
                  <td>{market.symbol}</td>
                  <td>{parseFloat(market.price).toFixed(coinsToPrecisions?.[market.symbol] || 2)}</td>
                  <td>
                    {parseFloat(market.change) < 0 ? (
                      <span className="text-red">
                        {parseFloat(market.change).toFixed(2)}
                        %
                      </span>
                    ) : (
                      <span className="text-green">
                        {parseFloat(market.change).toFixed(2)}
                        %
                      </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <FontAwesomeIcon
                className={styles.spinner}
                icon={faSpinner}
                size="2xl"
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
