import React, { useState, useMemo, useEffect } from 'react';
import '../components/TradeSpotStarbitrex/TradeSpotStarbitrex.css';
import 'rc-slider/assets/index.css';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import TradePriceWidget from '../components/TradePriceWidget';
import { Market } from '../components/TradeSpotStarbitrex/Market';
import BuySell from '../components/TradeSpotStarbitrex/BuySell';
import SpotTradeOrdersData from '../components/SpotTradePageData';
import { Order, TVChartContainer } from '../components';
import { socket } from '../web';
import useMediaQuery from '../hooks/useMediaQuery';
import { getCoinToPrecision } from '../redux/pairs/pairActions';
import useTradingStatusToast from '../hooks/useTradingStatusMessage';

function TradeSpotStarbitrexPage() {
  const [primaryCoin, setPrimaryCoin] = useState('ETH');
  const [secondaryCoin, setSecondaryCoin] = useState('USDT');
  const [updateRate, setUpdateRate] = useState(0);
  const [selectedRow] = useState('');
  const [settedDate, setDate] = useState(null);
  const [coins, setCoins] = useState([]);
  const [coinsToPrecisions, setCoinsToPrecisions] = useState({});
  const [isTradingEnabled, setIsTradingEnabled] = useState(true);
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.user?.user?._id);
  const coinToPrecision = useSelector((state) => state.pairTrade?.coinToPrecision);
  const account = useSelector((state) => state?.accounts?.account);

  const chartPair = useParams().coins;
  const isMobile = useMediaQuery(768);

  const today = useMemo(() => (settedDate || new Date().toISOString()), [chartPair, settedDate]);
  const Chart = useMemo(() => (chartPair ? <TVChartContainer pair={chartPair} market="spot" /> : null), [chartPair]);

  const unsubscribeFromPrevSocket = (prevPairName) => {
    socket.off(`getBinanceFutureTradesRequestResponse${prevPairName}${userId}${today}`);
    socket.off(`getCurrentMarketPriceResponse${prevPairName}${userId}${today}`);
    socket.off(`getBinanceMarketDepthRequest${prevPairName}${userId}${today}`);
    socket.off(`getTickerDataResponse${prevPairName}${userId}${today}`);
    socket.off(`getBinanceMarketChangeRequestResponse${userId}${today}`);
  };

  useEffect(() => {
    if (account?.isTradingEnabled !== undefined) {
      setIsTradingEnabled(account.isTradingEnabled);
    }
  }, [account.isTradingEnabled]);

  useTradingStatusToast(isTradingEnabled);

  useEffect(() => {
    dispatch(getCoinToPrecision('spot'));
  }, []);

  useEffect(() => {
    if (coinToPrecision) {
      setCoins(Object.keys(coinToPrecision));
      setCoinsToPrecisions(coinToPrecision);
    }
  }, [coinToPrecision]);

  useEffect(() => {
    if (userId) {
      socket.on(`tsListener${userId}`, () => {
        unsubscribeFromPrevSocket(chartPair);
        setDate(new Date().toISOString());
      });
    }

    return () => {
      socket.off(`tsListener${userId}`);
    };
  }, [userId]);

  return (
    <section style={{ width: '100%', minHeight: '100vh', paddingTop: '100px' }}>
      <TradePriceWidget
        coinsToPrecisions={coinsToPrecisions}
        primaryCoin={primaryCoin}
        secondaryCoin={secondaryCoin}
        prevPair={chartPair}
        setPrimaryCoin={(value) => setPrimaryCoin(value)}
        setSecondaryCoin={(value) => setSecondaryCoin(value)}
        unsubscribeFromPrevSocket={unsubscribeFromPrevSocket}
        pairs={coins}
        today={today}
        coinLink="trade-spot"
      />
      <div className="container-fluid">
        <div className="trade-main-order">
          <div className="one">
            {isMobile ? (
              <div className="active-orders-buy-sell-wrapper">
                <BuySell
                  today={today}
                  updateRate={updateRate}
                  selectedRow={selectedRow}
                  primaryCoin={primaryCoin}
                  secondaryCoin={secondaryCoin}
                  setPrimaryCoin={(d) => setPrimaryCoin(d)}
                  setSecondaryCoin={(d) => setSecondaryCoin(d)}
                  precisions={coinsToPrecisions}
                  coins={coins}
                  isTradingEnabled={isTradingEnabled}
                />
              </div>
            ) : (
              <Order
                coinsToPrecisions={coinsToPrecisions}
                today={today}
                selectRate={(rate) => setUpdateRate(rate)}
                primaryCoin={primaryCoin}
                secondaryCoin={secondaryCoin}
              />
            )}
          </div>

          {Chart}

          <div className="three">
            <Market
              coinsToPrecisions={coinsToPrecisions}
              coins={coins}
              today={today}
              setPrimaryCoin={setPrimaryCoin}
              setSecondaryCoin={setSecondaryCoin}
            />
          </div>
        </div>
      </div> 

      <div className="active-orders-buy-sell-wrapper">
        <SpotTradeOrdersData
          today={today}
          primaryCoin={primaryCoin}
          secondaryCoin={secondaryCoin}
          isTradingEnabled={isTradingEnabled}
        />
        {!isMobile ? (
          <BuySell
            today={today}
            updateRate={updateRate}
            selectedRow={selectedRow}
            primaryCoin={primaryCoin}
            secondaryCoin={secondaryCoin}
            setPrimaryCoin={(d) => setPrimaryCoin(d)}
            setSecondaryCoin={(d) => setSecondaryCoin(d)}
            precisions={coinsToPrecisions}
            coins={coins}
            isTradingEnabled={isTradingEnabled}
          />
        ) : (
          <Order
            coinsToPrecisions={coinsToPrecisions}
            today={today}
            selectRate={(rate) => setUpdateRate(rate)}
            primaryCoin={primaryCoin}
            secondaryCoin={secondaryCoin}
          />
        )}
      </div>
    </section>
  );
}

export default TradeSpotStarbitrexPage;
