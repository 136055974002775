export const SET_IS_DATA_READY = 'SET_IS_DATA_READY';
export const SET_CONVERSION_FROM_COIN = 'SET_CONVERSION_FROM_COIN';
export const SET_CONVERSION_TO_COIN = 'SET_CONVERSION_TO_COIN';
export const SET_CONVERSION_FROM_AMOUNT = 'SET_CONVERSION_FROM_AMOUNT';
export const SET_CONVERSION_TO_AMOUNT = 'SET_CONVERSION_TO_AMOUNT';
export const SET_CONVERSION_FROM_AMOUNT_ERR = 'SET_CONVERSION_FROM_AMOUNT_ERR';
export const SET_CONVERSION_TO_AMOUNT_ERR = 'SET_CONVERSION_TO_AMOUNT_ERR';
export const SET_USER_COIN_BALANCE = 'SET_USER_COIN_BALANCE';
export const SET_EXCHANGE_RATES_LOADING = 'SET_EXCHANGE_RATES_LOADING';
export const SET_EXCHANGE_RATES = 'SET_EXCHANGE_RATES';
export const SET_EXCHANGE_RATE = 'SET_EXCHANGE_RATE';
