import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';

export const columnsCardsDataTableScheme = (copyReferral, formatDate, deleteAction, type) => {
  const { t } = useTranslation();

  const getLabel = (type) => {
    switch (type) {
      case 'fiat':
        return t('labels.cardholder_name');
      case 'crypto':
        return t('labels.card_label');
      default:
        return t('labels.card_label');
    }
  };
  
  const columnsCards = [
    {
      name: getLabel(type),
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: t('labels.card_number'),
      selector: (row) => (
        <CopyToClipboard text={row?.card}>
          <span>
            {row?.card.slice(0, 4)}
            ...
            {row?.card.slice(row?.card.length || 0 - 4, row?.card.length)}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: t('labels.date'),
      selector: (row) => formatDate(new Date(row?.createdAt)),
      sortable: true,
    },
    {
      name: t('labels.action'),
      selector: (row) => (
        <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => deleteAction(row._id)}>{t('labels.delete')}</button>
      ),
    },
  ];
  return columnsCards;
};
