import { 
  GET_FOREX_ORDER, 
  GET_USER_FOREX_ORDER,
  ADD_FOREX_ORDER, 
  CLEAR_FOREX_ORDER, 
  FOREX_ORDER_ERROR, 
  STOP_FOREX_ORDER, 
  START_FOREX_ORDER, 
  FOREX_ORDER_PNL,
  FOREX_ORDER_BOOK,
} from './forexOrderTypes';
 
const initialState = {
  forexOrders: [],
  userForexOrders: [],
  forexOrder: [],
  forexError: false,
  forexStopped: false,
  forexStarted: false,
  forexOrderBook: [],
  forexOrderPnL: 0,
  forexOrderPnLFetched: false,
  forexOrdersFetched: false,
  userForexOrdersFetched: false,
  forexOrderFetched: false,
  forexOrderBookFetched: false,
  forexOrderAdded: false,
};
 
const LeverageOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FOREX_ORDER_PNL:
      return {
        ...state,
        forexOrderPnL: action.payload,
        forexOrderPnLFetched: true,
      };
    case GET_FOREX_ORDER:
      return {
        ...state,
        forexOrders: action.payload,
        forexOrdersFetched: true,
      };
    case GET_USER_FOREX_ORDER:
      return {
        ...state,
        userForexOrders: action.payload,
        userForexOrdersFetched: true,
      };
    case ADD_FOREX_ORDER:
      return {
        ...state,
        forexOrder: action.payload,
        forexOrderAdded: true,
      };
    case FOREX_ORDER_BOOK:
      return {
        ...state,
        forexOrderBook: action.payload,
        forexOrderBookFetched: true,
      };
    case CLEAR_FOREX_ORDER:
      return {
        ...state,
        forexStopped: false,
        forexStarted: false,
        forexError: false,
        forexOrderPnLFetched: false,
        forexOrdersFetched: false,
        userForexOrdersFetched: false,
        forexOrderFetched: false,
        forexOrderBookFetched: false,
        forexOrderAdded: false,
      };
    case STOP_FOREX_ORDER:
      return {
        ...state,
        forexStopped: true,
      };
    case START_FOREX_ORDER:
      return {
        ...state,
        forexStarted: true,
      };
    case FOREX_ORDER_ERROR:
      return {
        ...state,
        forexError: true,
      };
    default:
      return state;
  }
};
 
export default LeverageOrderReducer;
