/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

export function FrequentlyAskedQuestion() {
  const { t } = useTranslation();
  const { REACT_APP_BRANDNAME } = process.env;

  const faqValues = [
    {
      question: `${t('faq.first_question', { brand_name: REACT_APP_BRANDNAME })}`,
      answer: `${t('faq.first_answer', { brand_name: REACT_APP_BRANDNAME })}`,
    },
    {
      question: `${t('faq.second_question', { brand_name: REACT_APP_BRANDNAME })}`,
      answer: `${t('faq.second_answer', { brand_name: REACT_APP_BRANDNAME })}`,
    },
    {
      question: `${t('faq.third_question')}`,
      answer: `${t('faq.third_answer')}`,
    },
  ];

  const [activeTab, setActiveTab] = useState(null);

  return (
    <div className="faq">
      <div className="important-faqs-content">
        <p>FAQ</p>
        {faqValues.map((element, key = 1) => (
          <div
            style={{ marginTop: '10px' }}
            key={key}
            onClick={() => (activeTab === key ? setActiveTab(null) : setActiveTab(key))}
          >
            <h6
              style={{ color: 'white', fontSize: '13px', cursor: 'pointer' }}
            >
              {key + 1}
              .
              {element.question}
              <FontAwesomeIcon
                icon={faCaretDown}
                color="white"
                style={{ marginLeft: '10px' }}
              />
            </h6>
            {activeTab === key ? <p>{element.answer}</p> : ''}
            {' '}
          </div>
        ))}
      </div>
    </div>
  );
}
