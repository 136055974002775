import {
  GET_STAKINGS,
  GET_STAKING,
  ADD_STAKING,
  EDIT_STAKING,
  DELETE_STAKING,
  TOGGLE_STAKING_STATE,
  ERROR_STAKING_STATE,
} from './stakingTypes';

const initialState = {
  stakings: [],
  staking: {},
  stakingsFetched: false,
  stakingAdded: false,
  stakingFetched: false,
  stakingEditted: false,
  stakingDeleted: false,
  stakingError: false,
};

const stakingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAKINGS:
      return {
        ...state,
        stakings: action.payload,
        stakingsFetched: true,
      };
    case GET_STAKING:
      return {
        ...state,
        staking: action.payload,
        stakingFetched: true,
      };
    case ADD_STAKING:
      return {
        ...state,
        stakings: [state.stakings, action.payload],
        stakingAdded: true,
      };
    case EDIT_STAKING:
      return {
        ...state,
        staking: action.payload,
        stakingEditted: true,
      };
    case DELETE_STAKING:
      return {
        ...state,
        stakings: state.stakings.filter((item) => item._id !== action.payload),
        stakingDeleted: true,
      };
    case TOGGLE_STAKING_STATE:
      return {
        ...state,
        stakingsFetched: false,
        stakingAdded: false,
        stakingFetched: false,
        stakingEditted: false,
        stakingDeleted: false,
        stakingError: false,
      };
    case ERROR_STAKING_STATE:
      return {
        ...state,
        stakingError: true,
      };
    default:
      return state;
  }
};

export default stakingReducer;
