import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TradeInfo from '../components/Trade/TradeInfo';
import { socket } from '../web';

function TradePage() {
  const [settedDate, setDate] = useState(null);
  const userId = useSelector((state) => state.user?.user?._id);

  const { coins } = useParams();
  const today = useMemo(() => (settedDate || new Date().toISOString()), [coins, settedDate]);

  const unsubscribeFromPrevSocket = (prevPairName) => {
    socket.off(`getBinanceFutureTradesRequestResponse${prevPairName}${userId}${today}`);
    socket.off(`getCurrentMarketPriceResponse${prevPairName}${userId}${today}`);
    socket.off(`getBinanceMarketDepthRequest${prevPairName}${userId}${today}`);
    socket.off(`getTickerDataResponse${prevPairName}${userId}${today}`);
  };

  useEffect(() => {
    if (userId) {
      socket.on(`tsListener${userId}`, () => {
        unsubscribeFromPrevSocket(coins);
        setDate(new Date().toISOString());
      });
    }

    return () => {
      socket.off(`tsListener${userId}`);
    };
  }, [userId]);

  return (
    <TradeInfo symbol={coins} today={today} unsubscribeFromPrevSocket={unsubscribeFromPrevSocket} />
  );
}

export default TradePage;
