import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal, Row, Col, Nav, Tab,
} from 'react-bootstrap';
import { LoginUser, resendVerification, updateState } from '../redux/auth/authActions';
import logo from '../assets/images/STBRX-new-logo-01.png';
import RegisterStarbitrex from './RegisterStarbitrex';

function LoginStarbitrex() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state.auth?.user);
  const needPinCode = useSelector((state) => state.auth?.needPinCode);
  const qrCode = userData?.user?.[0]?.users.qrCode;
  const [errors, setErrors] = useState('');
  const [user, setUser] = useState({ email: '', password: '' });
  const [status, setStatus] = useState(0);
  const [view, setView] = useState(0);
  const [message, setMessage] = useState('');
  const [resend, setResend] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [tfa, setTfa] = useState('');
  const [path, setPath] = useState('');
  const [width, setWidth] = useState(-174);

  const pillsRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const element = pillsRef.current;

      if (element) {
        setWidth(-element.offsetWidth);
      }
    }, 100);
  }, [pillsRef.current]);
  
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const { pathname } = window.location;
    setPath(pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    if (needPinCode) {
      setShow(true);
      dispatch(updateState());
    }
  }, [needPinCode]);

  const findBrowser = () => {
    let currentBrowser = 'unknown';
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
      currentBrowser = 'Opera';
    } else if (navigator.userAgent.indexOf('Edg') !== -1) {
      currentBrowser = 'Edge';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      currentBrowser = 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      currentBrowser = 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      currentBrowser = 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
      // IF IE > 10
      currentBrowser = 'IE';
    }
    return currentBrowser;
  };

  const getOperatingSystem = () => {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) {
      operatingSystem = 'Windows OS';
    } else if (window.navigator.appVersion.indexOf('Mac') !== -1) {
      operatingSystem = 'MacOS';
    } else if (window.navigator.appVersion.indexOf('X11') !== -1) {
      operatingSystem = 'UNIX OS';
    } else if (window.navigator.appVersion.indexOf('Linux') !== -1) {
      operatingSystem = 'Linux OS';
    }

    return operatingSystem;
  };

  const handleSubmit = async () => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { email, password } = user;
    if (email === '') {
      setErrors(t('login.enter_email_msg'));
    } else if (!email.match(regexp)) {
      setErrors(t('login.invalid_email'));
    } else if (password === '') {
      setErrors(t('login.pass_required'));
    } else {
      setErrors('');
      const data = {
        email: user.email,
        password: user.password,
        pincode: tfa,
        one_time_password: tfa,
        hostname: window.location.host,
        currentBrowser: findBrowser(),
        currentOS: getOperatingSystem(),
      };
      dispatch(LoginUser(data));
    }
  };
  
  const navigateWithoutQueryParams = () => {
    const { pathname } = location;
    navigate(pathname);
  };


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('status')) {
      if (params.get('status') === '200') setStatus(1);
      else setStatus(2);
    }
    if (params.get('message')) {
      setMessage(params.get('message'));
    }
    if (params.get('resend')) {
      setResend(params.get('resend'));
    }
  }, []);

  useEffect(() => {
    if (message || resend) {
      navigateWithoutQueryParams();
    }
  }, [message, resend]);

  const resendEmail = () => {
    dispatch(resendVerification(resend));
  };

  const verifySecret = () => {
    const data = {
      email: user.email,
      code: tfa,
    };
    setTfa('');
    handleSubmit();
  };

  return (
    <>
      {status
        ? (
          <div className={`bar ${status === 1 ? 'bg-green' : 'bg-red'}`}>
            <span className="bar-content d-flex justify-content-center align-items-center">
              <p className="mb-0">
                {message}
                {'   '}
                {resend
                  ? (
                    <button type="button" onClick={() => resendEmail()} className="btn form-btn text-capitalize">
                      {t('login.resend_token')}
                    </button>
                  )
                  : ''}
              </p>
            </span>
          </div>
        )
        : ''}
      <section className="login-starbitrex register-starbitrex">
        <div className="login-section new-login-sec non-active">
          <div className="image-section" />
          <div className="login-data">
            <div>
              {path && (
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey={path === '/register' || path === '/register/' ? 'second' : 'first'}
                >
                  <Nav variant="pills" ref={pillsRef} className="flex-column" style={{ left: width }}>
                    <Nav.Item>
                      <Nav.Link eventKey="first" style={{ textTransform: 'uppercase' }}>{t('login.login')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second" style={{ textTransform: 'uppercase' }}>{t('login.register')}</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Row>
                    <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="abc-1">
                            <div className="logo">
                              <Link to="/">
                                <img alt="Starbitrex" className="img-fluid" src={logo} />
                              </Link>
                            </div>
                            <form>
                              <div className="password-input-field">
                                <p className="text-white-light">{t('login.enter_email')}</p>
                                <div>
                                  <input
                                    type="text"
                                    className="text-light remove-autofill-background"
                                    placeholder={`${t('login.type_email')}...`}
                                    name="email"
                                    value={user.email}
                                    onChange={(e) => handleChange(e)}
                                  />
                                </div>
                              </div>
                              <div className="password-input-field">
                                <p className="text-white-light">{t('login.enter_password')}</p>
                                <div>
                                  <span onClick={() => setView(!view)}>
                                    <FontAwesomeIcon
                                      className="faeye"
                                      icon={view ? faEyeSlash : faEye}
                                    />
                                  </span>
                                  <input
                                    type={view ? 'text' : 'password'}
                                    className="text-light remove-autofill-background"
                                    placeholder={`${t('login.type_password')}...`}
                                    name="password"
                                    value={user.password}
                                    onChange={(e) => handleChange(e)}
                                  />
                                </div>
                              </div>
                              {errors ? (
                                <div style={{ color: '#FE6E00' }} className="alert alert-danger">
                                  {errors}
                                </div>
                              ) : (
                                ''
                              )}
                              <div className="text-center">
                                <button
                                  type="button"
                                  className="btn enter-btn"
                                  onClick={handleSubmit}
                                  style={{ textTransform: 'uppercase' }}
                                >
                                  {t('login.login')}
                                </button>
                              </div>
                              <div className="my-4 text-center">
                                <Link to="/restore">{t('login.forgot_password')}</Link>
                              </div>
                            </form>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className="abc">
                            <div className="logo">
                              <Link to="/">
                                <img alt="Starbitrex" className="img-fluid" src={logo} />
                              </Link>
                            </div>
                            <form>
                              <RegisterStarbitrex />
                            </form>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              )}
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        className="withdraw-details two-factor-auth"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h3 className="text-white mb-3" style={{ textAlign: 'center' }}>{t('security.2f_authentication')}</h3>
          <input
            type="email"
            className="form-control mb-5"
            onChange={(e) => setTfa(e.target.value)}
            name="tfa"
            value={tfa}
            placeholder={t('security.enter_otp_code')}
          />
          <div className="limit-modal-btns">
            <button type="button" onClick={handleClose} className="btn cancel">
              {t('labels.cancel')}
            </button>
            <button type="button" onClick={verifySecret} className="btn confirm">
              {t('labels.confirm')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoginStarbitrex;
