import {
  SET_USER_VERIFY_STATUS,
  SET_VERIFICATION_PAGE_STEP,
  SET_IS_DATA_LOADING,
  SET_ID_CARD_FRONT_FILE,
  SET_ID_CARD_BACK_FILE,
  SET_CREDIT_CARD_FRONT_FILE,
  SET_CREDIT_CARD_BACK_FILE,
  SET_SELFIE_FILE,
  SET_ID_CARD_FRONT_DATA,
  SET_ID_CARD_BACK_DATA,
  SET_CREDIT_CARD_FRONT_DATA,
  SET_CREDIT_CARD_BACK_DATA,
  SET_SELFIE_DATA,
} from './kycVerificationActionTypes';

export const verifiedStatusColors = {
  verified: '#09C575',
  declined: 'red',
  pending: 'yellow',
};

export const verifiedStatusTitles = {
  verified: 'Verified',
  declined: 'Declined',
  pending: 'Pending',
};

export const VERIFIED_STATUS = 'verified';
export const DECLINED_STATUS = 'declined';

export const kycPages = {
  idCard: 'idCard',
  creditCard: 'creditCard',
  selfie: 'selfie',
  finalStep: 'finalStep',
};

const initialState = {
  userVerifyStatus: null,
  verificationPageStep: kycPages.idCard,
  isDataLoading: false,
  idCardFrontFile: null,
  idCardBackFile: null,
  creditCardFrontFile: null,
  creditCardBackFile: null,
  selfieFile: null,
  idCardFrontData: null,
  idCardBackData: null,
  creditCardFrontData: null,
  creditCardBackData: null,
  selfieData: null,
};

const kycVerificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_VERIFY_STATUS:
      return { ...state, userVerifyStatus: action.payload };
    case SET_VERIFICATION_PAGE_STEP:
      return { ...state, verificationPageStep: action.payload };
    case SET_IS_DATA_LOADING:
      return { ...state, isDataLoading: action.payload };
    case SET_ID_CARD_FRONT_FILE:
      return { ...state, idCardFrontFile: action.payload };
    case SET_ID_CARD_BACK_FILE:
      return { ...state, idCardBackFile: action.payload };
    case SET_CREDIT_CARD_FRONT_FILE:
      return { ...state, creditCardFrontFile: action.payload };
    case SET_CREDIT_CARD_BACK_FILE:
      return { ...state, creditCardBackFile: action.payload };
    case SET_SELFIE_FILE:
      return { ...state, selfieFile: action.payload };
    case SET_ID_CARD_FRONT_DATA:
      return { ...state, idCardFrontData: action.payload };
    case SET_ID_CARD_BACK_DATA:
      return { ...state, idCardBackData: action.payload };
    case SET_CREDIT_CARD_FRONT_DATA:
      return { ...state, creditCardFrontData: action.payload };
    case SET_CREDIT_CARD_BACK_DATA:
      return { ...state, creditCardBackData: action.payload };
    case SET_SELFIE_DATA:
      return { ...state, selfieData: action.payload };
    default:
      return state;
  }
};

export default kycVerificationReducer;
