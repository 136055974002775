import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faClock, faBan } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {
  selectCreditCardBackData,
  selectCreditCardBackFile, selectCreditCardFrontData, selectCreditCardFrontFile, 
  selectIdCardBackData, 
  selectIdCardBackFile, selectIdCardFrontData, selectIdCardFrontFile, selectIsDataLoading, selectSelfieData, selectSelfieFile, 
} from '../../../redux/kycVerification/kycVerificationSelectors';
import { addVerificationRequest, setVerificationPageStep } from '../../../redux/kycVerification/kycVerificationAction';
import { verifiedStatusColors, verifiedStatusTitles } from '../../../redux/kycVerification/kycVerificationReducer';
import s from './FinalStepPage.module.css';

function FinalStepPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const isDataLoading = useSelector(selectIsDataLoading);

  const idCardFrontFile = useSelector(selectIdCardFrontFile);
  const idCardBackFile = useSelector(selectIdCardBackFile);
  const creditCardFrontFile = useSelector(selectCreditCardFrontFile);
  const creditCardBackFile = useSelector(selectCreditCardBackFile);
  const selfieFile = useSelector(selectSelfieFile);


  const idCardFrontData = useSelector(selectIdCardFrontData);
  const idCardBackData = useSelector(selectIdCardBackData);
  const creditCardFrontData = useSelector(selectCreditCardFrontData);
  const creditCardBackData = useSelector(selectCreditCardBackData);
  const selfieData = useSelector(selectSelfieData);

  const idCardFrontTitle = 'A clear photo of your ID';
  const idCardBackTitle = 'A clear photo of your ID back';
  const creditCardFrontTitle = 'A clear photo of your credit cart front';
  const creditCardBackTitle = 'A clear photo of your credit cart back';
  const SelfieTitle = 'A clear photo of your selfie';

  const isProcessAvailable = (idCardFrontFile || idCardBackFile) || (creditCardFrontFile || creditCardBackFile) || selfieFile;
  const processBtnClassName = s.processBtn + (isProcessAvailable ? ` ${s.readyProcessBtn}` : '');

  const handleBackBtn = () => dispatch(setVerificationPageStep('selfie'));
  
  const handleProcessBtn = async () => {
    if (isProcessAvailable) {
      const formData = new FormData();

      if (idCardFrontFile) formData.append('idCardFront', idCardFrontFile, 'idCardFrontFile');
      if (idCardBackFile) formData.append('idCardBack', idCardBackFile, 'idCardBackFile');
      if (creditCardFrontFile) formData.append('creditCardFront', creditCardFrontFile, 'creditCardFrontFile');
      if (creditCardBackFile) formData.append('creditCardBack', creditCardBackFile, 'creditCardBackFile');
      if (selfieFile) formData.append('selfie', selfieFile, 'selfieFile');
      
      await dispatch(addVerificationRequest(formData));

      navigate('/portfolio');
    }
  };

  if (isDataLoading) {
    return (
      <>Loading</>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className={s.title}>Final Page:</div>
      <br />
      
      <div className={s.info}>
        <span>Id Card</span>
        <InfoItem file={idCardFrontFile} data={idCardFrontData} title={idCardFrontTitle} />
        <InfoItem file={idCardBackFile} data={idCardBackData} title={idCardBackTitle} />
        <br />
        <span>Credit Card</span>
        <InfoItem file={creditCardFrontFile} data={creditCardFrontData} title={creditCardFrontTitle} />
        <InfoItem file={creditCardBackFile} data={creditCardBackData} title={creditCardBackTitle} />
        <br />
        <span>Selfie</span>
        <InfoItem file={selfieFile} data={selfieData} title={SelfieTitle} />
        <br />
      </div>

      <div className={s.buttonsWrapper}>
        <div className={s.backBtn} onClick={handleBackBtn}>Back</div>
        <div className={processBtnClassName} onClick={handleProcessBtn}>Process</div>
      </div>
    </div>
  );
}

function InfoItem({ file, data, title }) {
  const color = data ? verifiedStatusColors[data?.verifiedStatus] : (file ? '#09C575' : 'white');
  const status = data ? verifiedStatusTitles[data?.verifiedStatus] : (file ? 'Selected' : 'Skipped');

  const isVerified = status === verifiedStatusTitles.verified;
  const isPending = status === verifiedStatusTitles.pending;
  const isDeclined = status === verifiedStatusTitles.declined;

  return (
    <div className={s.infoItem}>
      <div style={{ color }}>
        {`${title}: ${status}`}
        {isVerified && <FontAwesomeIcon icon={faCircleCheck} className="ms-2 pointer" />}
        {isPending && <FontAwesomeIcon icon={faClock} className="ms-2 pointer" />}
        {isDeclined && <FontAwesomeIcon icon={faBan} className="ms-2 pointer" />}
      </div>
    </div>
  );
};

export default FinalStepPage;
