export const EDIT_USER = 'EDIT_USER';
export const SET_USER = 'SET_USER';
export const CHANGE_PASS = 'CHANGE_PASS';
export const GET_USER = 'GET_USER';
export const CHANGE_2FA = 'CHANGE_2FA';
export const CLEAR_2FA = 'CLEAR_2FA';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_USER_ANALYTICS = 'GET_USER_ANALYTICS';
export const UPDATE_USER_FIELD = 'UPDATE_USER_FIELD';
export const UPDATE_USER_FIELD_FROM_SOCKET = 'UPDATE_USER_FIELD_FROM_SOCKET';
