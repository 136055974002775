import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetCoinImg } from '../../../helpers/getCoinImg';
import EXC from '../../../assets/images/swap-icon.png';
import {
  setConversionFromCoin, 
  setConversionToAmount, 
  setConversionToCoin, 
  setExchangeRate, 
  swapCurrenciesPositions, 
  updateExchangeRates, 
  updateUserBalance, 
} from '../../../redux/exchange/exchangeActions';
import {
  selectConversionFromAmount, 
  selectConversionFromAmountErr, 
  selectConversionFromCoin, 
  selectConversionToAmount, 
  selectConversionToAmountErr, 
  selectConversionToCoin, 
  selectExchangeRates, 
  selectIsExchangeRatesLoading, 
  selectUserCoinBalance, 
} from '../../../redux/exchange/exchangeSelectors';
import { toFixedAndTrim } from '../helper/helper';

function ExchangeBlock({ handleUserInput }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const token = localStorage.getItem('uToken');

  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);

  const conversionFromCoin = useSelector(selectConversionFromCoin);
  const conversionToCoin = useSelector(selectConversionToCoin);
  const conversionFromAmount = useSelector(selectConversionFromAmount);
  const conversionToAmount = useSelector(selectConversionToAmount);
  const conversionFromAmountErr = useSelector(selectConversionFromAmountErr);
  const conversionToAmountErr = useSelector(selectConversionToAmountErr);
  const userCoinBalance = useSelector(selectUserCoinBalance);
  
  const isExchangeRatesLoading = useSelector(selectIsExchangeRatesLoading);
  const exchangeRates = useSelector(selectExchangeRates);

  const conversionFromSymbol = conversionFromCoin?.symbol;
  const fromDecimalPrecision = conversionFromCoin?.decimalPrecision;
  const conversionToSymbol = conversionToCoin?.symbol;

  const formattedUserBalance = token ? toFixedAndTrim(userCoinBalance, fromDecimalPrecision) : '--';

  const [searchFromCoinValue, setSearchFromCoinValue] = useState('');
  const [searchToCoinValue, setSearchToCoinValue] = useState('');

  const searchInputFromRef = useRef();
  const searchInputToRef = useRef();

  if (!currencyData || currencyData.length === 0) {
    return <>error</>;
  }

  const filteredFromCurrencyData = currencyData
    .filter((coin) => coin.symbol !== conversionToSymbol)
    .filter((coin) => {
      if (searchFromCoinValue.length === 0) return true;

      const searchTerm = searchFromCoinValue.toLowerCase();

      const nameFilter = coin.name.toLowerCase().includes(searchTerm);
      const symbolFilter = coin.symbol.toLowerCase().includes(searchTerm);

      return nameFilter || symbolFilter;
    });

  const filteredToCurrencyData = currencyData
    .filter((coin) => coin.symbol !== conversionFromSymbol)
    .filter((coin) => {
      if (searchToCoinValue.length === 0) return true;

      const searchTerm = searchToCoinValue.toLowerCase();

      const nameFilter = coin.name.toLowerCase().includes(searchTerm);
      const symbolFilter = coin.symbol.toLowerCase().includes(searchTerm);

      return nameFilter || symbolFilter;
    });

  const handleExchangeFromCoinDropDownClick = (currency) => {
    dispatch(updateUserBalance(currency));
    dispatch(updateExchangeRates(currency));
    dispatch(setConversionFromCoin(currency));
    setSearchFromCoinValue('');
    handleUserInput(0);
  };

  const handleExchangeToCoinDropDownClick = (currency) => {
    const a = currency?.symbol;
    dispatch(setConversionToCoin(currency));
    dispatch(setConversionToAmount(parseFloat(conversionFromAmount) * exchangeRates[a]));
    dispatch(setExchangeRate(exchangeRates[a]));
    setSearchToCoinValue('');
  };

  return (
    <div className="crypto-fields-dd">

      <div className="crypto-have">
        <div className="fields-wrapper">
          <div 
            className="dropdown crypto-have-dropdown"
            onClick={() => searchInputFromRef.current.focus()}
          >
            <p className="label-text label-1">
              {`${t('labels.max')}: ${formattedUserBalance}`}
            </p>
            <a
              className="btn dropdown-toggle text-white"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="dd-coin-img">
                <img src={GetCoinImg(conversionFromSymbol)} alt="" className="img-fluid" />
              </div>
              <div className="coin-name-wrapper">
                <div className="dd-coin-name">
                  {conversionFromSymbol}
                </div>
              </div>
            </a>

            <ul
              className="dropdown-menu dropdown-scrollable"
              aria-labelledby="dropdownMenuLink"
              style={{ maxHeight: '400px' }}
            >
              <li>
                <input
                  style={{
                    width: '200px',
                    height: '38px',
                    margin: '10px',
                    border: '1px solid white',
                    borderRadius: '10px',
                    fontSize: '14px',
                  }}
                  ref={searchInputFromRef}
                  type="text"
                  placeholder="Search..."
                  value={searchFromCoinValue}
                  onChange={({ target }) => setSearchFromCoinValue(target.value)}
                />
              </li>
              {filteredFromCurrencyData.map((currency) => (
                <li key={`currency-${currency?._id}`}>
                  <a
                    className="dropdown-item"
                    onClick={() => handleExchangeFromCoinDropDownClick(currency)}
                  >
                    <img
                      alt=""
                      src={GetCoinImg(currency?.symbol)}
                      className="img-fluid coin-img pe-1"
                    />
                    <span className="m-1 fw-bold">{currency.symbol}</span>
                    <span className="fw-light text-gray" style={{ fontSize: '14px' }}>{currency.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-100">
            <p className="label-text label-1 text-start">{t('labels.you_pay')}</p>
            <div className="input-wrapper">
              <input
                type="number"
                placeholder={`${conversionFromCoin?.minAmount}-${conversionFromCoin?.maxAmount}`}
                value={conversionFromAmount}
                disabled={isExchangeRatesLoading}
                onChange={(e) => handleUserInput(e.target.value)}
              />
            </div>
            <div>{conversionFromAmountErr ? <span className="errMsg">{conversionFromAmountErr}</span> : ''}</div>
          </div>
        </div>
      </div>

      <div className="text-center swap-img-wrap">
        <img
          src={EXC}
          alt="#"
          className="img-fluid"
          style={{ width: '30px' }}
          onClick={() => {
            if (conversionFromSymbol && conversionToSymbol && !isExchangeRatesLoading) {
              dispatch(swapCurrenciesPositions());
            }
          }}
        />
      </div>

      <div className="crypto-have" style={{ marginTop: '-10px' }}>
        <div className="fields-wrapper">
          <div 
            className="dropdown crypto-have-dropdown no-label-text"
            onClick={() => searchInputToRef.current.focus()}
          >
            <a
              className="btn dropdown-toggle text-white"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="dd-coin-img">
                <img
                  className="img-fluid"
                  src={GetCoinImg(conversionToSymbol)}
                  alt="#"
                />
              </div>
              <div className="coin-name-wrapper">
                <div className="dd-coin-name">
                  {conversionToSymbol}
                </div>
              </div>
            </a>

            <ul className="dropdown-menu dropdown-scrollable" aria-labelledby="dropdownMenuLink" style={{ maxHeight: '400px' }}>
              <li>
                <input
                  style={{
                    width: '200px',
                    height: '38px',
                    margin: '10px',
                    border: '1px solid white',
                    borderRadius: '10px',
                    fontSize: '14px',
                  }}
                  type="text"
                  placeholder="Search..."
                  ref={searchInputToRef}
                  value={searchToCoinValue}
                  onChange={({ target }) => setSearchToCoinValue(target.value)}
                />
              </li>
              {filteredToCurrencyData.map((currency) => (
                <li key={`currency-${currency?._id}`}>
                  <a
                    className="dropdown-item"
                    onClick={() => handleExchangeToCoinDropDownClick(currency)}
                  >
                    <img
                      alt=""
                      src={GetCoinImg(currency?.symbol)}
                      className="img-fluid coin-img pe-1"
                    />
                    <span className="m-1 fw-bold">{currency.symbol}</span>
                    <span className="fw-light text-gray" style={{ fontSize: '14px' }}>{currency.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-100">
            <p className="label-text label-1 text-start">{t('labels.you_receive')}</p>
            <input
              type="number"
              value={
                conversionFromAmountErr
                  ? ''
                  : toFixedAndTrim(
                    conversionToAmount,
                    conversionToCoin.decimalPrecision,
                  )
              }
              disabled
            />
            <div>{conversionToAmountErr ? <span className="errMsg">{conversionToAmountErr}</span> : ''}</div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default ExchangeBlock;
