import { 
  GET_STOCK_ORDER, 
  GET_USER_STOCK_ORDER,
  ADD_STOCK_ORDER, 
  CLEAR_STOCK_ORDER, 
  STOCK_ORDER_ERROR, 
  STOP_STOCK_ORDER, 
  START_STOCK_ORDER, 
  STOCK_ORDER_PNL,
  STOCK_ORDER_BOOK,
} from './stockOrderTypes';
 
const initialState = {
  stockOrders: [],
  userStockOrders: [],
  stockOrder: [],
  stockError: false,
  stockStopped: false,
  stockStarted: false,
  stockOrderBook: [],
  stockOrderPnL: 0,
  stockOrderPnLFetched: false,
  stockOrdersFetched: false,
  userStockOrdersFetched: false,
  stockOrderFetched: false,
  stockOrderBookFetched: false,
  stockOrderAdded: false,
};
 
const StockOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_ORDER_PNL:
      return {
        ...state,
        stockOrderPnL: action.payload,
        stockOrderPnLFetched: true,
      };
    case GET_STOCK_ORDER:
      return {
        ...state,
        stockOrders: action.payload,
        stockOrdersFetched: true,
      };
    case GET_USER_STOCK_ORDER:
      return {
        ...state,
        userStockOrders: action.payload,
        userStockOrdersFetched: true,
      };
    case ADD_STOCK_ORDER:
      return {
        ...state,
        stockOrder: action.payload,
        stockOrderAdded: true,
      };
    case STOCK_ORDER_BOOK:
      return {
        ...state,
        stockOrderBook: action.payload,
        stockOrderBookFetched: true,
      };
    case CLEAR_STOCK_ORDER:
      return {
        ...state,
        stockStopped: false,
        stockStarted: false,
        stockError: false,
        stockOrderPnLFetched: false,
        stockOrdersFetched: false,
        userStockOrdersFetched: false,
        stockOrderFetched: false,
        stockOrderBookFetched: false,
        stockOrderAdded: false,
      };
    case STOP_STOCK_ORDER:
      return {
        ...state,
        stockStopped: true,
      };
    case START_STOCK_ORDER:
      return {
        ...state,
        stockStarted: true,
      };
    case STOCK_ORDER_ERROR:
      return {
        ...state,
        stockError: true,
      };
    default:
      return state;
  }
};
 
export default StockOrderReducer;
