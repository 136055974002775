
require('dotenv').config();

export const ENV = {
  hasMultiLanguages: process.env.REACT_APP_HAS_MULTI_LANGUAGES === 'true',
  isDEXSystem: process.env.REACT_APP_IS_DEX_SYSTEM === 'true',
  serverUrl: process.env.REACT_APP_SERVER_URL,
  Authorization: `Bearer ${process.env.REACT_APP_AUTHORIZATION}`,
  x_access_token: JSON.parse(localStorage.getItem('userToken')),
  x_auth_token: process.env.REACT_APP_X_AUTH_TOKEN,
  adminPrivateKey: process.env.REACT_APP_ADMIN_PRIVATE_KEY,
  minTradeAmtInUSD: 10,
  profileCompletionCriteria: {
    phoneNumber: 15,
    email: 15,
    address: 15,
    profilePicture: 15,
    cardForDeposit: 15,
    bankOrCardForWithdrawal: 15,
    firstDeposit: 10,
  },
  saveItem(name, value) {
    localStorage.setItem(`${name}`, JSON.stringify(value));
  },
  removeItem(name) {
    localStorage.removeItem(name);
  },
  encryptUserData(data, token, id, isAdminLogged = false) {
    if (data && id) {
      localStorage.setItem('userInfo', JSON.stringify(data));
      localStorage.setItem('uId', JSON.stringify(id));
    }
    if (token) {
      localStorage.setItem('uToken', JSON.stringify(token));
    }

    if (isAdminLogged) {
      localStorage.setItem('isAdminLogged', JSON.stringify(isAdminLogged));
    }
    return true;
  },
  getUserKeys() {
    const userData = localStorage.getItem('userInfo');
    return userData;
  },
  getToken() {
    const userData = localStorage.getItem('uToken');
    if (userData) {
      return userData;
    }
    return {};
  },
  getHeaders() {
    const token = JSON.parse(localStorage.getItem('uToken'));
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
      headers['access-token'] = token;
    }
    return headers;
  },
  logout() {
    localStorage.removeItem('uToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('uId');
    window.location.href = '/login';
  },
};
