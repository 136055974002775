import React from 'react';
import s from './VerifyFileInput.module.css';

function VerifyFileInput({
  type,
  labelTitle,
  borderTitle,
  statusColor,
  onChange,
}) {
  const areaStyles = { border: `1px dashed ${statusColor}` };
  const labelStyles = { color: statusColor };

  return (
    <div className={s.uploadInputItem}>
      <div className={s.uploadInputArea} style={areaStyles}>
        <input 
          type="file"
          accept="image/*"
          id={`${type}-file`}
          className={s.creditCardInputBack}
          onChange={onChange}
        />
        <label htmlFor={`${type}-file`}>
          <img
            alt="#" 
            className={s.creditCardBackInputLabel} 
            src="https://cdn1.iconfinder.com/data/icons/hawcons/32/698977-icon-130-cloud-upload-512.png"
          />
        </label>
        <span style={labelStyles}>
          {labelTitle}
        </span>
      </div>
      <span>{borderTitle}</span>
    </div>
  );
};

export default VerifyFileInput;
