import { 
  GET_INDICES_ORDER, 
  GET_USER_INDICES_ORDER,
  ADD_INDICES_ORDER, 
  CLEAR_INDICES_ORDER, 
  INDICES_ORDER_ERROR, 
  STOP_INDICES_ORDER, 
  START_INDICES_ORDER, 
  INDICES_ORDER_PNL,
  INDICES_ORDER_BOOK,
} from './indicesOrderTypes';
 
const initialState = {
  indicesOrders: [],
  userIndicesOrders: [],
  indicesOrder: [],
  indicesError: false,
  indicesStopped: false,
  indicesStarted: false,
  indicesOrderBook: [],
  indicesOrderPnL: 0,
  indicesOrderPnLFetched: false,
  indicesOrdersFetched: false,
  userIndicesOrdersFetched: false,
  indicesOrderFetched: false,
  indicesOrderBookFetched: false,
  indicesOrderAdded: false,
};
 
const IndicesOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDICES_ORDER_PNL:
      return {
        ...state,
        indicesOrderPnL: action.payload,
        indicesOrderPnLFetched: true,
      };
    case GET_INDICES_ORDER:
      return {
        ...state,
        indicesOrders: action.payload,
        indicesOrdersFetched: true,
      };
    case GET_USER_INDICES_ORDER:
      return {
        ...state,
        userIndicesOrders: action.payload,
        userIndicesOrdersFetched: true,
      };
    case ADD_INDICES_ORDER:
      return {
        ...state,
        indicesOrder: action.payload,
        indicesOrderAdded: true,
      };
    case INDICES_ORDER_BOOK:
      return {
        ...state,
        indicesOrderBook: action.payload,
        indicesOrderBookFetched: true,
      };
    case CLEAR_INDICES_ORDER:
      return {
        ...state,
        indicesStopped: false,
        indicesStarted: false,
        indicesError: false,
        indicesOrderPnLFetched: false,
        indicesOrdersFetched: false,
        userIndicesOrdersFetched: false,
        indicesOrderFetched: false,
        indicesOrderBookFetched: false,
        indicesOrderAdded: false,
      };
    case STOP_INDICES_ORDER:
      return {
        ...state,
        indicesStopped: true,
      };
    case START_INDICES_ORDER:
      return {
        ...state,
        indicesStarted: true,
      };
    case INDICES_ORDER_ERROR:
      return {
        ...state,
        indicesError: true,
      };
    default:
      return state;
  }
};
 
export default IndicesOrderReducer;
