import { ADD_CARD, GET_CARDS, DELETE_CARD } from './creditCardTypes';

const creditCardReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_CARD:
      return [...state, action.payload];
    case GET_CARDS:
      return action.payload;
    case DELETE_CARD:
      return state.filter((card) => card._id !== action.payload._id);
    default:
      return state;
  }
};

export default creditCardReducer;
