import {
  GET_ORDER, ADD_ORDER, CLEAR_ORDER, SET_IS_LOADING, 
} from './internalOrderTypes';

const initialState = {
  orders: [],
  order: [],
  success: false,
  isLoading: false,
};

const InternalOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER:
      return {
        ...state,
        orders: action.payload,
      };
    case ADD_ORDER:
      return {
        ...state,
        order: action.payload,
        success: true,
      };
    case CLEAR_ORDER:
      return {
        ...state,
        orders: [],
        order: [],
        success: false,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default InternalOrderReducer;
