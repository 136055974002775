import { useSelector } from 'react-redux';

export const selectIsDataReady = (state) => state.exchange.isDataReady ?? false;
export const selectConversionFromCoin = (state) => state.exchange.conversionFromCoin ?? null;
export const selectConversionToCoin = (state) => state.exchange.conversionToCoin ?? null;
export const selectConversionFromAmount = (state) => state.exchange.conversionFromAmount ?? 0;
export const selectConversionToAmount = (state) => state.exchange.conversionToAmount ?? 0;
export const selectConversionFromAmountErr = (state) => state.exchange.conversionFromAmountErr ?? '';
export const selectConversionToAmountErr = (state) => state.exchange.conversionToAmountErr ?? '';
export const selectUserCoinBalance = (state) => state.exchange.userCoinBalance ?? 0;
export const selectIsExchangeRatesLoading = (state) => state.exchange.isExchangeRateLoading ?? false;
export const selectExchangeRates = (state) => state.exchange.exchangeRates ?? null;
export const selectExchangeRate = (state) => state.exchange.exchangeRate ?? 0;

export const selectIsAllConditionsValid = () => {
  const conversionFromCoin = useSelector(selectConversionFromCoin);
  const conversionToCoin = useSelector(selectConversionToCoin);
  const conversionFromAmountErr = useSelector(selectConversionFromAmountErr);
  const conversionToAmountErr = useSelector(selectConversionToAmountErr);
  const conversionFromAmount = useSelector(selectConversionFromAmount);
  const conversionToAmount = useSelector(selectConversionToAmount);

  const allConditionsValid = Boolean(
    !conversionFromAmountErr
    && !conversionToAmountErr
    && conversionFromCoin
    && conversionFromCoin.symbol
    && conversionToCoin
    && conversionToCoin.symbol
    && conversionFromAmount
    && conversionToAmount,
  );

  return allConditionsValid;
};
