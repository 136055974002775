import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage, readMessages } from '../../redux/chat/chatActions';
import { ChatHeader } from './ChatHeader';
import { ChatInput } from './ChatInput';
import { ChatContent } from './ChatContent';
import './style.css';

export function Chat({ hideChat }) {
  const userIdJSON = localStorage.getItem('uId');
  const userId = (userIdJSON && userIdJSON !== 'undefined') ? JSON.parse(userIdJSON) : '';
  
  const dispatch = useDispatch();
  const textareaRef = useRef(null);
  const chatBoxRef = useRef(null);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState();
  const chat = useSelector((state) => state.chat);

  const scrollDown = () => {
    chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
  };

  useEffect(() => {
    scrollDown();
  }, [chat]);

  const handleSendMessage = async () => {
    const formData = new FormData();

    if (fileInputRef.current.files.length) {
      const file = fileInputRef.current.files[0];
      formData.append('file', file, file.name);
    }

    if (textareaRef.current.value.trim().length) formData.append('content', textareaRef.current.value);

    if (formData.get('content') || formData.get('file')) {
      formData.append('sender', userId);
      textareaRef.current.value = '';
      fileInputRef.current.value = null;
      scrollDown();
      setFile(null);
      
      dispatch(sendMessage(chat._id, formData));
    }
  };

  const handleReadMessage = () => {
    if (chat._id) dispatch(readMessages({ chatId: chat._id, user: chat.crmUser, userId: chat.user }));
  };

  return (
    <div className="chat-container">
      <ChatHeader hideChat={hideChat} />
      <ChatContent chat={chat} userId={userId} chatBoxRef={chatBoxRef} />
      <ChatInput 
        textareaRef={textareaRef} 
        fileInputRef={fileInputRef} 
        file={file}
        onSend={handleSendMessage} 
        onRead={handleReadMessage}
        setFile={setFile}
      />
    </div>
  );
}
