import { fetchExchangeRate, nonNegativeAmount } from '../apiHelper';
import {
  SET_IS_DATA_READY,
  SET_CONVERSION_FROM_COIN,
  SET_CONVERSION_TO_COIN,
  SET_CONVERSION_FROM_AMOUNT,
  SET_CONVERSION_TO_AMOUNT,
  SET_CONVERSION_FROM_AMOUNT_ERR,
  SET_CONVERSION_TO_AMOUNT_ERR,
  SET_USER_COIN_BALANCE,
  SET_EXCHANGE_RATES_LOADING,
  SET_EXCHANGE_RATES,
  SET_EXCHANGE_RATE,
} from './exchangeTypes';

export const setIsDataReady = (data) => ({ type: SET_IS_DATA_READY, payload: data });
export const setConversionFromCoin = (data) => ({ type: SET_CONVERSION_FROM_COIN, payload: data });
export const setConversionToCoin = (data) => ({ type: SET_CONVERSION_TO_COIN, payload: data });
export const setConversionFromAmount = (data) => ({ type: SET_CONVERSION_FROM_AMOUNT, payload: data });
export const setConversionToAmount = (data) => ({ type: SET_CONVERSION_TO_AMOUNT, payload: data });
export const setConversionFromAmountErr = (data) => ({ type: SET_CONVERSION_FROM_AMOUNT_ERR, payload: data });
export const setConversionToAmountErr = (data) => ({ type: SET_CONVERSION_TO_AMOUNT_ERR, payload: data });
export const setUserCoinBalance = (data) => ({ type: SET_USER_COIN_BALANCE, payload: data });
export const setExchangeRatesLoading = (data) => ({ type: SET_EXCHANGE_RATES_LOADING, payload: data });
export const setExchangeRates = (data) => ({ type: SET_EXCHANGE_RATES, payload: data });
export const setExchangeRate = (data) => ({ type: SET_EXCHANGE_RATE, payload: data });


// THUNKS BELOW

export const updateUserBalance = (exchangeFromCoinData) => (dispatch, getState) => {
  const {
    accounts: { account: { amounts } },
  } = getState();

  const { _id } = exchangeFromCoinData;

  try {
    const availableMoney = nonNegativeAmount(amounts.find((row) => row.currencyId === _id)?.amount);
    dispatch(setUserCoinBalance(availableMoney));
  } catch (error) {
    console.log(error);
  }
};

export const updateExchangeRates = (exchangeFromCoinData) => async (dispatch, getState) => {
  dispatch(setExchangeRatesLoading(true));

  const {
    currency: { currencies: { allCurrencies } },
  } = getState();

  const { symbol } = exchangeFromCoinData;

  try {
    const coinsString = allCurrencies.map((currency) => currency.symbol).join(',');
    const response = await fetchExchangeRate(symbol, coinsString);

    dispatch(setExchangeRates(response));
    dispatch(setExchangeRatesLoading(false));
  } catch (error) {
    console.log(error);
  }
};

export const swapCurrenciesPositions = () => async (dispatch, getState) => {
  const {
    exchange: { conversionFromCoin, conversionToCoin },
  } = getState();

  const tempCoin = conversionFromCoin;

  await dispatch(updateExchangeRates(conversionToCoin));
  dispatch(updateUserBalance(conversionToCoin));

  dispatch(setConversionFromCoin(conversionToCoin));
  dispatch(setConversionToCoin(tempCoin));

  dispatch(setConversionFromAmount(0));
  dispatch(setConversionToAmount(0));
  dispatch(setExchangeRate(0));
};
