let navigator;

export const setNavigator = (nav) => {
  navigator = nav;
};

export const logout = () => {
  localStorage.removeItem('uToken');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('uId');

  navigator('/login'); 
};
