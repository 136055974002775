import { GET_CHAT, READ_MESSAGES, SEND_MESSAGE } from './chatTypes';

const chatReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CHAT:
      return action.payload;
    case SEND_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case READ_MESSAGES:
      return {
        ...state,
        messages: state.messages.map((message) => {
          if (message.sender !== action.payload.userId) {
            return { ...message, isRead: true };
          }

          return message;
        }),
      };
    default:
      return state;
  }
};

export default chatReducer;
