import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import authReducer from './auth/authReducer';
import currencyReducer from './currencies/currencyReducer';
import fiatCurrencyReducer from './fiatCurrencies/fiatCurrencyReducer';
import walletReducer from './wallet/walletReducer';
import userReducer from './users/userReducer';
import roleReducer from './roles/roleReducer';
import NetworkReducer from './networks/networkReducer';
import externalWalletReducer from './addresses/externalWalletReducer';
import creditCardReducer from './cards/creditCardReducer';
import AccountReducer from './account/accountReducer';
import withdrawFeeReducer from './withdrawFee/withdrawFeeReducer';
import InternalOrderReducer from './internalOrder/internalOrderReducer';
import ExternalTransactionReducer from './externalTransactions/externalTransactionReducer';
import ExternalFiatTransactionReducer from './externalFiatTransactions/externalFiatTransactionReducer';
import transactionFeeReducer from './transactionFee/transactionFeeReducer';
import LeverageOrderReducer from './leverageOrder/leverageOrderReducer';
import SpotOrderReducer from './spotOrder/spotOrderReducer';
import marketReducer from './market/marketReducer';
import CronReducer from './cron/cronReducer';
import leverageReducer from './leverage/leverageReducer';
import bankAccountReducer from './bankAccounts/bankAccountReducer';
import ExternalBankTransactionReducer from './externalBankTransactions/externalBankTransactionReducer';
import transactionReducer from './transactions/transactionReducer';
import adminAddressReducer from './adminAddress/adminAddressReducer';
import notificationReducer from './notifications/notificationReducer';
import stakingReducer from './staking/stakingReducer';
import stakingInvestmentReducer from './stakingInvestment/stakingInvestmentReducer';
import forexCurrencyReducer from './forexCurrency/forexCurrencyReducer';
import forexOrdersReducer from './forexOrder/forexOrderReducer';
import stockSettingReducer from './stockSetting/stockSettingReducer';
import stockOrdersReducer from './stockOrder/stockOrderReducer';
import chatReducer from './chat/chatReducer';
import commoditiesSettingReducer from './commoditiesSetting/commoditiesSettingReducer';
import commoditiesOrdersReducer from './commoditiesOrder/commoditiesOrderReducer';
import cryptoSettingReducer from './cryptoSetting/cryptoSettingReducer';
import cryptoOrdersReducer from './cryptoOrder/cryptoOrderReducer';
import indicesSettingReducer from './indicesSetting/indicesSettingReducer';
import indicesOrdersReducer from './indicesOrder/indicesOrderReducer';
import metalsSettingReducer from './metalsSetting/metalsSettingReducer';
import metalsOrdersReducer from './metalsOrder/metalsOrderReducer';
import pairTradeReducer from './pairs/pairReducer';
import cfdReducer from './cfd/cfdReducer';
import currencyRatesReducer from './currencyRate/currencyRateReducer';
import othersPspReducer from './pspOthers/pspOthersReducer';
import languageReducer from './language/languageReducer';
import leverageSettingReducer from './leverageSetting/leverageSettingReducer';
import exchangeReducer from './exchange/exchangeReducer';
import { accountTypesReducer } from './accountType/accountTypeReducer';
import DepositReducer from './deposit/depositReducer';
import kycVerificationReducer from './kycVerification/kycVerificationReducer';
import systemSettingsReducer from './systemSettings/systemSettingsReducer';

const middleware = [thunk];
const reducer = combineReducers({
  auth: authReducer,
  currency: currencyReducer,
  fiatCurrency: fiatCurrencyReducer,
  networks: NetworkReducer,
  wallet: walletReducer,
  user: userReducer,
  role: roleReducer,
  externalWallets: externalWalletReducer,
  creditCards: creditCardReducer,
  bankAccounts: bankAccountReducer,
  accounts: AccountReducer,
  withdrawFee: withdrawFeeReducer,
  internalOrders: InternalOrderReducer,
  externalTransactions: ExternalTransactionReducer,
  externalFiatTransactions: ExternalFiatTransactionReducer,
  externalBankTransactions: ExternalBankTransactionReducer,
  transactionFee: transactionFeeReducer,
  LeverageOrders: LeverageOrderReducer,
  market: marketReducer,
  spotOrder: SpotOrderReducer,
  portfolioCurrencies: CronReducer,
  leverages: leverageReducer,
  adminTransactions: transactionReducer,
  adminAddress: adminAddressReducer,
  notification: notificationReducer,
  staking: stakingReducer,
  stakingInvestment: stakingInvestmentReducer,
  forexCurrency: forexCurrencyReducer,
  forexOrders: forexOrdersReducer,
  stockSetting: stockSettingReducer,
  stockOrders: stockOrdersReducer,
  chat: chatReducer,
  commoditiesSetting: commoditiesSettingReducer,
  commoditiesOrders: commoditiesOrdersReducer,
  cryptoSetting: cryptoSettingReducer,
  cryptoOrders: cryptoOrdersReducer,
  indicesSetting: indicesSettingReducer,
  indicesOrders: indicesOrdersReducer,
  metalsSetting: metalsSettingReducer,
  metalsOrders: metalsOrdersReducer,
  pairTrade: pairTradeReducer,
  cfdCurrencies: cfdReducer,
  currencyRates: currencyRatesReducer,
  othersPsp: othersPspReducer,
  languages: languageReducer,
  leverageSettings: leverageSettingReducer,
  exchange: exchangeReducer,
  depositTypes: DepositReducer,
  accountTypesReducer,
  kycVerification: kycVerificationReducer,
  systemSettingsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;
