import {
  GET_PAIRS, GET_PAIR, GET_TRADES, GET_COIN_TO_PRECISION, 
} from './pairTypes';

const initialState = {
  pairs: [],
  tradeTypes: [],
  coinToPrecision: [],
  pair: {},
};

const pairReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAIRS:
      return {
        ...state,
        pairs: action.payload,
      };
    case GET_PAIR:
      return {
        ...state,
        pair: action.payload,
      };
    case GET_COIN_TO_PRECISION:
      return {
        ...state,
        coinToPrecision: action.payload,
      };
    case GET_TRADES:
      return {
        ...state,
        tradeTypes: action.payload,
      };
    default:
      return state;
  }
};

export default pairReducer;
