import {
  SET_IS_DATA_READY,
  SET_CONVERSION_FROM_COIN,
  SET_CONVERSION_TO_COIN,
  SET_CONVERSION_FROM_AMOUNT,
  SET_CONVERSION_TO_AMOUNT,
  SET_CONVERSION_FROM_AMOUNT_ERR,
  SET_CONVERSION_TO_AMOUNT_ERR,
  SET_USER_COIN_BALANCE,
  SET_EXCHANGE_RATES_LOADING,
  SET_EXCHANGE_RATES,
  SET_EXCHANGE_RATE,
} from './exchangeTypes';

const initialState = {
  isDataReady: false,

  // Two coins (currency)
  // The 'conversionFromCoin' represents the coin that will be exchanged for the 'conversionToCoin'
  conversionFromCoin: null,
  conversionToCoin: null,

  conversionFromAmount: 0,
  conversionToAmount: 0,

  conversionFromAmountErr: '',
  conversionToAmountErr: '',

  // conversionFromUserCoinBalance
  userCoinBalance: 0,

  isExchangeRateLoading: false,
  exchangeRates: null,
  exchangeRate: 0,
};

const ExchangeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_DATA_READY:
      return {
        ...state,
        isDataReady: action.payload,
      };
    case SET_CONVERSION_FROM_COIN:
      return {
        ...state,
        conversionFromCoin: action.payload,
      };
    case SET_CONVERSION_TO_COIN:
      return {
        ...state,
        conversionToCoin: action.payload,
      };
    case SET_CONVERSION_FROM_AMOUNT:
      return {
        ...state,
        conversionFromAmount: action.payload,
      };
    case SET_CONVERSION_TO_AMOUNT:
      return {
        ...state,
        conversionToAmount: action.payload,
      };
    case SET_CONVERSION_FROM_AMOUNT_ERR:
      return {
        ...state,
        conversionFromAmountErr: action.payload,
      };
    case SET_CONVERSION_TO_AMOUNT_ERR:
      return {
        ...state,
        conversionToAmountErr: action.payload,
      };
    case SET_USER_COIN_BALANCE:
      return {
        ...state,
        userCoinBalance: action.payload,
      };
    case SET_EXCHANGE_RATES_LOADING:
      return {
        ...state,
        isExchangeRateLoading: action.payload,
      };
    case SET_EXCHANGE_RATES:
      return {
        ...state,
        exchangeRates: action.payload,
      };
    case SET_EXCHANGE_RATE:
      return {
        ...state,
        exchangeRate: action.payload,
      };
    default:
      return state;
  }
};

export default ExchangeReducer;
