import { toast } from 'react-toastify';
import {
  GET_ORDER, ADD_ORDER, CLEAR_ORDER, SET_IS_LOADING, 
} from './internalOrderTypes';
import { apiHelper } from '../apiHelper';
import { getAccount } from '../account/accountActions';

export const getInternalOrders = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/internalOrderHistory/${id}`, '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_ORDER,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const addInternalOrder = (data, userId) => async (dispatch) => {
  dispatch({ type: SET_IS_LOADING, payload: true });

  try {
    const res = await apiHelper('post', '/api/internalOrderHistory/add', data);

    if (res?.data) {
      const { data } = res;
      
      toast.success(data.message);
      dispatch({ type: ADD_ORDER, payload: data });

      await dispatch(getAccount(userId));
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const clearData = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_ORDER,
    });
  } catch (error) {
    toast.error(error.response.message);
  }
};
