import React from 'react';
import { toFixed } from '../helpers/utils';

export const limitOrdersColumnsSchema = (secondaryCoin, primaryCoin, rates, setOrderId, handleShow, t) => {
  const limitOrdersColumns = [
    {
      name: t('labels.pair'),
      selector: (row) => row?.spotPair,
      sortable: true,
    },
    {
      name: t('labels.type'),
      selector: (row) => (row?.marketOrder ? t('labels.market') : t('labels.limit')),
      sortable: true,
    },
    {
      name: t('labels.direction'),
      selector: (row) => (row?.tradeType ? t('labels.buy') : t('labels.sell')),
      sortable: true,
    },
    {
      name: t('labels.order_value'),
      selector: (row) => `${parseFloat(parseFloat(row?.investedQty) * parseFloat(row?.tradeStartPrice)).toFixed(2)
      } ${
        secondaryCoin.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.order_qty'),
      selector: (row) => `${parseFloat(row?.investedQty).toFixed(2)} ${primaryCoin.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.order_price'),
      selector: (row) => `${row?.tradeStartPrice} ${secondaryCoin.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.mark_price'),
      selector: (row) => {
        if (rates?.length) {
          const rate = rates.find((line) => line.symbol === row.spotPair);
          if (rate) {
            return parseFloat(rate.markPrice).toFixed(2);
          }
        }
        return `${0} ${secondaryCoin.symbol}`;
      },
      sortable: true,
    },
    {
      name: t('labels.unfilled_qty'),
      selector: (row) => `${parseFloat(row?.investedQty).toFixed(2)} ${primaryCoin.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.time'),
      selector: (row) => row?.createdAt.replace('T', ' ').replace('Z', ' '),
      sortable: true,
    },
    {
      name: t('labels.id'),
      selector: (row) => row?._id,
      sortable: true,
    },
    {
      name: t('labels.commission'),
      selector: ({ commission = 0, userInvestedAmount = 0 }) => toFixed(((userInvestedAmount * commission ?? 0) / 100), 2),
      sortable: true,
    },
    {
      name: 'Swap',
      cell: ({ endSwap }) => toFixed(endSwap, 2),
    },
    {
      name: t('labels.action'),
      selector: (row) => (
        <span>
          <button
            type="button"
            className="btn graph-table-btns ms-2"
            onClick={() => {
              setOrderId(row?._id);
              handleShow();
            }}
          >
            {t('labels.cancel')}
          </button>
        </span>
      ),
    },
  ];
  return limitOrdersColumns;
};

export const limitPastOrdersColumnsSchema = (secondaryCoin, primaryCoin, t) => {
  const limitPastOrdersColumns = [
    {
      name: t('labels.pair'),
      selector: (row) => row?.spotPair,
      sortable: true,
    },
    {
      name: t('labels.type'),
      selector: (row) => (row?.marketOrder ? t('labels.market') : t('labels.limit')),
      sortable: true,
    },
    {
      name: t('labels.direction'),
      selector: (row) => (row?.tradeType ? t('labels.buy') : t('labels.sell')),
      sortable: true,
    },
    {
      name: t('labels.order_value'),
      selector: (row) => `${parseFloat(parseFloat(row?.investedQty) * parseFloat(row?.tradeStartPrice)).toFixed(2)
      } ${
        secondaryCoin.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.commission'),
      selector: ({ endCommission }) => toFixed(endCommission ?? 0, 2),
    },
    {
      name: 'Swap',
      cell: ({ endSwap }) => toFixed(endSwap, 2),
    },
    {
      name: t('labels.filled_qty'),
      selector: (row) => (row?.status === 3
        ? `${0} ${primaryCoin.symbol}`
        : `${parseFloat(row?.investedQty).toFixed(2)} ${primaryCoin.symbol}`),
      sortable: true,
    },
    {
      name: t('labels.order_price'),
      selector: (row) => `${row?.tradeStartPrice} ${secondaryCoin.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.order_qty'),
      selector: (row) => `${parseFloat(row?.investedQty).toFixed(2)} ${primaryCoin.symbol}`,
      sortable: true,
    },
    {
      name: t('labels.status'),
      selector: (row) => (row?.status === 2 ? t('labels.completed') : t('labels.cancelled')),
      sortable: true,
    },
    {
      name: t('labels.time'),
      selector: (row) => row?.createdAt.replace('T', ' ').replace('Z', ' '),
      sortable: true,
    },
    {
      name: t('labels.id'),
      selector: (row) => row?._id,
      sortable: true,
    },
  ];
  return limitPastOrdersColumns;
};
