import { 
  GET_COMMODITIES_ORDER, 
  GET_USER_COMMODITIES_ORDER,
  ADD_COMMODITIES_ORDER, 
  CLEAR_COMMODITIES_ORDER, 
  COMMODITIES_ORDER_ERROR, 
  STOP_COMMODITIES_ORDER, 
  START_COMMODITIES_ORDER, 
  COMMODITIES_ORDER_PNL,
  COMMODITIES_ORDER_BOOK,
} from './commoditiesOrderTypes';
 
const initialState = {
  commoditiesOrders: [],
  userCommoditiesOrders: [],
  commoditiesOrder: [],
  commoditiesError: false,
  commoditiesStopped: false,
  commoditiesStarted: false,
  commoditiesOrderBook: [],
  commoditiesOrderPnL: 0,
  commoditiesOrderPnLFetched: false,
  commoditiesOrdersFetched: false,
  userCommoditiesOrdersFetched: false,
  commoditiesOrderFetched: false,
  commoditiesOrderBookFetched: false,
  commoditiesOrderAdded: false,
};
 
const CommoditiesOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMODITIES_ORDER_PNL:
      return {
        ...state,
        commoditiesOrderPnL: action.payload,
        commoditiesOrderPnLFetched: true,
      };
    case GET_COMMODITIES_ORDER:
      return {
        ...state,
        commoditiesOrders: action.payload,
        commoditiesOrdersFetched: true,
      };
    case GET_USER_COMMODITIES_ORDER:
      return {
        ...state,
        userCommoditiesOrders: action.payload,
        userCommoditiesOrdersFetched: true,
      };
    case ADD_COMMODITIES_ORDER:
      return {
        ...state,
        commoditiesOrder: action.payload,
        commoditiesOrderAdded: true,
      };
    case COMMODITIES_ORDER_BOOK:
      return {
        ...state,
        commoditiesOrderBook: action.payload,
        commoditiesOrderBookFetched: true,
      };
    case CLEAR_COMMODITIES_ORDER:
      return {
        ...state,
        commoditiesStopped: false,
        commoditiesStarted: false,
        commoditiesError: false,
        commoditiesOrderPnLFetched: false,
        commoditiesOrdersFetched: false,
        userCommoditiesOrdersFetched: false,
        commoditiesOrderFetched: false,
        commoditiesOrderBookFetched: false,
        commoditiesOrderAdded: false,
      };
    case STOP_COMMODITIES_ORDER:
      return {
        ...state,
        commoditiesStopped: true,
      };
    case START_COMMODITIES_ORDER:
      return {
        ...state,
        commoditiesStarted: true,
      };
    case COMMODITIES_ORDER_ERROR:
      return {
        ...state,
        commoditiesError: true,
      };
    default:
      return state;
  }
};
 
export default CommoditiesOrderReducer;
