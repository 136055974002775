import { toast } from 'react-toastify';
import { DISPLAY_LEVERAGE } from './leverageTypes';
import { apiHelper } from '../apiHelper';

export const getLeverageByCurrency = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/leverage/currency/${id}`, '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: DISPLAY_LEVERAGE,
        payload: data,
      });
    }
  } catch (error) {
    // console.log('error', error);
    toast.error(error.response.message);
  }
};
