import { useEffect, useState } from 'react';

const useMediaQuery = (minWidth) => {
  const [isWidthBelowMin, setIsWidthBelowMin] = useState(
    typeof window !== 'undefined' ? window.innerWidth < minWidth : false,
  );

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const resizeHandler = () => {
      setIsWidthBelowMin(window.innerWidth < minWidth);
    };

    window.addEventListener('resize', resizeHandler);
    
    return () => window.removeEventListener('resize', resizeHandler);
  }, [minWidth]);

  return isWidthBelowMin;
};

export default useMediaQuery;
