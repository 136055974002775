export const toFixed = (num, fixed) => {
  if (!num || typeof num === 'undefined' || Number.isNaN(Number(num))) return 0;
  
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  
  return Number(num.toString().match(re)[0]);
};

export const setDynamicPrecision = (amount) => {
  if (!amount || amount >= 1) return 2;

  const stringAmount = String(amount).replace('.', '');
  let precision = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= stringAmount.length; i++) {
    const currentNumber = Number(stringAmount[i]);

    if (currentNumber !== 0) break;

    precision += 1;
  }

  return precision + 1;
};
