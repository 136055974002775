import { toast } from 'react-toastify';
import {
  GET_PAIRS, GET_PAIR, GET_TRADES, GET_COIN_TO_PRECISION, 
} from './pairTypes';
import { apiHelper } from '../apiHelper';

export const getPairs = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/pair', '');
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_PAIRS,
        payload: res.data.pairs,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getCoinToPrecision = (typeName) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/pair/get-coin-to-precision?typeName=${typeName}&isEnabled=true`, '');
    if (res && res.data) {
      dispatch({
        type: GET_COIN_TO_PRECISION,
        payload: res.data.coinsToPrecisions,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getTrades = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/trade-type', '');
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_TRADES,
        payload: res.data.tradeTypes,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getPairData = (pairName) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/pair/get-single-pair-data/${pairName}`, '');
    if (res && res.data && res.data.pair) {
      dispatch({
        type: GET_PAIR,
        payload: res.data.pair,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
