import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import CustomToastBody from '../components/Trade/CustomToastBody/CustomTostBody';

const useTradingStatusToast = (isTradingEnabled) => {
  const { t } = useTranslation();

  useEffect(() => {
    const toastId = 'isTradingEnabled';

    if (!isTradingEnabled) {
      if (!toast.isActive(toastId)) {
        toast(<CustomToastBody message={t('messages.trading_disabled')} />, {
          toastId,
          position: 'bottom-left',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          className: 'custom-toast-body-container',
        });
      }
    }
  }, [isTradingEnabled, t]);
};

export default useTradingStatusToast;
