import React from 'react';
import { Accordion } from 'react-bootstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SC from '../assets/images/successfully-copied.svg';

export const CoinActivityDataTableSchema = (
  getCoinImg,
  selectedCurrency,
  copyTxtHash,
  displayMessage,
  t,
) => {
  const columns = [
    {
      selector: (row, index) => (
        <div key={index}>
          <Accordion defaultActiveKey={0}>
            <Accordion.Item className="transcations-accord-item mb-2" eventKey={index}>
              <Accordion.Header>
                <div className="transcations-accord-content">
                  <div className="content">
                    <div>
                      <img src={getCoinImg(row.symbol)} alt="" className="img-fluid" />
                    </div>
                    <div className="ms-2">
                      <h5 className="text-white">{row.coin}</h5>
                      <p className="m-0 text-white-light text-start">{row.symbol}</p>
                    </div>
                  </div>

                  <div className="text-white dd-icon d-flex align-items-center">
                    <h5 className="m-0" style={{ color: selectedCurrency?.color }}>
                      {row.sign}
                      {parseFloat(row.amount).toFixed(selectedCurrency.symbol === 'USDT' ? 2 : 4)}
                      {row.symbol}
                    </h5>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body className="p-0">
                {row.type === 1 ? (
                  <div className="accordion-content">
                    <p className=" mb-0" style={{ color: selectedCurrency?.color }}>
                      {row?.category}
                    </p>
                    <div className="accordion-content-activity">
                      <div className="activity">
                        <p className="text-white-light">{t('labels.date')}</p>
                        <p className="mb-0">{row?.date.replace('T', ' ').replace('Z', ' ')}</p>
                      </div>
                      {row?.info && (
                        <div className="activity">
                          <p className="text-white-light">{t('labels.info')}</p>
                          <p className="mb-0" style={{ color: selectedCurrency?.color }}>
                            {row?.info}
                          </p>
                        </div>
                      )}
                      <div className="activity">
                        <p className="text-white-light">{t('labels.transaction_id')}</p>
                        <p className="mb-0" style={{ color: selectedCurrency?.color }}>
                          {row?.txHash}
                          <CopyToClipboard text={row?.txHash}>
                            <FontAwesomeIcon
                              className="text-white ms-3"
                              icon={faCopy}
                              onClick={() => copyTxtHash()}
                            />
                          </CopyToClipboard>
                        </p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light">{t('labels.amount')}</p>
                        <p className="text-light mb-0">
                          {row.sign}

                          {parseFloat(row.amount).toFixed(
                            selectedCurrency.symbol === 'USDT' ? 2 : 4,
                          )}
                          {row.symbol}
                        </p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light">{t('labels.status')}</p>
                        <p
                          className="mb-0"
                          style={{
                            color: row?.resolveStatus === 2 ? '#dc3545' : selectedCurrency?.color,
                          }}
                        >
                          {row?.resolveStatus === 2 ? `${t('labels.declined')}` : `${t('labels.approved')}`}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="accordion-content">
                    <p className=" mb-0" style={{ color: selectedCurrency?.color }}>
                      {row?.category}
                    </p>
                    <div className="accordion-content-activity">
                      <div className="activity">
                        <p className="text-white-light">Date</p>
                        <p className="mb-0">{row?.date.replace('T', ' ').replace('Z', ' ')}</p>
                      </div>
                      {row?.info && (
                        <div className="activity">
                          <p className="text-white-light">Info</p>
                          <p className="mb-0" style={{ color: selectedCurrency?.color }}>
                            {row?.info}
                          </p>
                        </div>
                      )}
                      <div className="activity">
                        <p className="text-white-light">Amount</p>
                        <p className="text-light mb-0">
                          {row.sign}
                          {parseFloat(row.amount).toFixed(
                            selectedCurrency.symbol === 'USDT' ? 2 : 4,
                          )}
                          {row.symbol}
                        </p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light"> From </p>
                        <p className="text-light mb-0">
                          {row.sign === '-' ? row.coin : row.otherCoin}
                        </p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light"> To </p>
                        <p className="text-light mb-0">
                          {row.sign === '-' ? row.otherCoin : row.coin}
                        </p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light"> Conversion Rate </p>
                        <p className="text-light mb-0">{row.rate}</p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light"> Total </p>
                        <p className="text-light mb-0">
                          {row.otherCoin}
                          :
                          {row.sign === '-' ? '+' : '-'}
                          {row.otherAmount}
                        </p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light">Status</p>
                        <p
                          className="mb-0"
                          style={{
                            color: row?.resolveStatus === 2 ? '#dc3545' : selectedCurrency?.color,
                          }}
                        >
                          {row?.resolveStatus === 2 ? 'Declined' : 'Approved'}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {displayMessage ? (
                  <p className="successfully-copied">
                    <img src={SC} alt="" className="img-fluid" />
                    Successfully Copied!
                  </p>
                ) : (
                  ''
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ),
    },
  ];

  return columns;
};
