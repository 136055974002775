import BRONZE from '../assets/images/accountTypeImages/24x24/Icons status_Bronze.svg';
import GOLD from '../assets/images/accountTypeImages/24x24/Icons status_Gold.svg';
import PLATINUM from '../assets/images/accountTypeImages/24x24/Icons status_Platinum.svg';
import SILVER from '../assets/images/accountTypeImages/24x24/Icons status_Silver.svg';
import VIP from '../assets/images/accountTypeImages/24x24/Icons status_VIP.svg';

export const typeToImg = {
  bronze: BRONZE,
  gold: GOLD,
  platinum: PLATINUM,
  silver: SILVER,
  vip: VIP,
};

export const getAccountTypeIconByName = (name) => typeToImg[name];
