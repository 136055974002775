import React, { useEffect, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Table, ProgressBar } from 'react-bootstrap';
import { io } from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp, faEnvelope, faLocationDot, faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import currencyFormatter from 'currency-formatter';
import { PortfolioAccountCloumns } from '../DataTableSchemas/PortfolioStarbitrexDataTableSchema';
import { getUserAnalytics } from '../redux/users/userActions';
import { getCurrency } from '../redux/currencies/currencyActions';
import { getUserTotalBalance, updateAccount } from '../redux/account/accountActions';
import GetAccountData from '../components/GetAccountData';
// import { getPortfolioCurrencies } from '../redux/cron/cronActions';
import { GetCoinImg } from '../helpers/getCoinImg';
import WalletTransfer from '../components/WalletTransfer';
import Footer from '../components/Footer/Footer';
import userPlaceholder from '../assets/images/user-placeholder.jpg';
import verification from '../assets/images/verified.png';
import {
  getUserLeverageOrders,
} from '../redux/leverageOrder/leverageOrderActions';
import { getCurrencyRates } from '../redux/currencyRate/currencyRateActions';
import { nonNegativeAmount } from '../redux/apiHelper';
import { ENV } from '../config/config';
import { getAccountTypeIconByName } from '../helpers/getAccountTypeIconByName';
import { AccountTypeIcon } from '../components/AccountTypeIcon/AccountTypeIcon';
import KycVerificationBadge from '../components/KycVerificationBadge/KycVerificationBadge';

createTheme(
  'solarizedd',
  {
    text: {
      primary: '#fff',
      secondary: '#fff',
    },
    background: {
      default: '#0F1015',
    },
    context: {
      background: '#0F1015',
      text: '#FFFFFF',
    },
    divider: {
      default: '#fff',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  },
  'dark',
);

function Portfolio() {
  const { t } = useTranslation();
  const { profileCompletionCriteria } = ENV;
  const [coinData] = useState([]);
  const [isCurrenciesPriceRate, setIsCurrenciesPriceRate] = useState(false);
  const [, setLoader] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [previousAmountChange, setPreviousAmountChange] = useState(0);
  const [previousAmountPercentChange, setPreviousAmountPercentChange] = useState(0);
  const [portfolioAge, setPortfolioAge] = useState(0);
  const [bestAsset, setBestAsset] = useState(0);
  const [worstAsset, setWorstAsset] = useState(0);
  const [profileCompletePercent, setProfileCompletePercent] = useState(0);
  const [imagePath, setImagePath] = useState('');
  const [realizedPnL, setRealizedPnL] = useState({ value: 0, percentage: 0 });
  const [accountType, setAccountType] = useState({ name: 'main' });

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user?.user);
  const currentAccount = useSelector((state) => state.accounts?.account);
  const amounts = useSelector((state) => state.accounts?.account?.amounts);
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  // const reduxPnL = useSelector((state) => state.LeverageOrders?.orderPnL);
  const userOrders = useSelector((state) => state.LeverageOrders?.userOrders);
  const userId = useSelector((state) => state.user?.user?._id);
  const userAnalytics = useSelector((state) => state.user?.userAnalytics);
  const currencyRates = useSelector((state) => state.currencyRates);
  const totalBalance = useSelector((state) => state.accounts.totalBalance);

  // ---------------------------------------

  const [primaryCoin] = useState('ETH');
  const [secondaryCoin] = useState('USDT');
  const [pairName] = useState((primaryCoin + secondaryCoin));
  const [socket, setSocket] = useState(null);
  const [currentMarketPrice, setcurrentMarketPrice] = useState(0);
  const [startSocket, setStartSocket] = useState(true);

  const socketURL = process.env.REACT_APP_SOCKET_URL;

  useEffect(() => {
    if (currentAccount.accountType) {
      setAccountType(currentAccount.accountType);
    }
  }, [currentAccount]);

  useEffect(() => {
    if (userId) {
      dispatch(getUserLeverageOrders(userId));
      dispatch(getUserAnalytics(userId));
    }

    dispatch(getUserTotalBalance());
  }, [userId]);

  useEffect(() => {
    if (pairName) {
      if (socket != null) {
        setStartSocket(false);
        socket.disconnect();
        setSocket(io(socketURL, { transports: ['websocket'] }));
        setStartSocket(true);
        setcurrentMarketPrice(0);
      }
    }

    return () => { 
      if (socket) socket.disconnect();
    };
  }, [pairName]);

  useEffect(() => {
    if (pairName && startSocket && userId) {
      if (socket == null) {
        setSocket(io(socketURL, { transports: ['websocket'] }));
      }
      if (socket) {
        const today = 'today';

        socket.emit('getCurrentMarketPriceRequest', { pairName, userId, today });
        socket.on(`getCurrentMarketPriceResponse${pairName}${userId}${today}`, (currentMarketPriceData) => {
          setcurrentMarketPrice(currentMarketPriceData);
        });
        socket.emit('getBinanceMarketDepthRequest', { pairName, userId, today });
      }
    }
    return () => { 
      if (socket) socket.disconnect();
    };
  }, [socket, pairName, userId]);

  const getUnrealizedPnL = (row) => {
    let val = '';
    if (row) {
      const rate = parseFloat(currentMarketPrice ? currentMarketPrice?.find((line) => line.symbol === row.pairName).markPrice : 0);
      val = row?.tradeType === 1 // buy
        ? parseFloat(row?.qty) * (parseFloat(rate) - parseFloat(row?.tradeStartPrice))
        : parseFloat(row?.qty) * (parseFloat(row?.tradeStartPrice) - parseFloat(rate)); // sell
    }
    return val && !Number.isNaN(val) ? val : 0;
  };

  const getRealizedPnL = (completedOrders) => {
    const realizedPnLInit = completedOrders.reduce(
      (result, item) => {
        const tradeProfitOrLoss = parseFloat(item.tradeProfitOrLoss);
        const qty = parseFloat(item.qty);
        const tradeStartPrice = parseFloat(item.tradeStartPrice);

        result.value += tradeProfitOrLoss;
        result.percentage += (tradeProfitOrLoss / (qty * tradeStartPrice)) * 100;

        return result;
      },
      { value: 0, percentage: 0 },
    );

    if (!Number.isNaN(realizedPnLInit.value)) {
      realizedPnLInit.percentage = Math.abs(realizedPnLInit.percentage);
      setRealizedPnL(realizedPnLInit);
    }
  };

  useEffect(() => {
    const completedOrders = userOrders?.filter((row) => row.status === 2 || row.status === 3);
    if (completedOrders?.length) {
      getRealizedPnL(completedOrders);
    }
  }, [userOrders]);

  // ----------------------------------------

  useEffect(() => {
    if (currentUser?.createdAt && userAnalytics) {
      setPortfolioAge(moment().diff(moment(currentUser.createdAt), 'days'));
      let profPercent = 0;
      if (currentUser?.email) {
        profPercent += profileCompletionCriteria.email;
      }
      if (currentUser?.countryCode) {
        profPercent += profileCompletionCriteria.address;
      }
      if (currentUser?.phone) {
        profPercent += profileCompletionCriteria.phoneNumber;
      }
      if (userAnalytics?.bankOrCardWithdrawalAccount) {
        profPercent += profileCompletionCriteria.bankOrCardForWithdrawal;
      }
      if (userAnalytics?.firstDeposit) {
        profPercent += profileCompletionCriteria.firstDeposit;
      }
      if (currentUser?.profileImage) {
        profPercent += profileCompletionCriteria.profilePicture;
        setImagePath(`${process.env.REACT_APP_SERVER_URL}/images/${currentUser?.profileImage}`);
      }

      setProfileCompletePercent(profPercent);
    }
  }, [currentUser, userAnalytics]);


  async function calculateAssetValues(currencyData, amounts, resData) {
    const result = currencyData.reduce((acc, currency) => {
      const usdRate = parseFloat(1 / resData[currency.symbol === 'USDT' ? 'USD' : currency.symbol]) || 0;
      const amountsData = amounts?.find((row) => row.currencyId === currency._id) || {};
      const amount = parseFloat(amountsData.amount) || 0;
      const futuresAmount = parseFloat(amountsData.futures_amount) || 0;
      const forexAmount = parseFloat(amountsData.cfd_amount) || 0;
      const sum = usdRate * (amount + futuresAmount + forexAmount);

      if (!Number.isNaN(sum)) {
        acc.total += sum;
        acc.maxCurrency = Math.max(acc.maxCurrency, sum);
        acc.minCurrency = Math.min(acc.minCurrency, sum);
      }

      return acc;
    }, { total: 0, maxCurrency: 0, minCurrency: 0 });

    return { maxCurrency: result.maxCurrency, minCurrency: result.minCurrency, total: result.total.toFixed(4) };
  }

  async function fetchDataAndCalculate(currencyData, amounts, currencyRates) {
    const { maxCurrency, minCurrency, total } = await calculateAssetValues(currencyData, amounts, currencyRates);

    const sanitizedMaxCurrency = nonNegativeAmount(maxCurrency);
    const sanitizedMinCurrency = nonNegativeAmount(minCurrency);
    const sanitizedTotal = nonNegativeAmount(total);

    setBestAsset(sanitizedMaxCurrency);
    setWorstAsset(sanitizedMinCurrency);
    setTotalAmount(sanitizedTotal);

    if (currentAccount?._id) {
      const previousTotalAmount = currentAccount.previousTotalAmount || 0;
      const amountChangeDiff = sanitizedTotal - previousTotalAmount;
      const average = (sanitizedTotal + previousTotalAmount) / 2;
      const amountChangeDiffPercent = (amountChangeDiff / average) * 100;

      const data = { previousTotalAmount: sanitizedTotal };
      setPreviousAmountChange(amountChangeDiff);
      setPreviousAmountPercentChange(amountChangeDiffPercent);
      dispatch(updateAccount(currentAccount._id, data));
    }
  }

  useEffect(() => {
    if (!isCurrenciesPriceRate && amounts?.length && currencyData?.length && Object.keys(currencyRates).length) {
      setIsCurrenciesPriceRate(true);
      fetchDataAndCalculate(currencyData, amounts, currencyRates);
    }
  }, [amounts, currencyData, isCurrenciesPriceRate, currencyRates]);

  useEffect(() => {
    Promise.allSettled([
      dispatch(getCurrency()),
      dispatch(getCurrencyRates('USD')),
    ]);

    if (coinData) {
      setLoader(false);
    }
  }, []);

  const getRateInUsdt = (coinSymbol, amount) => {
    if (currencyRates && Object.keys(currencyRates).length) {
      const totalInUsdt = parseFloat(
        parseFloat((1 / currencyRates[coinSymbol === 'USDT' ? 'USD' : coinSymbol]))
        * parseFloat(amount),
      );

      if (!Number.isNaN(totalInUsdt)) {
        return totalInUsdt;
      }

      return null;
    }

    return '-';
  };

  const accountColumns = PortfolioAccountCloumns(
    currencyData,
    GetCoinImg,
    WalletTransfer,
    getRateInUsdt,
  );

  return (
    <>
      {currentUser?._id ? <GetAccountData /> : null}
      <section className="header-padding">
        <div className="portfolio-page">
          <div className="img-content">
            <div className="portfolio-price-box-container">
              <div className="portfolio-price-box d-flex justify-content-between">
                <div className="portfolio-data-section d-flex align-items-center">
                  {
                    !ENV.isDEXSystem && (
                      <div className="portfolio-img">
                        <div className="profile-img">
                          <img className="img-fluid" src={currentUser ? imagePath || userPlaceholder : ''} alt="" />
                        </div>
                        <div className="status {red} green" />
                      </div>
                    )
                  }
                  <div className="portfolio-data">
                    {
                      !ENV.isDEXSystem && (
                        <>
                          <div className="profile-name d-flex align-items-center">
                            <h3 className="me-3 mb-0">
                              {currentUser?.firstName}
                              {' '}
                              {currentUser?.lastName}
                            </h3>
                            <KycVerificationBadge userId={currentUser._id} />
                          </div>
                          <div className="more-data d-flex align-items-center">
                            <div className="account-type d-flex align-items-center margin-right">
                              {accountType.name !== 'main' && (
                                <div className="account-type-img me-2">
                                  <img className="img-fluid" src={getAccountTypeIconByName(accountType.name)} alt="" />
                                </div>
                              )}
                              <h5 className="account-type-text">{accountType.name.toUpperCase()}</h5>
                            </div>
                            {currentUser?.countryCode
                              ? (
                                <div className="address-area d-flex align-items-center margin-right">
                                  <span className="address-icon me-2"><FontAwesomeIcon icon={faLocationDot} /></span>
                                  <h5 className="address">{currentUser?.country?.nicename}</h5>
                                </div>
                              ) : null}
                            <div className="email d-flex align-items-center">
                              <span className="email-icon me-2"><FontAwesomeIcon icon={faEnvelope} /></span>
                              <h5 className="email-address" title={currentUser?.email}>{currentUser?.email}</h5>
                            </div>
                          </div>
                        </>
                      )
                    }

                    <div className="balance-section">
                      <div className="green-box">
                        <span className="total-tag">{t('portfolio.total_balance')}</span>
                        <span className="balance" style={{ color: totalAmount >= 0 ? '#3baf7c' : 'red' }}>
                          {totalBalance}
                        </span>
                        <div className="flow d-flex align-items-center">
                          <span className={`d-flex align-items-center me-3 ${(previousAmountPercentChange && parseFloat(previousAmountPercentChange) >= 0) ? 'green' : 'red'}`}>
                            <span className="raise me-2">
                              {(previousAmountPercentChange && parseFloat(previousAmountPercentChange) >= 0) ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                            </span>
                            <span className="raise">
                              {previousAmountPercentChange ? Math.abs(parseFloat(previousAmountPercentChange).toFixed(2)) : ''}
                              %
                            </span>
                          </span>
                          <span className="fixed-text">{t('portfolio.vs_previous_month')}</span>
                        </div>
                      </div>
                      <div className="green-box">
                        <span className="total-tag">PnL</span>
                        <span className="balance" style={{ color: realizedPnL.value >= 0 ? '#3baf7c' : 'red' }}>
                          {`${realizedPnL.value.toFixed(2)} USD`}
                        </span>
                        <div className="flow d-flex align-items-center">
                          <span className={`d-flex align-items-center me-3 ${realizedPnL.value >= 0 ? 'green' : 'red'}`}>
                            <span className="raise me-2">
                              {realizedPnL.value >= 0 ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                            </span>
                            <span className="raise">
                              {realizedPnL.percentage.toFixed(2)}
                              %
                            </span>
                          </span>
                          <span className="fixed-text">{t('portfolio.vs_previous_month')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="stats-section">
                  <div className="buttons-section">
                    <Link className="green-button btn me-2" to="/deposit/USDT">{t('labels.deposit')}</Link>
                  </div>
                  {
                    !ENV.isDEXSystem && (
                      <div className="progress-section">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h5 className="progress-label">{t('portfolio.profile_completion')}</h5>
                          <h5 className="progress-percent">
                            {profileCompletePercent}
                            %
                          </h5>
                        </div>
                        <div className="progress-line">
                          <ProgressBar variant="success" now={profileCompletePercent} />
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4" style={{ backgroundColor: '#0F1015' }}>
            <div className="portfolio-details">
              <div className="detail purple">
                <h5 className="mb-2 text-white">{t('portfolio.24h_change')}</h5>
                <h3>
                  {currencyFormatter.format(parseFloat(previousAmountChange).toFixed(2), { code: 'USD' })}
                  {' '}
                </h3>
              </div>
              <div className="detail green">
                <h5 className="mb-2 text-white">{t('portfolio.highest_balance')}</h5>
                <h3>
                  {currencyFormatter.format(parseFloat(totalAmount).toFixed(2), { code: 'USD' })}
                  {' '}
                </h3>
              </div>
              <div className="detail blue">
                <h5 className="mb-2 text-white">{t('portfolio.portfolio_age')}</h5>
                <h3>
                  {portfolioAge < 1 ? 0 : portfolioAge}
                  {portfolioAge <= 1 ? ` ${t('labels.day')}` : ` ${t('labels.days')}`}
                </h3>
              </div>
              <div className="detail dark">
                <h5 className="mb-2 text-white">{t('portfolio.best_24h_asset')}</h5>
                <h3>{currencyFormatter.format(parseFloat(bestAsset).toFixed(2), { code: 'USD' })}</h3>
              </div>
              <div className="detail dark">
                <h5 className="mb-2 text-white">{t('portfolio.worst_24h_asset')}</h5>
                <h3>{currencyFormatter.format(parseFloat(worstAsset).toFixed(2), { code: 'USD' })}</h3>
              </div>
            </div>
          </div>
          <div className="portfolio-datatable">
            <div className="top-heading d-flex align-items-center justify-content-between">
              <span className="cur-tag">{t('portfolio.my_currencies')}</span>
              <span className="cur-small-tag">24H</span>
            </div>
            {amounts && amounts.length ? (
              <DataTable
                columns={accountColumns}
                data={amounts?.sort((a, b) => b.amount - a.amount).filter((coin) => currencyData?.find((cur) => cur._id === coin.currencyId))}
                pagination
                fixedHeader
                persistTableHead
                theme="solarizedd"
              />
            ) : (
              <Table responsive className="">
                <thead>
                  <tr>
                    <th scope="col">Symbol</th>
                    <th scope="col">Coin</th>
                    <th scope="col">Spot Wallet Amount</th>
                    <th scope="col">Transfer Assets</th>
                    <th scope="col">Future Wallet Amount</th>
                  </tr>
                </thead>

              </Table>
            )}

          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Portfolio;
