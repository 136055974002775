import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IdCardModule from './IdCardModule/IdCardModule';
import CreditCardModule from './CreditCardModule/CreditCardModule';
import SelfieModule from './SelfieModule/SelfieModule';
import FinalStepPage from './FinalStepPage/FinalStepPage';
import { selectVerificationPageStep } from '../../redux/kycVerification/kycVerificationSelectors';
import { fetchUserVerificationData } from '../../redux/kycVerification/kycVerificationAction';
import s from './Verification.module.css';
import { kycPages } from '../../redux/kycVerification/kycVerificationReducer';

function Verification() {
  const dispatch = useDispatch();

  const verificationPageStep = useSelector(selectVerificationPageStep);
  const userId = useSelector((state) => state.user?.user?._id);

  const isIdCardStep = verificationPageStep === kycPages.idCard;
  const isCreditCardStep = verificationPageStep === kycPages.creditCard;
  const isSelfieStep = verificationPageStep === kycPages.selfie;
  const isFinalStep = verificationPageStep === kycPages.finalStep;

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserVerificationData(userId));
    }
  }, [userId]);

  return (
    <section className="user-setting header-padding">
      <div className={s.wrapper}>
        {isIdCardStep && <IdCardModule />}
        {isCreditCardStep && <CreditCardModule />}
        {isSelfieStep && <SelfieModule />}
        {isFinalStep && <FinalStepPage />}
      </div>
    </section>
  );
}

export default Verification;
