import React, { useState, useMemo } from 'react';
import { ChatContext } from './ChatContext';
import { Chat } from '../../components';

export function ChatProvider({ children }) {
  const [isShown, setChat] = useState(false);

  const showChat = () => {
    setChat(true);
  };

  const hideChat = () => {
    setChat(false);
  };

  const valueProvider = useMemo(() => ({
    showChat,
    hideChat,
  }), []);

  return (
    <ChatContext.Provider value={valueProvider}>
      { isShown && <Chat hideChat={hideChat} /> }
      { children }
    </ChatContext.Provider>
  );
}
