import React, { useEffect } from 'react';
import AOS from 'aos';
import './FullPageLoader.css';

function FullPageLoader() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="center-body">
      <span className="loader" />
    </div>
  );
}

export default FullPageLoader;
