import { GET_LANGUAGES } from './languageTypes';

// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_LANGUAGES:
      return action.payload;
    default:
      return state;
  }
};
