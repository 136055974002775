import React, { useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Container, Image, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser, faWallet, faGear, faSignOutAlt, faSignIn, faHistory, faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { SupportButton } from '../SupportButton';
import { LogoutUser } from '../../redux/auth/authActions';
import Logo from '../../assets/images/STBRX-new-logo-01.png';
import EXC from '../../assets/images/exchange-svg-icon.svg';
import TDI from '../../assets/images/trade-svg-icon.svg';
import { LanguageSelector } from '../LanguageSelector';
import { setNavigator } from '../../services/navigatorService';
import { ENV } from '../../config/config';

export function MobileHeader({
  path, showChat, hideChat, userData, 
}) {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem('uToken');

  useEffect(() => {
    setNavigator(history);
  }, [history]);

  const logOut = async (e) => {
    e.preventDefault();
    await dispatch(LogoutUser(localStorage.getItem('uId').trim()));
    localStorage.removeItem('uToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('uId');
    history('/login');
  };

  return (
    <div className="header header-js">
      <Navbar className="navbar navbar-expand-lg navbar-light header1 pb-0" expand="lg">
        <Container fluid>
          <Link className="m-0 navbar-brand" to="/">
            <Image src={Logo} fluid />
          </Link>

          {token && (
            <Navbar.Collapse className="header-navbar-collapse-icon" id="basic-navbar-nav">
              <nav className="navbar-nav">
            
                <NavLink className="nav-link header-transactions-navlink" to="/transactions">
                  <FontAwesomeIcon icon={faHistory} className="header-icon" />
                  <span className="navbar-tooltip bg-color">
                    {t('header.history')}
                  </span>
                  <span className="navbar-link-heading mobile-none2">{t('header.history')}</span>
                </NavLink>

                <div className="dropdown header-trade-dd-mobile navbar-nav nav-link mobile-none2">
                  <button
                    className="btn text-white dropdown-toggle p-0"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon={faGear} className="header-icon" />
                    <span
                      className="navbar-link-heading mobile-none2"
                      style={{ fontWeight: 700 }}
                    >
                      {t('header.setting')}
                    </span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <Link
                        to="/additional-security"
                        className={path.includes('/additional-security') ? 'active nav-link' : 'nav-link'}
                      >
                        {t('header.security')}
                      </Link>
                    </li>
                    {
                      !ENV.isDEXSystem && (
                        <li>
                          <Link
                            to="/profile-setting/"
                            className={path.includes('/profile-setting') ? 'active nav-link' : 'nav-link'}
                          >
                            {t('header.profile_setting')}
                          </Link>
                        </li>
                      )
                    }
                    <li>
                      <Link
                        to="/change-password/"
                        className={path.includes('/change-password') ? 'active nav-link' : 'nav-link'}
                      >
                        {t('header.change_password')}
                      </Link>
                    </li>
                  </ul>
                </div>

                <LanguageSelector isMobile />


                <NavLink className="nav-link" to="/logout" onClick={(e) => logOut(e)}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="header-icon" />
                  <span className="navbar-link-heading mobile-none2">{t('header.logout')}</span>
                </NavLink>

              </nav>
            </Navbar.Collapse>
          )}

          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <FontAwesomeIcon icon={faChevronDown} className="header-icon" />
          </Navbar.Toggle>

          {token ? (
            <nav
              className="header-navbar-collapse-icon navbar-nav header-right-icons"
              style={{ flexDirection: 'row' }}
            >
              { !!(userData.isChatEnabled || typeof userData.isChatEnabled === 'undefined') && (<SupportButton onShow={showChat} onHide={hideChat} />) }
              
              <NavLink className="nav-link header-portfolio-navlink" to="/portfolio">
                <FontAwesomeIcon icon={faUser} className="header-icon" />
                <span className="navbar-tooltip mobile-none">{t('header.portfolio')}</span>
              </NavLink>

              <NavLink className="nav-link header-exchange-navlink" to="/exchange/BTC">
                <img src={EXC} className="img-fluid" alt="" />
                <span className="navbar-tooltip mobile-none">{t('header.exchange')}</span>
              </NavLink>

              <Navbar className={path.includes('/trade') || path.includes('/trade-spot')
                ? 'nav-link dropdown-hover header-trade-dd active settings-btn2'
                : 'nav-link header-trade-dd dropdown-hover settings-btn2'}
              >
                <button type="button" className="dropbtn-hover p-0">
                  <img src={TDI} className="header-icon header-trade-icon" alt="" />
                </button>

                <div className="dropdown-content-hover">
                  <Link
                    to="/trade/ETHUSDT"
                    className={path.includes('/trade/') ? 'active nav-link' : 'nav-link'}
                  >
                    {t('header.futures_trading')}
                  </Link>
                  <Link
                    to="/trade-spot/ETHUSDT"
                    className={path.includes('/trade-spot/') ? 'active nav-link' : 'nav-link'}
                  >
                    {t('header.spot_trading')}
                  </Link>
                  {/* <Link
                    to="/trade-cfd/EURUSD"
                    className={path.includes('/trade-cfd/') ? 'active nav-link' : 'nav-link'}
                  >
                    CFD Trading
                  </Link> */}
                  <Link
                    to="/simple-earn-staking"
                    className={path.includes('/simple-earn-staking') ? 'active nav-link' : 'nav-link'}
                  >
                    {t('header.earn')}
                  </Link>
                </div>
              </Navbar>

              <Navbar className={path.includes('/activity') || path.includes('/fiat-activity')
                ? 'nav-link dropdown-hover header-trade-dd active settings-btn2'
                : 'nav-link header-trade-dd dropdown-hover settings-btn2'}
              >
                <button type="button" className="dropbtn-hover p-0">
                  <FontAwesomeIcon icon={faWallet} className="header-icon" />
                </button>

                <div className="dropdown-content-hover">
                  <Link
                    to="/activity/ETH"
                    className={path.includes('/activity/') ? 'active nav-link' : 'nav-link'}
                  >
                    {t('header.crypto')}
                  </Link>
                  <Link
                    to="/fiat-activity/USD"
                    className={path.includes('/fiat-activity/') ? 'active nav-link' : 'nav-link'}
                  >
                    {t('header.fiat')}
                  </Link>
                </div>
              </Navbar>
            </nav>
          ) : (
            <nav className="header-navbar-collapse-icon navbar-nav header-right-icons">
              <NavLink className="nav-link" to="/login">
                <FontAwesomeIcon icon={faSignIn} className="header-icon" />
                <span className="navbar-tooltip bg-color">{t('header.sign_in')}</span>
              </NavLink>
            </nav>
          )}

        </Container>
      </Navbar>
    </div>
  );
}
