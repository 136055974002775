import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import verification from '../../assets/images/verified.png';
import { fetchUserVerifyStatus } from '../../redux/kycVerification/kycVerificationAction';
import { selectUserVerifyStatus } from '../../redux/kycVerification/kycVerificationSelectors';
import { VERIFIED_STATUS } from '../../redux/kycVerification/kycVerificationReducer';
import s from './KycVerificationBadge.module.css';

function KycVerificationBadge({ userId }) {
  const dispatch = useDispatch();

  const userVerifyStatus = useSelector(selectUserVerifyStatus);
  const isVerified = userVerifyStatus === VERIFIED_STATUS;

  useEffect(() => {
    if (userId) dispatch(fetchUserVerifyStatus(userId));
  }, [userId]);

  return (
    <div className={s.wrapper}>
      {isVerified ? <VerifiedBadge /> : <NotVerifiedBadge />}
    </div>
  );
}

function VerifiedBadge() {
  const { t } = useTranslation();

  return (
    <div className={`${s.backgroundArea} ${s.verifiedArea}`}>
      <img className={s.verifiedIcon} src={verification} alt="" />
      {t('portfolio.verified_status')}
    </div>
  );
}

function NotVerifiedBadge() {
  const { t } = useTranslation();

  return (
    <>
      <div className={`${s.backgroundArea} ${s.notVerifiedArea}`}>
        <FontAwesomeIcon className={s.noyVerifiedIcon} icon={faCircleXmark} />
        {t('portfolio.not_verified_status')}
      </div>
      <NavLink className={s.verifyLink} to="/verification">
        {t('portfolio.verify_link')}
      </NavLink>
    </>
  );
}

export default KycVerificationBadge;
