import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ENV } from '../config/config';
import EN_TRANSLATION from './translations/en_translation.json';
import FR_TRANSLATION from './translations/fr_translation.json';
import RU_TRANSLATION from './translations/ru_translation.json';

const resources = {
  en: {
    translation: EN_TRANSLATION,
  },
};

if (ENV.hasMultiLanguages) {
  resources.fr = {
    translation: FR_TRANSLATION,
  };
  resources.ru = {
    translation: RU_TRANSLATION,
  };
}


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
