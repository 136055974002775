import { toast } from 'react-toastify';
import { GET_WALLET, CREATE_WALLET } from './RequestTypes';
import { apiHelper } from '../apiHelper';

export const getBTCUserWallet = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/wallet/btc-wallet/${userId}`);
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_WALLET,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const clearBTCUserWallet = () => async (dispatch) => {
  const data = { wallet: [], success: true, temporary: true };
  dispatch({
    type: GET_WALLET,
    payload: data,
  });
};

export const getUserWallet = (userId, currencyId, networkId) => async (dispatch) => {
  try {
    const res = await apiHelper('post', `/api/wallet/get-wallet/${userId}`, { currencyId, networkId });
    if (res?.data) {
      const { data } = res;
      // toast.success(res.data.message)
      dispatch({
        type: GET_WALLET,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const createUserWallet = (userId, networkId, currencyId) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/wallet/create-wallet', { network: networkId, user: userId, currencyId });
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: CREATE_WALLET,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
