import { toast } from 'react-toastify';
import {
  GET_STAKINGS,
  GET_STAKING,
  ADD_STAKING,
  EDIT_STAKING,
  DELETE_STAKING,
  TOGGLE_STAKING_STATE,
  ERROR_STAKING_STATE,
} from './stakingTypes';
import { apiHelper } from '../apiHelper';

export const getStakings = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/staking', '');
    if (res?.data && res?.data?.stakings) {
      const { data } = res;
      await dispatch({
        type: GET_STAKINGS,
        payload: data?.stakings,
      });
    } else {
      await dispatch({
        type: GET_STAKINGS,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getUserStakings = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/user-staking/union?userId=${userId}`, '');
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_STAKINGS,
        payload: res.data.stakings,
      });
    } 
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getStaking = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/staking/${id}`, '');
    if (res?.data && res?.data?.staking) {
      const { data } = res;
      await dispatch({
        type: GET_STAKING,
        payload: data?.staking,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addStaking = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/staking/add', data);

    if (res?.data && res?.data?.staking_) {
      const { data } = res;

      toast.success(data?.message, {
        autoClose: 1000,
      });

      await dispatch({
        type: ADD_STAKING,
        payload: data?.staking_,
      });
    } else {
      await dispatch({
        type: ERROR_STAKING_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_STAKING_STATE,
    });
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editStaking = (id, bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/staking/${id}`, bodyData);
    if (res?.data && res?.data?.staking) {
      const { data } = res;

      toast.success(data?.message, {
        autoClose: 1000,
      });

      await dispatch({
        type: EDIT_STAKING,
        payload: data?.staking,
      });
    } else {
      await dispatch({
        type: ERROR_STAKING_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_STAKING_STATE,
    });
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteStaking = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/staking/${id}`, '');
    if (res?.data && res?.data?.staking) {
      const { data } = res;

      toast.success(data?.message, {
        autoClose: 1000,
      });

      await dispatch({
        type: DELETE_STAKING,
        payload: data?.staking?._id,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateStakingState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_STAKING_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
