import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy, faCoins, faCreditCard, faPiggyBank, faMoneyBillAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCurrency } from '../../redux/currencies/currencyActions';
import { getNetwork } from '../../redux/networks/networkActions';
import { getUserWallet, getBTCUserWallet, clearBTCUserWallet } from '../../redux/wallet/walletActions';
import { getDefaultBankAccount } from '../../redux/bankAccounts/bankAccountActions';
import { getDefaultAdminAddressByCurrencyNetwork } from '../../redux/adminAddress/adminAddressActions';
import GetAccountData from '../GetAccountData';
import 'react-credit-cards/es/styles-compiled.css';
import { FrequentlyAskedQuestion } from '../FrequentlyAskedQuestion/FrequentlyAskedQuestion';
import CardDepositComponent from './components/CardDepositComponent';
import { socket } from '../../web';
import { getOthersPsp } from '../../redux/pspOthers/pspOthersActions';
import { getDepositTypes } from '../../redux/deposit/depositActions';

const defaultDepositType = 'COIN';
const tabNameToIcon = {
  BANK: faPiggyBank,
  CARD: faCreditCard,
  COIN: faCoins,
  OTHER: faMoneyBillAlt,
};

function Deposit() {
  const { t } = useTranslation();
  const { symbol } = useParams();
  const [defaultBankAccountData, setDefaultBankAccountData] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const [showWallet, setShowWallet] = useState(false);
  const [tabName, setTabName] = useState(defaultDepositType);

  const tabIcon = useMemo(() => tabNameToIcon[tabName ?? defaultDepositType], [tabName]);

  const dispatch = useDispatch();
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const networks = useSelector((state) => state.networks?.networks);
  const userId = useSelector((state) => state.user?.user?._id);
  const defaultBankAccount = useSelector((state) => state.bankAccounts?.defaultBankAccount);
  const userWallet = useSelector((state) => state.wallet.wallet);
  const isTemporaryWallet = useSelector((state) => state.wallet.temporary);
  const defaultadminAddress = useSelector(
    (state) => state?.adminAddress?.defaultAdminAddressNetwork,
  );
  const othersPsp = useSelector((state) => state.othersPsp);
  const depositTypes = useSelector((state) => state.depositTypes);

  const isBTC = useMemo(() => selectedNetwork?.symbol === 'BTC', [selectedNetwork]);

  const showDefaultWalletHandler = (network, fetchAddress = false) => {
    if (fetchAddress) {
      if (network?._id && selectedCurrency?._id) {
        dispatch(getDefaultAdminAddressByCurrencyNetwork(network?._id, selectedCurrency?._id));
      }
    } else {
      dispatch(getDefaultAdminAddressByCurrencyNetwork());
    }
  };

  const getWalletInfo = (currency, network) => {
    dispatch(getUserWallet(userId, currency._id, network._id));
  };

  useEffect(() => {
    if (isBTC && userWallet && isTemporaryWallet) {
      socket.on(`btcWalletListener/${userId}`, () => {
        setSelectedNetwork([]);
        dispatch(clearBTCUserWallet());
      });
    }

    return () => {
      socket.off(`btcWalletListener/${userId}`);
    };
  }, [isBTC, userWallet, userId, isTemporaryWallet]);

  useEffect(() => {
    if (isBTC && userId) {
      dispatch(getBTCUserWallet(userId));
    }
  }, [isBTC, userId]);

  useEffect(() => {
    if (Object.keys(defaultBankAccount)?.length > 0) {
      setDefaultBankAccountData(defaultBankAccount);
    }
  }, [defaultBankAccount]);


  useEffect(() => {
    if (depositTypes) {
      const found = depositTypes.find((typeObject) => typeObject.status);
      setTabName(found?.name ?? defaultDepositType);
    }
  }, [depositTypes]);

  useEffect(() => {
    Promise.allSettled([
      dispatch(getCurrency()),
      dispatch(getNetwork()),
      dispatch(getDefaultBankAccount()),
      dispatch(getOthersPsp()),
      dispatch(getDepositTypes()),
    ]);
  }, []);

  useEffect(() => {
    if (currencyData) {
      const found = currencyData?.find((currency) => currency.symbol === symbol);
      setSelectedCurrency(found);
    }
  }, [currencyData]);

  const copyReferral = () => {
    toast.success(`${t('labels.copied_successfully')}`);
  };

  return (
    <>
      <GetAccountData />
      <section className="deposit header-padding">
        <div className="container-fluid custom-box padding50">
          <div className="d-flex justify-content-center align-items-center flex-md-row flex-column">
            <div className="d-flex align-items-center mb-lg-0 mb-3">
              <i className="fa fa-angle-left me-lg-4 me-3 left-angle" />
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-md-8">
              <div className="deposit-col crypto-deposit-box">
                <div className="deposit-coin">
                  <div className="deposit-coin-header">
                    <h3 className="text-light">{t('labels.deposit')}</h3>
                    <div className="dropdown deposit-dropdown coin-deposit-dropdown dd-bk__gray">
                      <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButtonTab"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <div className="d-flex justify-content-between">
                          <p className="coin-name">
                            <FontAwesomeIcon icon={tabIcon} style={{ marginRight: '7px' }} />
                            {tabName}
                          </p>
                          <div className="coin-details d-flex align-items-center">
                            <p className="dd-arrow" />
                          </div>
                        </div>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButtonTab">
                        {depositTypes.map((typeObject) => (
                          <li
                            key={typeObject.name}
                            className={typeObject.status ? '' : 'disabled'}
                            onClick={() => setTabName(typeObject.name)}
                          >
                            <a className="dropdown-item">
                              <div className="d-items d-flex justify-content-between">
                                <p>{t(`labels.${typeObject.name.toLowerCase()}`)}</p>
                              </div>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {tabName === 'COIN' && (
                  <div className="dropdown deposit-dropdown coin-deposit-dropdown dd-bk__gray">
                    <button
                      className="btn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="d-flex justify-content-between">
                        <p className="coin-name">
                          {selectedCurrency && selectedCurrency.symbol
                            ? selectedCurrency.symbol
                            : '???'}
                        </p>
                        <div className="coin-details d-flex align-items-center">
                          <p className="detail">
                            (
                            {selectedCurrency && selectedCurrency.symbol
                              ? selectedCurrency.name
                              : `${t('labels.select_coin')}`}
                            )
                          </p>
                          <p className="dd-arrow" />
                        </div>
                      </div>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      {currencyData
                          && currencyData.length > 0
                          && currencyData
                            ?.filter((row) => row?.isFiat !== true)
                            ?.map((currency) => (
                              <li
                                key={currency._id}
                                onClick={() => {
                                  setSelectedCurrency(currency);
                                  setSelectedNetwork([]);
                                  setShowWallet(false);
                                  showDefaultWalletHandler(selectedNetwork, false);
                                }}
                              >
                                <a className="dropdown-item">
                                  <div className="d-items d-flex justify-content-between">
                                    <p>{currency.symbol}</p>
                                    <p>{currency.name}</p>
                                  </div>
                                </a>
                              </li>
                            ))}
                    </ul>
                  </div>
                  )}
                  {selectedCurrency && selectedCurrency.symbol ? (
                    <>
                      {tabName === 'COIN' && <span className="coin-deposit-label">{t('labels.network')}</span>}
                      { tabName === 'COIN' && networks?.length && networks.filter((network) => network.currencies.some((o) => o._id === selectedCurrency._id)).length && (
                      <div className="dropdown deposit-dropdown coin-deposit-dropdown dd-bk__gray" style={{ marginBottom: '10px' }}>
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex justify-content-between">
                            <p className="coin-name">
                              {selectedNetwork && selectedNetwork.symbol
                                ? selectedCurrency.symbol
                                : 'Select Network'}
                            </p>
                            <div className="coin-details d-flex align-items-center">
                              <p className="dd-arrow" />
                            </div>
                          </div>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                          {networks.filter((network) => network.currencies.some((o) => o._id === selectedCurrency._id))
                            .map((network) => (
                              <li
                                key={network._id}
                                onClick={() => {
                                  getWalletInfo(selectedCurrency, network);
                                  setSelectedNetwork(network);
                                  setShowWallet(true);
                                  showDefaultWalletHandler(network, true);
                                }}
                              >
                                <a className="dropdown-item">
                                  <div className="d-items d-flex justify-content-between">
                                    <p>{network.symbol}</p>
                                    <p>{network.name}</p>
                                  </div>
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                      )}
                      {showWallet && isBTC && userWallet && userWallet.address ? (
                        <div>
                          {tabName === 'COIN' && (
                          <div className="code-address-wallet">
                            <p className="text-white wallet-title">{t('labels.your_wallet_address')}</p>
                            <div className="qr-image">
                              <div className="qr-image_background">
                                <QRCode
                                  size={200}
                                  value={userWallet.address}
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="address-wallet-section">
                              <p className="address-wallet-section__address">{userWallet.address}</p>
                              <CopyToClipboard text={userWallet.address}>
                                <FontAwesomeIcon size="xl" color="#fff" icon={faCopy} onClick={() => copyReferral()} />
                              </CopyToClipboard>
                            </div>
                          </div>
                          )}
                        </div>
                      ) : null}

                      {showWallet && !isBTC ? (
                        defaultadminAddress && defaultadminAddress?.address ? (
                          <div>
                            {tabName === 'COIN' && (
                              <div className="code-address-wallet">
                                <p className="text-white wallet-title">{t('labels.your_wallet_address')}</p>
                                <div className="qr-image">
                                  <div className="qr-image_background">
                                    <QRCode
                                      size={200}
                                      value={defaultadminAddress?.address}
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                                <div className="address-wallet-section">
                                  <p className="address-wallet-section__address">{defaultadminAddress?.address}</p>
                                  <CopyToClipboard text={defaultadminAddress?.address}>
                                    <FontAwesomeIcon size="xl" color="#fff" icon={faCopy} onClick={() => copyReferral()} />
                                  </CopyToClipboard>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : null
                      ) : null}
                      {/* {showWallet ? (
                        wallet && wallet.address ? (
                          <div>
                            {depositCoin && (
                              <div className="code-address-wallet">
                                <div className="qr-image p-5">
                                  <QRCode size={200} value={wallet.address} className="img-fluid" />
                                </div>
                                <label>Your Wallet Address</label>
                                <div className="address-wallet d-flex justify-content-between align-items-center text-white">
                                  <p className="me-3">{wallet.address}</p>
                                  <CopyToClipboard text={wallet.address}>
                                    <FontAwesomeIcon icon={faCopy} onClick={() => copyReferral()} />
                                  </CopyToClipboard>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="inline-block">
                            {depositCoin && (
                              <div>
                                {isLoader ? (
                                  <a className="btn btn-wallet w-100 form-btn text-capitalize mt-5">
                                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                                  </a>
                                ) : (
                                  <a
                                    onClick={() => createWallet()}
                                    className="btn btn-wallet  w-100 form-btn text-capitalize mt-5"
                                  >
                                    Create Wallet
                                  </a>
                                )}
                              </div>
                            )}
                          </div>
                        )
                      ) : null} */}
                    </>
                  ) : null}
                </div>

                <div className="deposit-coin2">
                  {tabName === 'CARD' && <CardDepositComponent />}

                  {/* <div className="paylogo credit-logo-wrap">
                    {depositCoin2 && (
                      <a href="https://ramp.network/" target="_blank" rel="noreferrer">
                        <img alt="" src={Ramp} className="paylogo" />
                      </a>
                    )}

                    {depositCoin2 && (
                      <a href="https://www.moonpay.com/buy/btc" target="_blank" rel="noreferrer">
                        <img alt="" src={eclipcoin} className="paylogo" />
                      </a>
                    )}

                    {depositCoin2 && (
                      <a href="https://www.coinspot.com.au/" target="_blank" rel="noreferrer">
                        <img alt="" src={guard} className="paylogo" />
                      </a>
                    )}

                    {depositCoin2 && (
                      <a
                        href="https://www.kraken.com/learn/buy-bitcoin-btc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img alt="" src={bitcoincom} className="paylogo" />
                      </a>
                    )}
                  </div> */}
                </div>

                <div className="deposit-coin3">
                  {/* <h2 onClick={depositCoinHandler3}>Bank</h2> */}

                  {tabName === 'BANK' && (
                    <div className="form-group col-12 mt-2">
                      <label className="bank-label">{t('labels.bank_name')}</label>
                      <div className="input-wrap">
                        <input
                          type="text"
                          className="form-control-bank"
                          value={defaultBankAccountData?.name}
                          disabled
                        />
                        <CopyToClipboard text={defaultBankAccountData?.name} className="ms-3">
                          <FontAwesomeIcon icon={faCopy} onClick={() => copyReferral()} />
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}

                  {tabName === 'BANK' && (
                    <div className="form-group col-12 mt-2">
                      <label className="bank-label">{t('labels.iban')}</label>
                      <div className="input-wrap">
                        <input
                          type="text"
                          className="form-control-bank"
                          value={defaultBankAccountData?.iban}
                          disabled
                        />
                        <CopyToClipboard text={defaultBankAccountData?.iban} className="ms-3">
                          <FontAwesomeIcon icon={faCopy} onClick={() => copyReferral()} />
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}

                  {tabName === 'BANK' && (
                    <div className="form-group col-12 mt-2">
                      <label className="bank-label">{t('labels.account_number')}</label>
                      <div className="input-wrap">
                        <input
                          type="text"
                          className="form-control-bank"
                          value={defaultBankAccountData?.accountNumber}
                          disabled
                        />
                        <CopyToClipboard
                          text={defaultBankAccountData?.accountNumber}
                          className="ms-3"
                        >
                          <FontAwesomeIcon icon={faCopy} onClick={() => copyReferral()} />
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}

                  {tabName === 'BANK' && (
                    <div className="form-group col-12 mt-2">
                      <label className="bank-label">{t('labels.bank_address')}</label>
                      <div className="input-wrap">
                        <input
                          type="text"
                          className="form-control-bank"
                          value={defaultBankAccountData?.bankAddress}
                          disabled
                        />
                        <CopyToClipboard
                          text={defaultBankAccountData?.bankAddress}
                          className="ms-3"
                        >
                          <FontAwesomeIcon icon={faCopy} onClick={() => copyReferral()} />
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}

                  {tabName === 'BANK' && (
                    <div className="form-group col-12 mt-2">
                      <label className="bank-label">{t('labels.swift_code')}</label>
                      <div className="input-wrap">
                        <input
                          type="text"
                          className="form-control-bank"
                          value={defaultBankAccountData?.swiftCode}
                          disabled
                        />
                        <CopyToClipboard text={defaultBankAccountData?.swiftCode} className="ms-3">
                          <FontAwesomeIcon icon={faCopy} onClick={() => copyReferral()} />
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}

                </div>
                {tabName === 'OTHER' && (
                  <div className="others-psp">
                    {othersPsp.map((psp) => (
                      <Link to={psp.url} key={psp._id} target="_blank">
                        <img src={`${process.env.REACT_APP_SERVER_URL}/images/${psp.imageUrl}`} alt="psp.img" className="grayscale-image" />
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <FrequentlyAskedQuestion />
          </div>
        </div>
        <div className="batton" />
      </section>
    </>
  );
}

export default Deposit;
