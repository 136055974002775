import { toast } from 'react-toastify';
import { makeApiRequest, parseFullSymbol, getPairTrade } from '../../../helpers/datafeedHelpers';
import { subscribeOnStream, unsubscribeFromStream, getChannel } from '../streaming/futuresStreaming';
import { socket } from '../../../web';

const userIdJSON = localStorage.getItem('uId');
const userId = (userIdJSON && userIdJSON !== 'undefined') ? JSON.parse(userIdJSON) : '';

const configurationData = {
  supported_resolutions: ['1', '5', '15', '1D', '1W', '1M'],

  exchanges: [
    { value: `${process.env.REACT_APP_BRANDNAME}`, name: `${process.env.REACT_APP_BRANDNAME}`, desc: `${process.env.REACT_APP_BRANDNAME}` },
  ],

  symbols_types: [
    { name: 'Features', value: 'Features' },
  ],
};

const lastBarsCache = new Map();

function subscribeOnListener() {
  if (userId) {
    socket.on(`tsListener${userId}`, () => {
      const channel = getChannel();
      unsubscribeFromStream(channel.subscriberUID);
      subscribeOnStream(
        channel.symbolInfo,
        channel.resolution,
        channel.onRealtimeCallback,
        channel.subscriberUID,
        channel.onResetCacheNeededCallback,
        channel.lastDailyBar,
      );
    });
  }
}

export default {
  onReady: (callback) => {
    subscribeOnListener();
    setTimeout(() => callback(configurationData));
  },

  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    console.log('[searchSymbols]: Method call');
  },

  resolveSymbol: async (symbolName, onSymbolResolvedCallback) => {
    try {
      const parsedSymbol = await parseFullSymbol(symbolName);
      const description = parsedSymbol.fromSymbol + parsedSymbol.toSymbol;
      const data = await getPairTrade(description);
      const symbolInfo = await {
        ticker: symbolName,
        name: symbolName,
        description,
        type: 'Future',
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: parsedSymbol.exchange,
        minmov: 1,
        pricescale: data.updatedCoinsToPrecisions[description]?.scale || 100,
        has_intraday: true,
        has_weekly_and_monthly: true,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: data.updatedCoinsToPrecisions[description]?.precision || 2,
        data_status: 'streaming',
      }; 
      onSymbolResolvedCallback(symbolInfo);
    } catch (error) {
      console.error('[resolveSymbol]: Error', error);
    }
  },

  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    const { from, to, firstDataRequest } = periodParams;
    const parsedSymbol = parseFullSymbol(symbolInfo.full_name);

    const urlParameters = {
      fromSymbol: parsedSymbol.fromSymbol,
      toSymbol: parsedSymbol.toSymbol,
      interval: resolution,
      market: 'futures',
      userId,
    };

    try {
      const data = await makeApiRequest(urlParameters);

      if (data.error) {
        toast.error(data.message, {
          autoClose: 3000,
        });

        onErrorCallback(data.message);

        return;
      }

      let bars = [];
        
      data.candles.forEach((bar) => {
        if (bar.time >= from && bar.time < to) {
          bars = [...bars, {
            time: bar.time * 1000,
            low: bar.low,
            high: bar.high,
            open: bar.open,
            close: bar.close,
          }];
        }
      });

      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.full_name, { ...bars[bars.length - 1] });
      }

      if (bars.length < 1) {
        onHistoryCallback([], { noData: true });
      } else {
        onHistoryCallback(bars, { noData: false });
      }
    } catch (error) {
      console.error('[getBars]: Get error', error);
      onErrorCallback(error);
    }
  },

  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback,
  ) => {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name),
    );
  },

  unsubscribeBars: (subscriberUID) => {
    unsubscribeFromStream(subscriberUID);
  },

};
