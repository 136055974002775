import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { socket } from '../../web';
import { DropdownMenu } from './dropdownMenu';
import BothOrderIcon from '../../assets/images/both-order-icon.svg';
import GreenOrderIcon from '../../assets/images/green-order-icon.svg';
import RedOrderIcon from '../../assets/images/red-order-icon.svg';
import styles from './style.module.css';


export function HistoryTable({
  selectRate, userId, today, coinsToPrecisions, 
}) {
  const { t } = useTranslation();
  const { pair } = useSelector((state) => state.pairTrade);
  const [orderBookAPI, setOrderBookAPI] = useState([]);
  const [minimalAmountValue, setMinimalAmountValue] = useState(0.01);
  const [orderSelector, setOrderSelector] = useState('both');
  const pairName = useParams().coins;
  const localOrderBook = useRef({ a: [], b: [] });
  
  useEffect(() => {
    const eventName = `getBinanceMarketDepthRequest${pairName}${userId}${today}`;
  
    if (pairName && pair && pair.isEnabled && userId && today) {
      socket.emit('getBinanceMarketDepthRequest', { pairName, userId, today });
  
      socket.on(eventName, (response) => {
        const newOrderBook = {
          a: response.a
            ?.filter((row) => parseFloat(row['1']) >= minimalAmountValue)
            .concat(localOrderBook.current.a)
            .slice(0, 20),
          b: response.b
            ?.filter((row) => parseFloat(row['1']) >= minimalAmountValue)
            .concat(localOrderBook.current.b)
            .slice(0, 20),
        };
  
        localOrderBook.current = newOrderBook;
        setOrderBookAPI(newOrderBook);
      });
    }
  
    return () => {
      socket.off(eventName);
      setOrderBookAPI([]);
    };
  }, [pairName, userId, today, minimalAmountValue, pair]);
  
  return (
    <div>
      <div className="sellorder-header">
        <div className="img-wrapper">
          <img
            src={BothOrderIcon}
            alt="Both"
            style={{ opacity: orderSelector === 'both' ? '1' : '0.5', height: '25px' }}
            onClick={() => setOrderSelector('both')}
          />

          <img
            src={GreenOrderIcon}
            alt="Both"
            style={{ opacity: orderSelector === 'buy' ? '1' : '0.5', height: '25px' }}
            onClick={() => setOrderSelector('buy')}
          />

          <img
            src={RedOrderIcon}
            alt="Both"
            style={{ opacity: orderSelector === 'sell' ? '1' : '0.5', height: '25px' }}
            onClick={() => setOrderSelector('sell')}
          />

        </div>
        <div className="dropdown-wrapper">
          <DropdownMenu
            minimalAmountValue={minimalAmountValue}
            setMinimalAmountValue={(value) => setMinimalAmountValue(value)}
          />
        </div>
      </div>
      <div
        className="order-book-market-sell-orders"
        style={{
          display: orderSelector === 'sell' || orderSelector === 'both' ? '' : 'none',
          height: orderSelector === 'sell' ? '100%' : '280px',
        }}
      >
        <table className="trade-table" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>{t('labels.price')}</th>
              <th>{t('labels.amount')}</th>
              <th>{t('labels.sum')}</th>
            </tr>
          </thead>
          <tbody style={{ position: 'relative' }}>
            {orderBookAPI && orderBookAPI.a ? (
              orderBookAPI.a
                .map((order, key = 0) => (
                  <tr
                    key={pairName + key}
                    style={{ fontSize: 'smaller' }}
                    onClick={() => {
                      selectRate(order[0]);
                    }}
                  >
                    <td className="color-red">
                      {parseFloat(order[0]).toFixed(coinsToPrecisions?.[pairName] || 2)}
                    </td>
                    <td>
                      {parseFloat(order[1]).toFixed(2)}
                    </td>
                    <td>
                      {parseFloat(order[0] * order[1]).toFixed(2)}
                    </td>
                  </tr>
                ))
            ) : (
              <FontAwesomeIcon
                className={styles.spinner}
                icon={faSpinner}
                size="2xl"
              />
            )}
          </tbody>
        </table>
      </div>
  
      <div
        className="order-book-market-buy-orders"
        style={{
          display: orderSelector === 'buy' || orderSelector === 'both' ? '' : 'none',
          height: orderSelector === 'buy' ? '100%' : '280px',
        }}
      >
        <table className="trade-table" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>{t('labels.price')}</th>
              <th>{t('labels.amount')}</th>
              <th>{t('labels.sum')}</th>
            </tr>
          </thead>
          <tbody style={{ position: 'relative' }}>
            {orderBookAPI && orderBookAPI.b ? (
              orderBookAPI.b
                .map((order, index = 0) => ((
                  <tr
                    key={index}
                    style={{ fontSize: 'smaller' }}
                    onClick={() => {
                      selectRate(order[0]);
                    }}
                  >
                    <td className="text-success">
                      {parseFloat(order[0]).toFixed(coinsToPrecisions?.[pairName] || 2)}
                    </td>
                    <td>
                      {parseFloat(order[1]).toFixed(2)}
                    </td>
                    <td>
                      {parseFloat(order[0] * order[1]).toFixed(2)}
                    </td>
                  </tr>
                )))
            ) : (
              <FontAwesomeIcon
                className={styles.spinner}
                icon={faSpinner}
                size="xl"
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
