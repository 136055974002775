export const selectUserVerifyStatus = (state) => state.kycVerification.userVerifyStatus;
export const selectVerificationPageStep = (state) => state.kycVerification.verificationPageStep;
export const selectIsDataLoading = (state) => state.kycVerification.isDataLoading;
export const selectIdCardFrontFile = (state) => state.kycVerification.idCardFrontFile;
export const selectIdCardBackFile = (state) => state.kycVerification.idCardBackFile;
export const selectCreditCardFrontFile = (state) => state.kycVerification.creditCardFrontFile;
export const selectCreditCardBackFile = (state) => state.kycVerification.creditCardBackFile;
export const selectSelfieFile = (state) => state.kycVerification.selfieFile;
export const selectIdCardFrontData = (state) => state.kycVerification.idCardFrontData;
export const selectIdCardBackData = (state) => state.kycVerification.idCardBackData;
export const selectCreditCardFrontData = (state) => state.kycVerification.creditCardFrontData;
export const selectCreditCardBackData = (state) => state.kycVerification.creditCardBackData;
export const selectSelfieData = (state) => state.kycVerification.selfieData;
