import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Cards from 'react-credit-cards';
import { submitDepositReq } from '../../../redux/transactions/transactionActions';
import { socketConnection } from '../../../redux/apiHelper';
import { getFiatCurrency } from '../../../redux/currencies/currencyActions';
import styles from './styles.module.css';


function CardDepositComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const userId = useSelector((state) => state.user?.user?._id);
  const accountId = useSelector((state) => state.account?.account?._id);
  const [depositCoins, setDepositCoins] = useState(0);
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [number, setNumber] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState();

  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [focus, setFocus] = useState('');
  const [depositFieldsIsNotValid, setDepositFieldsIsNotValid] = useState({
    number: false,
    numberError: '',
    name: false,
    nameError: '',
    cvc: false,
    cvcError: '',
    amountForCurrency: false,
    amountError: '',
  });
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  useEffect(() => {
    dispatch(getFiatCurrency());
  }, []);

  useEffect(() => {
    if (currencyData && currencyData.length) setSelectedCurrency(currencyData[0]);
  }, [currencyData]);

  const handleExpiry = (name, value) => {
    if (name === 'month') setMonth(value);
    if (name === 'year') setYear(value);
  };

  const setYearOptions = () => {
    const yearsOptions = [];
    const year = new Date().getFullYear();

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 9; i++) {
      const nextYear = year + i;
      yearsOptions.push({
        value: String(nextYear).substring(2),
        label: nextYear,
      });
    }

    return yearsOptions;
  };

  const yearOptions = setYearOptions();

  const handleDepositReq = async (event) => {
    event.preventDefault();
    if (parseFloat(depositCoins) === 0) {
      Swal.fire({
        text: `${t('messages.min_deposit_warning')}`,
        icon: 'info',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
    } else {
      const additionalInfo = {
        name,
        number,
        cvc,
        expiry: `${expiry.slice(0, 2)}/${expiry.slice(2)}`,
      };
      const data = {
        userId,
        toAccount: userId,
        userAccountId: accountId,
        currencyId: selectedCurrency,
        transactionType: false,
        balanceType: false,
        isReal: true,
        isResolved: 0,
        additionalInfo: JSON.stringify(additionalInfo),
        adding: parseFloat(depositCoins).toPrecision(8),
        coins: depositCoins ? depositCoins.toString() : '0',
      };
      Swal.fire({
        text: `${t('messages.fiat_deposit_success')}`,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'OK',
      });
      dispatch(submitDepositReq(data));
      setLoader(true);

      // connect socket and emit notification
      await socketConnection();
    }
  };

  const validateInputs = (name, value) => {
    const cardNumberRegex = /^$|^[0-9]+$/;
    const cardHolderNameRegex = /^(?:[a-zA-Z\s']+)?$/;
    const amountRegex = /^$|^[0-9.]+$/;

    if (name === 'number' && !cardNumberRegex.test(value)) {
      setDepositFieldsIsNotValid((prev) => ({
        ...prev, [name]: true, numberError: 'Card number must contains only numbers',
      }));
      return false;
    };

    if (name === 'name' && !cardHolderNameRegex.test(value)) {
      setDepositFieldsIsNotValid((prev) => ({
        ...prev, [name]: true, nameError: 'Cardholder name must contains only letters', 
      }));
      return false;
    };

    if (name === 'cvc' && !cardNumberRegex.test(value)) {
      setDepositFieldsIsNotValid((prev) => ({
        ...prev, [name]: true, cvcError: 'CVV must be a numbers', 
      }));
      return false;
    };

    if (name === 'amountForCurrency' && !amountRegex.test(value)) {
      setDepositFieldsIsNotValid((prev) => ({
        ...prev, [name]: true, amountError: 'Amount must contains only numbers', 
      }));
      return false;
    } 

    return true;
  };

  const handleChange = (name, value) => {
    const isValid = validateInputs(name, value);

    if (isValid) {
      if (name === 'name') setName(value);
      if (name === 'number') setNumber(value);
      if (name === 'cvc') setCvc(value);
      if (name === 'amountForCurrency') setDepositCoins(Number(value));
  
      setDepositFieldsIsNotValid((prev) => ({ ...prev, [name]: false }));
    }
  }; 

  useEffect(() => {
    if (
      !name.includes(' ') 
      || number.length !== 16
      || cvc.length !== 3 
      || month.length !== 2
      || year.length !== 2
    ) {
      setAllFieldsValid(false);
      return;
    }

    if (
      !depositFieldsIsNotValid.name 
      && !depositFieldsIsNotValid.number 
      && !depositFieldsIsNotValid.cvc
      && !depositFieldsIsNotValid.amountForCurrency
      && (!Number.isNaN(depositCoins) && depositCoins > 0)
    ) {
      setExpiry(month + year);
      setAllFieldsValid(true);
    }
  }, [
    name, 
    cvc, 
    year, 
    month, 
    number, 
    depositFieldsIsNotValid,
    depositCoins]);

  return (
    <>
      <div className="cardf mb-3">
        <div>
          <Cards number={number} name={name} expiry={expiry} cvc={cvc} focused={focus} />
        </div>
      </div>
      <form className="ccdetail" onSubmit={handleDepositReq}>
        <div className="row">
          <div className={`form-group col-12 mt-2 ${styles.inputWrapper}`}>
            <label htmlFor="name" className="carddet">
              {t('labels.cardholder_name')}
            </label>
            <input
              type="text"
              className="form-controlcarddet"
              value={name}
              name="name"
              onChange={({ target: { name, value } }) => handleChange(name, value)}
              onFocus={(e) => setFocus(e.target.name)}
              required
            />
            {depositFieldsIsNotValid.name && <p className={styles.errorInputMessage}>{depositFieldsIsNotValid.nameError}</p>}
          </div>
          <div className={`form-group col-12 mt-2 ${styles.inputWrapper}`}>
            <label htmlFor="name" className="carddet">
              {t('labels.card_number')}
            </label>
            <div className="input-wrap">
              <input
                type="tel"
                className="form-controlcarddet"
                value={number}
                name="number"
                maxLength="16"
                pattern="[0-9]+"
                onChange={({ target: { name, value } }) => handleChange(name, value)}
                onFocus={(e) => setFocus(e.target.name)}
                required
              />
            </div>
            {depositFieldsIsNotValid.number && <p className={styles.errorInputMessage}>{depositFieldsIsNotValid.numberError}</p>}
          </div>
          <div className={`form-group col-lg-8 mt-2 ${styles.inputWrapper}`}>
            <div className={`date-year-holder ${styles.alignEnd}`}>
              <div className="date-holder">
                <label htmlFor="month" className="carddet">
                  {t('labels.expiration_date')}
                </label>
                <select
                  className="form-controlcarddet"
                  name="month"
                  onChange={({ target: { name, value } }) => handleExpiry(name, value)}
                  required
                >
                  <option value=" ">{t('labels.month')}</option>
                  <option value="01">{t('labels.january')}</option>
                  <option value="02">{t('labels.february')}</option>
                  <option value="03">{t('labels.march')}</option>
                  <option value="04">{t('labels.april')}</option>
                  <option value="05">{t('labels.may')}</option>
                  <option value="06">{t('labels.june')}</option>
                  <option value="07">{t('labels.july')}</option>
                  <option value="08">{t('labels.august')}</option>
                  <option value="09">{t('labels.september')}</option>
                  <option value="10">{t('labels.october')}</option>
                  <option value="11">{t('labels.november')}</option>
                  <option value="12">{t('labels.december')}</option>
                </select>
              </div>
              <div className="year-holder">
                <select
                  className="form-controlcarddet"
                  required
                  name="year"
                  onChange={({ target: { name, value } }) => handleExpiry(name, value)}
                  onFocus={(e) => setFocus(e.target.name)}
                >
                  <option value=" ">{t('labels.year')}</option>
                  { yearOptions.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={`form-group col-lg-4 mt-2 ${styles.inputWrapper}`}>
            <label htmlFor="cvv" className="carddet">
              CVV
            </label>
            <input
              type="tel"
              name="cvc"
              maxLength="3"
              className="form-controlcarddet"
              value={cvc}
              pattern="\d*"
              onChange={({ target: { name, value } }) => handleChange(name, value)}
              onFocus={(e) => setFocus(e.target.name)}
              required
            />
            {depositFieldsIsNotValid.cvc && <p className={styles.errorInputMessageCvc}>{depositFieldsIsNotValid.cvcError}</p>}
          </div>
          <div className="form-group col-12 mt-2">
            <div className="date-year-holder amount-curr-holder">
              <div className={`date-holder ${styles.inputWrapper}`}>
                <label htmlFor="Amount" className="carddet">
                  {t('labels.amount')}
                </label>
                <input
                  type="text"
                  className="form-controlcarddet"
                  name="amountForCurrency"
                  onChange={({ target: { name, value } }) => handleChange(name, value)}
                  value={depositCoins}
                  required
                />
                {depositFieldsIsNotValid.amountForCurrency && <p className={styles.errorInputMessage}>{depositFieldsIsNotValid.amountError}</p>}
              </div>
              <div className={`year-holder ${styles.inputWrapper}`}>
                <label htmlFor="Currency" className="carddet">
                  {t('labels.currency')}
                </label>
                <select
                  className="form-controlcarddet"
                  name="Currency"
                  required
                  value={selectedCurrency}
                  onChange={(e) => {
                    const selectedCurrenctCurrency = currencyData.find(
                      (currency) => currency._id === e.target.value,
                    );
                    setSelectedCurrency(selectedCurrenctCurrency._id);
                  }}
                >
                  {currencyData
                    && currencyData.length > 0
                    && currencyData
                      .map((currency) => (
                        <option value={currency._id} key={currency._id}>
                          {currency.symbol}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            {allFieldsValid  
            && (
            <button className="ccbtn" type="submit">
              {t('labels.pay')}
            </button>
            ) }
          </div>
        </div>
      </form>
    </>
  );
}

export default CardDepositComponent;
