import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectConversionFromCoin, selectIsExchangeRatesLoading, selectUserCoinBalance } from '../../../redux/exchange/exchangeSelectors';


function AmountSelectionButtons({ handleUserInput }) {
  const { t } = useTranslation();

  const isExchangeRatesLoading = useSelector(selectIsExchangeRatesLoading);
  const conversionFromCoin = useSelector(selectConversionFromCoin);
  const userBalance = useSelector(selectUserCoinBalance);

  const minimumExchangeLimit = conversionFromCoin.minAmount;

  const setMinConversionFromAmount = () => {
    const conversionFromAmount = userBalance > 0 ? minimumExchangeLimit : 0;
    handleUserInput(conversionFromAmount);
  };

  const setHalfConversionFromAmount = () => {
    const conversionFromAmount = userBalance > 0 ? userBalance / 2 : 0;
    handleUserInput(conversionFromAmount);
  };

  const setMaxConversionFromAmount = () => handleUserInput(userBalance);
  
  return (
    <div className="min-half-max-btns">
      <button
        type="button"
        className="btn"
        onClick={setMinConversionFromAmount}
        disabled={isExchangeRatesLoading}
      >
        {t('labels.min')}
      </button>
      <button
        type="button"
        className="btn"
        onClick={setHalfConversionFromAmount}
        disabled={isExchangeRatesLoading}
      >
        {t('labels.half')}
      </button>
      <button
        style={{ border: '0' }}
        type="button"
        className="btn"
        onClick={setMaxConversionFromAmount}
        disabled={isExchangeRatesLoading}
      >
        {t('labels.max')}
      </button>
    </div>
  );
}

export default AmountSelectionButtons;
