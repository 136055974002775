import React from 'react';
import { useTranslation } from 'react-i18next';

export function SavedCardForm({
  selectedCurrency, selectedCard, handleShowCard, creditCards, setSelectedCard, 
}) {
  const { t } = useTranslation();

  
  return (
    <form className="ccdetailw">
      <br />
      <div className="row">
        <div className="wallet-address">
          <div className="address-add">
            <p className="mb-0 text-white">{t('labels.card_number')}</p>
            <button
              type="button"
              onClick={handleShowCard}
              className="btn add-address-btn"
            >
              {t('labels.info')}
            </button>
          </div>
          <div className="dropdown wallet-address-dd">
            <button
              className="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {selectedCard?.card || t('labels.select_card_number')}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              { creditCards && creditCards.filter((card) => card && card.currencyId === selectedCurrency?._id).length ? (
                creditCards.map((card) => card.currencyId === selectedCurrency?._id && (
                <li key={card._id}>
                  <a
                    className="dropdown-item"
                    key={card._id}
                    onClick={() => setSelectedCard(card)}
                  >
                    {card.card}
                  </a>
                </li>
                ))
              ) : (
                <li>
                  <a className="dropdown-item">{t('messages.no_addresses_added')}</a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </form>
  );
}
