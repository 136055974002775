import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrency } from '../../redux/currencies/currencyActions';
import { addBankAccount } from '../../redux/bankAccounts/bankAccountActions';

function AddBankAccount({ handleCloseBankAccount }) {
  const { t } = useTranslation();
  const [bankName, setBankName] = useState('');
  const [iban, setIban] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState([]);

  const dispatch = useDispatch();
  const currencyData = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const userId = useSelector((state) => state.user?.user?._id);

  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  const handleAdd = () => {
    const data = {
      name: bankName,
      iban,
      accountNumber,
      bankAddress,
      swiftCode,
      currencyId: selectedCurrency._id,
      userId,
    };

    dispatch(addBankAccount(data));
    setSelectedCurrency([]);
    handleCloseBankAccount();
  };

  return (
    <div className="withdrawal-modal2">
      <h4 className="text-green">{t('labels.add_bank_account')}</h4>
      <form className="address-form wrap-address-form m-auto">
        <div className="input-group buttonInside">
          <p className="text-white">{t('labels.bank_name')}</p>
          <input type="text" placeholder="Enter Address label" onChange={(e) => setBankName(e.target.value)} />
        </div>
        <p className="text-white">{t('labels.select_currency')}</p>
        <div className="dropdown deposit-dropdown">
          <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <div className="d-flex justify-content-between">
              <p className="coin-name">
                {selectedCurrency && selectedCurrency.symbol ? selectedCurrency.name : `${t('labels.select_currency')}`}
              </p>
              <div className="coin-details d-flex align-items-center">
                {selectedCurrency && selectedCurrency.symbol ? (
                  <p className="detail">
                    (
                    {selectedCurrency.symbol}
                    )
                  </p>
                ) : <p className="detail">{`(${t('labels.symbol')})`}</p>}
                <p className="dd-arrow" />
              </div>
            </div>
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {currencyData && currencyData.length > 0 && currencyData?.filter((row) => row?.isFiat === true)?.map((currency) => (
              <li key={currency._id} onClick={() => { setSelectedCurrency(currency); }}>
                <a className="dropdown-item">
                  <div className="d-items d-flex justify-content-between">
                    <p>{currency.name}</p>
                    <p>{currency.symbol}</p>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>

        {selectedCurrency && selectedCurrency._id
          ? (
            <>
              <div className="input-group buttonInside">
                <p className="text-white">{t('labels.account_number')}</p>
                <input type="text" onChange={(e) => setAccountNumber(e.target.value)} />
              </div>
              <div className="input-group buttonInside">
                <p className="text-white">{t('labels.iban')}</p>
                <input type="text" onChange={(e) => setIban(e.target.value)} />
              </div>
              <div className="input-group buttonInside">
                <p className="text-white">{t('labels.bank_address')}</p>
                <input type="text" onChange={(e) => setBankAddress(e.target.value)} />
              </div>
              <div className="input-group buttonInside">
                <p className="text-white">{t('labels.swift_code')}</p>
                <input type="text" onChange={(e) => setSwiftCode(e.target.value)} />
              </div>
              <div className="d-block">
                <button type="button" onClick={() => handleAdd()} className="btn enter-btn3">{t('labels.continue')}</button>
              </div>
            </>
          )
          : ''}
      </form>
    </div>
  );
}

export default AddBankAccount;
