import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import { GET_LANGUAGES } from './languageTypes';

export const getLanguages = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/language', '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_LANGUAGES,
        payload: res.data.languages,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};

export const searchLanguages = (value) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/language/search?lang=${value}`, '');
  
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_LANGUAGES,
        payload: res.data.languages,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
