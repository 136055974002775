import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsAllConditionsValid, selectIsExchangeRatesLoading } from '../../../redux/exchange/exchangeSelectors';


function ConfirmationButton({ showConfirmation }) {
  const { t } = useTranslation();

  const isExchangeRatesLoading = useSelector(selectIsExchangeRatesLoading);
  const isAllConditionsValid = selectIsAllConditionsValid();

  if (!isAllConditionsValid) {
    return null;
  }

  return (
    <div className="text-center mb-3">
      <button
        type="button"
        className="btn enter-btn2"
        onClick={showConfirmation}
        disabled={isExchangeRatesLoading}
      >
        {t('labels.confirm')}
      </button>
    </div>
  );
}

export default ConfirmationButton;
