
export const toFixedAndTrim = (num, fixed) => {
  num = parseFloat(num);

  if (num && num !== Infinity) {
    const regExp = new RegExp(`^-?\\d+(?:\\.\\d{1,${fixed}})?`);
    const matchResult = num.toFixed(fixed).match(regExp);
    const number = matchResult[0].replace(/\.0+$|(\.\d*?[1-9])0+$/, '$1');
    return number;
  }

  return 0;
};

export const calculateFee = (conversionFeeRate, conversionAmount = 0, decimalPrecision = 2) => {
  const feeByPercentage = ((conversionAmount * conversionFeeRate) / 100).toFixed(decimalPrecision);
  const isNan = Number.isNaN(Number(feeByPercentage));
  const finalFee = !isNan ? parseFloat(feeByPercentage) : 0;
  const res = toFixedAndTrim(finalFee, decimalPrecision);
  return res;
};

