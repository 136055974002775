export const GET_ADMIN_ADDRESSES = 'GET_ADMIN_ADDRESSES';
export const GET_ADMIN_ADDRESSES_NETWORK = 'GET_ADMIN_ADDRESSES_NETWORK';
export const GET_DEFAULT_ADMIN_ADDRESS_NETWORK = 'GET_DEFAULT_ADMIN_ADDRESS_NETWORK';
export const GET_ADMIN_ADDRESS = 'GET_ADMIN_ADDRESS';
export const ADD_ADMIN_ADDRESS = 'ADD_ADMIN_ADDRESS';
export const EDIT_ADMIN_ADDRESS = 'EDIT_ADMIN_ADDRESS';
export const DELETE_ADMIN_ADDRESS = 'DELETE_ADMIN_ADDRESS';
export const SET_DEFAULT_ADMIN_ADDRESS = 'SET_DEFAULT_ADMIN_ADDRESS';
export const TOGGLE_STATE = 'TOGGLE_STATE';
export const ERROR_STATE = 'ERROR_STATE';
