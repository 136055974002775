import React from 'react';
import { getAccountTypeIconByName } from '../../helpers/getAccountTypeIconByName';

import './AccountTypeIcon.css';

export function AccountTypeIcon({ accountType }) {
  return (
    <div className="account-type-logo">
      {
        (accountType.name !== 'main')
        && (
          <img
            src={getAccountTypeIconByName(accountType.name)}
            alt={accountType.name.toUpperCase()}
            className="rotateimg180"
          />
        )
      }
      {accountType.name?.toUpperCase()}
    </div>
  );
}
