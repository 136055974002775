import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Accordion } from 'react-bootstrap';
import SC from '../assets/images/successfully-copied.svg';

const currencyFormatter = require('currency-formatter');

export const transactionsDataTableSchema = (getCoinImg, copyTxtHash, displayMessage, currenciesPriceRate) => {
  const { t } = useTranslation();
  const cutHash = (hash) => (`${hash.substring(0, 5)}...${hash.substring(hash.length - 5, hash.length)}`);

  const getRateInUsdt = (coin_symbol, amount) => {
    if (currenciesPriceRate && Object.keys(currenciesPriceRate).length) {
      const total_in_usdt = parseFloat(
        parseFloat(
          1 / currenciesPriceRate[coin_symbol === 'USDT' ? 'USD' : coin_symbol],
        ) * parseFloat(amount),
      );

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(total_in_usdt)) {
        return total_in_usdt;
      }
      
      return null;
    }

    return '-';
  };

  const convertToUSD = (row) => {
    const usdtValue = getRateInUsdt(row?.symbol, row?.amount);
    if (usdtValue && !Number.isNaN(Number(usdtValue))) {
      return currencyFormatter.format(usdtValue.toFixed(2), {
        code: 'USD',
      });
    }
    
    return 0;
  };

  const columns = [
    {
      selector: (row) => (
        <Accordion>
          <Accordion.Item className="transcations-accord-item mb-2" eventKey="1">
            <Accordion.Header>
              <div className="transcations-accord-content">
                <div className="content">
                  <div>
                    <img src={getCoinImg(row.symbol)} alt="" className="img-fluid" />
                  </div>
                  <div className="ms-2">
                    <h5 className="text-white">{row.coin}</h5>
                    <p className="m-0 text-white-light text-start">{row.symbol}</p>
                  </div>
                </div>

                <div className="text-white dd-icon d-flex align-items-center">
                  <h5 className="m-0" style={{ color: row?.color }}>
                    {row.sign}
                    {parseFloat(row.amount).toFixed(2)}
                    {row.symbol}
                  </h5>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {row.type === 1
                ? (
                  <div className="accordion-content">
                    <p className="mb-0" style={{ color: row?.color }}>{row?.category}</p>
                    <div className="accordion-content-activity">
                      <div className="activity">
                        <p className="text-white-light">{t('labels.date')}</p>
                        <p className="mb-0">{row?.date.replace('T', ' ').replace('Z', ' ')}</p>
                      </div>
                      {
                          row?.info && (
                          <div className="activity">
                            <p className="text-white-light">{t('labels.info')}</p>
                            <p className="mb-0" style={{ color: row?.color }}>
                              {row?.info}
                            </p>
                          </div>
                          )
                        }
                      <div className="activity">
                        <p className="text-white-light">{t('labels.transaction_id')}</p>
                        <p className="mb-0" style={{ color: row?.color }}>
                          {row?.txHash}
                          <CopyToClipboard text={row?.txHash}>
                            <FontAwesomeIcon className="text-white ms-3" icon={faCopy} onClick={() => copyTxtHash()} />
                          </CopyToClipboard>
                        </p>
                      </div>
                      {
                        !!row.hash && (
                          <div className="activity">
                            <p className="text-white-light">{t('labels.hash')}</p>
                            <p className="mb-0" style={{ color: row?.color }}>
                              {cutHash(row.hash)}
                              <CopyToClipboard text={row.hash}>
                                <FontAwesomeIcon className="text-white ms-3" icon={faCopy} onClick={() => copyTxtHash()} />
                              </CopyToClipboard>
                            </p>
                          </div>
                        )
                      }
                      <div className="activity">
                        <p className="text-white-light">{t('labels.amount')}</p>
                        <p className="text-light mb-0">
                          {row.sign}
                          {parseFloat(row.amount).toFixed(2)}
                          {row.symbol}
                        </p>
                      </div>
                      {
                        !!row.amount && (
                          <div className="activity">
                            <p className="text-white-light">{`${t('labels.amount_in')} USD`}</p>
                            <p className="text-light mb-0">
                              {row.sign}
                              {convertToUSD(row)}
                            </p>
                          </div>
                        )
                      }
                      <div className="activity">
                        <p className="text-white-light">{t('labels.status')}</p>
                        <p className="mb-0" style={{ color: row?.resolveStatus === 2 ? '#dc3545' : row?.color }}>
                          {row?.resolveStatus === 2 ? t('labels.declined') : t('labels.approved')}
                        </p>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div className="accordion-content">
                    <p className="mb-0" style={{ color: row?.color }}>{row?.category}</p>
                    <div className="accordion-content-activity">
                      <div className="activity">
                        <p className="text-white-light">{t('labels.date')}</p>
                        <p className="mb-0">{row?.date.replace('T', ' ').replace('Z', ' ')}</p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light">{t('labels.transaction_id')}</p>
                        <p className="mb-0" style={{ color: row?.color }}>
                          {row?.id}
                          <CopyToClipboard text={row?.id}>
                            <FontAwesomeIcon className="text-white ms-3" icon={faCopy} onClick={() => copyTxtHash()} />
                          </CopyToClipboard>
                        </p>
                      </div>
                      {row?.info && (
                      <div className="activity">
                        <p className="text-white-light">{t('labels.info')}</p>
                        <p className="mb-0" style={{ color: row?.color }}>
                          {row?.info}
                        </p>
                      </div>
                      )}
                      <div className="activity">
                        <p className="text-white-light">{t('labels.amount')}</p>
                        <p className="text-light mb-0">
                          {row.sign}
                          {parseFloat(row.amount).toFixed(2)}
                          {row.symbol}
                        </p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light">{t('labels.from')}</p>
                        <p className="text-light mb-0">{row.sign === '-' ? row.coin : row.otherCoin}</p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light">{t('labels.to')}</p>
                        <p className="text-light mb-0">{row.sign === '-' ? row.otherCoin : row.coin}</p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light">{t('labels.conversion_rate')}</p>
                        <p className="text-light mb-0">{row.rate}</p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light">{t('labels.total')}</p>
                        <p className="text-light mb-0">
                          {row.otherCoin}
                          :
                          {row.sign === '-' ? '+' : '-'}
                          {row.otherAmount}
                        </p>
                      </div>
                      <div className="activity">
                        <p className="text-white-light">{t('labels.status')}</p>
                        <p className="mb-0" style={{ color: row?.resolveStatus === 2 ? '#dc3545' : row?.color }}>
                          {row?.resolveStatus === 2 ? t('labels.declined') : t('labels.approved')}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              {displayMessage ? (
                <p className="successfully-copied">
                  <img src={SC} alt="" className="img-fluid" />
                  {t('labels.copied_successfully')}
                </p>
              ) : ''}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ),
    },
  ];
  return columns;
};
