import React, { useState } from 'react';
import tradeSpotDropdownMark from '../../assets/images/tradeSpotDropdownMark.svg';
import styles from './style.module.css';

export function DropdownMenu({ minimalAmountValue, setMinimalAmountValue }) {
  const [show, setShow] = useState(false);
  
  const dropdownValues = [0.01, 0.1, 1, 5];
  
  const handleToggle = () => {
    if (show) setShow(false);
    else setShow(true);
  };
  
  return (
    <div className="dropdown">
      <button
        className={`btn text-white ${styles.dropDownBtn}`}
        type="button"
        onClick={handleToggle}
      >
        {minimalAmountValue}
        
        <img 
          src={tradeSpotDropdownMark}
          className={styles.dropDownBtnMark}
          alt="tradeSpotDropdownMark"
        />
      </button>
      {show && (
        <ul className={styles.dropDownMenu}>
          {dropdownValues.map((value, key = 0) => (
            <li
              key={key}
              onClick={() => {
                setMinimalAmountValue(value);
                handleToggle();
              }}
            >
              {value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
  
