import { toast } from 'react-toastify';
import {
  GET_STAKING_INVESTMENTS,
  GET_STAKING_INVESTMENT,
  ADD_STAKING_INVESTMENT,
  EDIT_STAKING_INVESTMENT,
  DELETE_STAKING_INVESTMENT,
  CLAIM_STAKING_INVESTMENT,
  TOGGLE_STAKING_INVESTMENT_STATE,
  ERROR_STAKING_INVESTMENT_STATE,
} from './stakingInvestmentTypes';
import { apiHelper } from '../apiHelper';

export const getStakingInvestments = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/staking-investment', '');
    if (res?.data && res?.data?.stakingInvestments) {
      const { data } = res;
      await dispatch({
        type: GET_STAKING_INVESTMENTS,
        payload: data?.stakingInvestments,
      });
    } else {
      await dispatch({
        type: GET_STAKING_INVESTMENTS,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getUserStakingInvestments = (userId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/staking-investment/user/${userId}`, '');
    if (res?.data && res?.data?.stakingInvestments) {
      const { data } = res;
      await dispatch({
        type: GET_STAKING_INVESTMENTS,
        payload: data?.stakingInvestments,
      });
    } else {
      await dispatch({
        type: GET_STAKING_INVESTMENTS,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getStakingInvestment = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/staking-investment/${id}`, '');
    if (res?.data && res?.data?.stakingInvestment) {
      const { data } = res;
      await dispatch({
        type: GET_STAKING_INVESTMENT,
        payload: data?.stakingInvestment,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const addStakingInvestment = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/staking-investment/add', data);

    if (res?.data && res?.data?.stakingInvestment_) {
      const { data } = res;

      toast.success(data?.message, {
        autoClose: 1000,
      });

      await dispatch({
        type: ADD_STAKING_INVESTMENT,
        payload: data?.stakingInvestment_,
      });
    } else {
      if (res?.data?.status === 2) {
        toast.error(res?.data?.message, {
          autoClose: 1000,
        });
      }

      await dispatch({
        type: ERROR_STAKING_INVESTMENT_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_STAKING_INVESTMENT_STATE,
    });
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editStakingInvestment = (id, bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/staking-investment/${id}`, bodyData);
    if (res?.data && res?.data?.stakingInvestment) {
      const { data } = res;

      toast.success(data?.message, {
        autoClose: 1000,
      });

      await dispatch({
        type: EDIT_STAKING_INVESTMENT,
        payload: data?.stakingInvestment,
      });
    } else {
      await dispatch({
        type: ERROR_STAKING_INVESTMENT_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_STAKING_INVESTMENT_STATE,
    });
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const claimFlexibleStakingInvestment = (bodyData) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/staking-investment/claim-flexible', bodyData);
    if (res?.data && res?.data?.stakingInvestment) {
      const { data } = res;

      toast.success(data?.message, {
        autoClose: 1000,
      });

      await dispatch({
        type: CLAIM_STAKING_INVESTMENT,
        payload: data?.stakingInvestment,
      });
    } else {
      await dispatch({
        type: ERROR_STAKING_INVESTMENT_STATE,
      });
    }
  } catch (error) {
    await dispatch({
      type: ERROR_STAKING_INVESTMENT_STATE,
    });
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteStakingInvestment = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/staking-investment/${id}`, '');
    if (res?.data && res?.data?.stakingInvestment) {
      const { data } = res;

      toast.success(data?.message, {
        autoClose: 1000,
      });

      await dispatch({
        type: DELETE_STAKING_INVESTMENT,
        payload: data?.stakingInvestment?._id,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const updateStakingInvestmentState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_STAKING_INVESTMENT_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};
