import { toast } from 'react-toastify';
import {
  GET_CHAT, READ_MESSAGES, SEND_MESSAGE, 
} from './chatTypes';
import { apiHelper } from '../apiHelper';

export const readMessages = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', '/api/chat/read', data);

    if (res && res.data && res.data.success) {
      dispatch({
        type: READ_MESSAGES,
        payload: data,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getMessage = (message) => (dispatch) => {
  dispatch({
    type: SEND_MESSAGE,
    payload: message,
  });
};

export const getChat = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/chat/user-chat/${id}`, '');
    
    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_CHAT,
        payload: res.data.chat,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const sendMessage = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', `/api/chat/message/${id}`, data);
    if (res && res.data && res.data.success) {
      if (res.data.newChatId) {
        const chatRes = await apiHelper('get', `/api/chat/user-chat/${res.data.message.sender}`, '');
    
        if (chatRes && chatRes.data && chatRes.data.success) {
          await dispatch({
            type: GET_CHAT,
            payload: chatRes.data.chat,
          });
        }
      } else {
        await dispatch({
          type: SEND_MESSAGE,
          payload: res.data.message,
        });
      }
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
