import { 
  GET_METALS_ORDER, 
  GET_USER_METALS_ORDER,
  ADD_METALS_ORDER, 
  CLEAR_METALS_ORDER, 
  METALS_ORDER_ERROR, 
  STOP_METALS_ORDER, 
  START_METALS_ORDER, 
  METALS_ORDER_PNL,
  METALS_ORDER_BOOK,
} from './metalsOrderTypes';
 
const initialState = {
  metalsOrders: [],
  userMetalsOrders: [],
  metalsOrder: [],
  metalsError: false,
  metalsStopped: false,
  metalsStarted: false,
  metalsOrderBook: [],
  metalsOrderPnL: 0,
  metalsOrderPnLFetched: false,
  metalsOrdersFetched: false,
  userMetalsOrdersFetched: false,
  metalsOrderFetched: false,
  metalsOrderBookFetched: false,
  metalsOrderAdded: false,
};
 
const MetalsOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case METALS_ORDER_PNL:
      return {
        ...state,
        metalsOrderPnL: action.payload,
        metalsOrderPnLFetched: true,
      };
    case GET_METALS_ORDER:
      return {
        ...state,
        metalsOrders: action.payload,
        metalsOrdersFetched: true,
      };
    case GET_USER_METALS_ORDER:
      return {
        ...state,
        userMetalsOrders: action.payload,
        userMetalsOrdersFetched: true,
      };
    case ADD_METALS_ORDER:
      return {
        ...state,
        metalsOrder: action.payload,
        metalsOrderAdded: true,
      };
    case METALS_ORDER_BOOK:
      return {
        ...state,
        metalsOrderBook: action.payload,
        metalsOrderBookFetched: true,
      };
    case CLEAR_METALS_ORDER:
      return {
        ...state,
        metalsStopped: false,
        metalsStarted: false,
        metalsError: false,
        metalsOrderPnLFetched: false,
        metalsOrdersFetched: false,
        userMetalsOrdersFetched: false,
        metalsOrderFetched: false,
        metalsOrderBookFetched: false,
        metalsOrderAdded: false,
      };
    case STOP_METALS_ORDER:
      return {
        ...state,
        metalsStopped: true,
      };
    case START_METALS_ORDER:
      return {
        ...state,
        metalsStarted: true,
      };
    case METALS_ORDER_ERROR:
      return {
        ...state,
        metalsError: true,
      };
    default:
      return state;
  }
};
 
export default MetalsOrderReducer;
