import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import {
  SET_USER_VERIFY_STATUS,
  SET_VERIFICATION_PAGE_STEP,
  SET_IS_DATA_LOADING,
  SET_ID_CARD_FRONT_FILE,
  SET_ID_CARD_BACK_FILE,
  SET_CREDIT_CARD_FRONT_FILE,
  SET_CREDIT_CARD_BACK_FILE,
  SET_SELFIE_FILE,
  SET_ID_CARD_FRONT_DATA,
  SET_ID_CARD_BACK_DATA,
  SET_CREDIT_CARD_FRONT_DATA,
  SET_CREDIT_CARD_BACK_DATA,
  SET_SELFIE_DATA,
} from './kycVerificationActionTypes';

export const setUserVerifyStatus = (verifyStatus) => ({
  type: SET_USER_VERIFY_STATUS,
  payload: verifyStatus,
});

export const setVerificationPageStep = (stepName) => ({
  type: SET_VERIFICATION_PAGE_STEP,
  payload: stepName,
});

export const setIsDataLoading = (stepName) => ({
  type: SET_IS_DATA_LOADING,
  payload: stepName,
});

export const setIdCardFrontFile = (file) => ({
  type: SET_ID_CARD_FRONT_FILE,
  payload: file,
});

export const setIdCardBackFile = (file) => ({
  type: SET_ID_CARD_BACK_FILE,
  payload: file,
});

export const setCreditCardFrontFile = (file) => ({
  type: SET_CREDIT_CARD_FRONT_FILE,
  payload: file,
});

export const setCreditCardBackFile = (file) => ({
  type: SET_CREDIT_CARD_BACK_FILE,
  payload: file,
});

export const setSelfieFile = (file) => ({
  type: SET_SELFIE_FILE,
  payload: file,
});

export const setIdCardFrontData = (file) => ({
  type: SET_ID_CARD_FRONT_DATA,
  payload: file,
});

export const setIdCardBackData = (file) => ({
  type: SET_ID_CARD_BACK_DATA,
  payload: file,
});

export const setCreditCardFrontData = (file) => ({
  type: SET_CREDIT_CARD_FRONT_DATA,
  payload: file,
});

export const setCreditCardBackData = (file) => ({
  type: SET_CREDIT_CARD_BACK_DATA,
  payload: file,
});

export const setSelfieData = (file) => ({
  type: SET_SELFIE_DATA,
  payload: file,
});

export const addVerificationRequest = (data) => async () => {
  try {
    const response = await apiHelper('post', '/api/kyc-verification', data);

    if (response?.data) {
      toast.success(response.data.message);
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const fetchUserVerificationData = (userId) => async (dispatch) => {
  dispatch(setIsDataLoading(true));

  const kycTypes = {
    idCardFront: 'idCardFront',
    idCardBack: 'idCardBack',
    creditCardFront: 'creditCardFront',
    creditCardBack: 'creditCardBack',
    selfie: 'selfie',
  };

  try {
    const response = await apiHelper('get', `/api/kyc-verification/${userId}`);

    if (response && response.data) {
      const kycRequests = response.data?.kycRequestIds ?? [];

      kycRequests.forEach((kycReq) => {
        switch (kycReq.type) {
          case kycTypes.idCardFront:
            dispatch(setIdCardFrontData(kycReq));
            break;
          case kycTypes.idCardBack:
            dispatch(setIdCardBackData(kycReq));
            break;
          case kycTypes.creditCardFront:
            dispatch(setCreditCardFrontData(kycReq));
            break;
          case kycTypes.creditCardBack:
            dispatch(setCreditCardBackData(kycReq));
            break;
          case kycTypes.selfie:
            dispatch(setSelfieData(kycReq));
            break;

          default:
            break;
        }
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }

  dispatch(setIsDataLoading(false));
};

export const fetchUserVerifyStatus = (userId) => async (dispatch) => {
  try {
    const response = await apiHelper('get', `/api/kyc-verification/${userId}`);

    if (response && response.data) {
      const verifiedStatus = response.data?.verifiedStatus ?? null;
      dispatch(setUserVerifyStatus(verifiedStatus));
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
