import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FOF from '../assets/images/not_found_img.svg';

function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="notfound-sec">
      <div className="container">
        <div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
          <div className="col-md-6">
            <div className="text-center img-wrapper">
              <img src={FOF} alt="FOF" className="img-fluid" />
            </div>
            <div className="content-wrapper d-flex flex-column align-items-center mt-5 w-100">
              <h2 className="text-uppercase">{t('not_found.error_msg')}</h2>
              <Link to="/" className="btn go-home-btn">
                <span>{t('not_found.back')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
