import { toast } from 'react-toastify';
import { apiHelper } from '../apiHelper';
import { GET_LEVERAGE_VALUE } from './leverageSettingTypes';

export const getLeverageValue = ({ userId, pairName }) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/leverage-setting/leverage?userId=${userId}&pairName=${pairName}`, '');

    if (res && res.data && res.data.success) {
      dispatch({
        type: GET_LEVERAGE_VALUE,
        payload: res.data.leverage,
      });
    } 
  } catch (error) {
    toast.error(error.message);
  }
};
