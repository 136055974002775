import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import AddiSvg from '../assets/images/additional-security.png';
import {
  clearOTP, getUser, verifyTFA,
} from '../redux/users/userActions';

function AdditionalSecurityStarbitrexPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user?.user);
  const success = useSelector((state) => state.user?.authChanged);
  const [user, setUser] = useState('');
  const [tfa, setTfa] = useState('');

  const [authModal, setAuthModal] = useState(false);
  const handleClose2 = () => setAuthModal(false);
  const handleShow2 = () => setAuthModal(true);

  useEffect(() => {
    const loginUser = localStorage.getItem('uId');
    const uId = JSON.parse(loginUser);
    dispatch(getUser(uId));
    if (success) {
      dispatch(clearOTP());
      handleClose2();
    }
  }, [success]);

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  const verifySecret = () => {
    const data = {
      email: user.email,
      code: tfa,
    };
    dispatch(verifyTFA(data));
    setTfa('');
  };

  return (
    <>
      <section className="header-padding">
        <div className="additional-security">
          <div className="container">
            <h1>
              {t('labels.additional')}
              {' '}
              <span className="light-green-bg">{t('header.security')}</span>
            </h1>
            <div className="auto-lock d-flex align-items-center">
              <div>
                <img src={AddiSvg} alt="" className="img-fluid" />
              </div>
              <div className="auto-lock-content">
                <h2>
                  <span>{t('security.auto_lock')}</span>
                </h2>
                <p className="text-white-light" style={{ textTransform: 'lowercase' }}>{t('labels.information')}</p>
                <p className="m-0 paraa" style={{ color: 'rgba(13, 90, 76, 1)' }}>
                  {t('security.backup_wallet_enable')}
                </p>
                <div className="text-success mb-2">{t('security.activate_deactivate_gauth')}</div>
                <button className="btn btn-warning" type="button" onClick={handleShow2}>{user?.otpStatus === true ? t('security.deactivate_2fa') : t('security.activate_2fa')}</button>
              </div>
            </div>
          </div>
          <div className="batton" />
        </div>
      </section>

      {/* 2FA Modal */}
      <Modal show={authModal} onHide={handleClose2} className="withdraw-details two-factor-auth" centered backdrop="static">
        <Modal.Header closeButton />
        <Modal.Body>
          <h3 className="text-white mb-3" style={{ textAlign: 'center' }}>{t('security.2f_authentication')}</h3>
          {!user?.otpStatus && (
            <div className="mb-4" style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={user?.qrCode} alt="img" />
            </div>
          )}
          <input
            type="email"
            className="form-control mb-5"
            onChange={(e) => setTfa(e.target.value)}
            name="tfa"
            value={tfa}
            placeholder={t('security.enter_otp_code')}
          />
          <div className="limit-modal-btns">
            <button type="button" onClick={handleClose2} className="btn cancel">{t('labels.cancel')}</button>
            <button type="button" onClick={verifySecret} className="btn confirm">{t('labels.confirm')}</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdditionalSecurityStarbitrexPage;
