import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';

export const userBankAccountsSchema = (formatDate, copyReferral, deleteAction) => {
  const { t } = useTranslation();

  const columnsBanks = [
    {
      name: t('labels.bank_name'),
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: t('labels.iban'),
      selector: (row) => (
        <CopyToClipboard text={row?.iban}>
          <span>
            {row?.iban.slice(0, 4)}
            ...
            {row?.iban.slice((row?.iban.length || 0) - 4, row?.iban.length)}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: t('labels.account_number'),
      selector: (row) => (
        <CopyToClipboard text={row?.accountNumber}>
          <span>
            {row?.accountNumber.slice(0, 4)}
            ...
            {row?.accountNumber.slice((row?.accountNumber.length || 0) - 4, row?.accountNumber.length)}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: t('labels.bank_address'),
      selector: (row) => row?.bankAddress,
      sortable: true,
    },
    {
      name: t('labels.swift_code'),
      selector: (row) => row?.swiftCode,
      sortable: true,
    },
    {
      name: t('labels.date'),
      selector: (row) => formatDate(new Date(row?.createdAt)),
      sortable: true,
    },
    {
      name: t('labels.action'),
      selector: (row) => (
        <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => deleteAction(row._id)}>{t('labels.delete')}</button>
      ),
    },
  ];
  return columnsBanks;
};
