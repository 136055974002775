import React, { useMemo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

export function ChatInput({
  textareaRef, fileInputRef, onSend, onRead, file, setFile,
}) {
  useEffect(() => {
    if (file && file.size > 3007152) {
      toast.error('File bigger then 3 MB');
      setFile(null);
      fileInputRef.current.value = null;
    }
  }, [file]);

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onSend();
    }
  };

  const isFileLoaded = useMemo(() => file && file.name, [file]);

  const splitFileName = (fileName) => {
    const [name, ext] = fileName.split('.');

    return (
      <>
        <span className="truncate-text">{name}</span>
        <span>{`.${ext}`}</span>
      </>
    );
  };

  return (
    <div className="chat-input">
      <div className="chat-input__upload">
        <label htmlFor="file-input">
          <FontAwesomeIcon className="pointer" icon={faPaperclip} />
          {isFileLoaded && <span className="chat-input__filename">{splitFileName(file.name)}</span>}
        </label>
        <input id="file-input" accept="image/*" type="file" ref={fileInputRef} onChange={(e) => setFile(e.target.files[0])} />
      </div>
      <textarea ref={textareaRef} onFocus={() => onRead()} onKeyDown={(e) => onEnterPress(e)} />
      <button type="button" className="green-button btn me-2">
        <FontAwesomeIcon className="pointer" icon={faForward} onClick={() => onSend()} />
      </button>
    </div>
  );
}
