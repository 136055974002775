import {
  GET_ADMIN_DEPOSITS,
  GET_ADMIN_WITHDRAWS,
  TRANSACTION_ERROR,
  DEPOSIT_REQ,
} from './transationTypes';

const initialState = {
  adminDeposits: [],
  adminWithdraws: [],
  userDepositReq: [],
  fiatDeposits: [],
  depositReqMessage: '',
  error: false,
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_DEPOSITS:
      return {
        ...state,
        adminDeposits: action.payload,
        error: false,
      };
    case GET_ADMIN_WITHDRAWS:
      return {
        ...state,
        adminWithdraws: action.payload,
        error: false,
      };
    case DEPOSIT_REQ:
      return {
        ...state,
        userDepositReq: action.payload,
        fiatDeposits: true,
        error: false,
        depositReqMessage: action.payload.message,
      };
    case TRANSACTION_ERROR:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default transactionReducer;
